import { Link, withRouter } from 'react-router-dom';
import styled, { materialUiTheme } from 'theme/index';
import AppConstants from 'AppConstants';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LeftNavContainer = styled.div`
    display: flex;
    width: 100%;
`;

const LeftNavCol = styled.div`
    background-color: ${props =>
        props.reverse ? '#ffffff' : AppConstants.COLOR_LEFT_NAV_BACKGROUND};
    width: 33.33%;
    padding-top: 20px;
    ${materialUiTheme.breakpoints.down('xs')} {
        width: 100%;
        background-color: #ffffff;
    }
`;

const ResponsiveLeftNavCol = styled(LeftNavCol)`
    ${materialUiTheme.breakpoints.down('xs')} {
        display: none;
    }
`;

const PageContent = styled.div`
    width: 66.66%;
    padding: ${props =>
        props.padding === 'container'
            ? '30px 24px 24px 24px'
            : props.padding === 'basic'
                ? '30px 34px'
                : '0px'};
    ${materialUiTheme.breakpoints.down('xs')} {
        padding: ${props =>
            props.padding === 'container'
                ? '30px 8px 24px 8px'
                : props.padding === 'basic'
                    ? '30px 16px'
                    : '0px'};
        width: 100%;
    };
    display: flex;
    flex-direction: column;
`;

const PageContentHiddenForPhone = styled(PageContent)`
    ${materialUiTheme.breakpoints.down('xs')} {
        display: none;
    }
`;

const ListStyled = styled(List)`
    && {
        padding-left: 6px;
        padding-right: 6px;
    }
`;

const ListItemStyled = styled(ListItem)`
    && {
        border-radius: 4px;
        padding-left: 20px;
        background-color: ${props =>
            props.selected
                ? props.reverse
                    ? `${AppConstants.COLOR_LEFT_NAV_BACKGROUND} !important`
                    : '#ffffff !important'
                : 'inherit'};
        &:hover {
            background-color: #ffffff;
        }
    }
`;

const ListItemText = styled.span`
    font-family: Soleil, sans-serif;
    font-size: 1rem;
    margin-left: 16px;
`;

const ListItemTextStyled = styled(ListItemText)`
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    color: ${props =>
        props.selected ? AppConstants.COLOR_PRIMARY : AppConstants.COLOR_TEXT};
`;

const NavHeader = styled.span`
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    vertical-align: -2px;
`;

const DesktopContentContainer = styled.div`
    width: 100%;
    border-top: solid 1px ${AppConstants.COLOR_DIVIDER};
    padding-top: 40px;
`;

function LeftNav(props) {
    const {
        children,
        leftDesktopContent,
        location = props.location || {},
        navigations = props.navigations || [],
        padding,
        reverseBackground,
        title,
        to
    } = props;

    const { t } = useTranslation();

    // For phone, we only want to show the nav and nothing else
    // when we are at the root landing page (nothing active).
    let Col1;
    let Content;
    if (props.isRoot === true) {
        Col1 = LeftNavCol;
        Content = PageContentHiddenForPhone;
    } else {
        Col1 = ResponsiveLeftNavCol;
        Content = PageContent;
    }
    const currentPath = location.pathname;
    const DesktopContent = leftDesktopContent;
    const variant = reverseBackground ? 'reverse' : '';
    return (
        <LeftNavContainer>
            <Col1 reverse={reverseBackground}>
                <ListStyled component="nav">
                    <ListItemStyled button={true} variant={variant} component={React.forwardRef((props, ref) => ( <Link {...props} ref={ref} />))} to={to}>
                        <ListItemText>
                            <NavHeader>{t(title)}</NavHeader>
                        </ListItemText>
                    </ListItemStyled>
                    {navigations.map((nav, i) => {
                        const isSelected =
                            nav.to === currentPath || nav.to === props.select;
                        return (
                            <ListItemStyled
                                key={i}
                                button={true}
                                component={React.forwardRef(({ reverse, ...rest }, ref) => ( <Link {...rest} ref={ref} /> ))}
                                to={nav.to}
                                selected={isSelected}
                                reverse={reverseBackground}
                            >
                                <ListItemTextStyled selected={isSelected}>
                                    {t(nav.name)}
                                </ListItemTextStyled>
                            </ListItemStyled>
                        );
                    })}
                </ListStyled>
                {leftDesktopContent !== null && (
                    <DesktopContentContainer>
                        <DesktopContent />
                    </DesktopContentContainer>
                )}
            </Col1>
            <Content padding={padding}>{children}</Content>
        </LeftNavContainer>
    );
}

/**
 * Possible values of padding:
 * - 'none' (default) - absolutely no padding
 * - 'container' - only enough to avoid browser horizontal scrollbar when using a grid
 * - 'basic' - default padding for full white page
 */
LeftNav.propTypes = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    navigations: PropTypes.array.isRequired,
    select: PropTypes.string,
    padding: PropTypes.string,
    reverseBackground: PropTypes.bool,
    leftDesktopContent: PropTypes.func,
    isRoot: PropTypes.bool
};

LeftNav.defaultProps = {
    padding: 'basic',
    reverseBackground: false,
    leftDesktopContent: null,
    isRoot: false
};

export default withRouter(LeftNav);
