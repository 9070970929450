import styled, { materialUiTheme } from 'theme/index';
import AppConstants from 'AppConstants.js';
import Button from '@material-ui/core/Button';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PropTypes from 'prop-types';
import React from 'react';
import { supportedChartingViews } from './ChartDataTabs';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    ${materialUiTheme.breakpoints.down('xs')} {
        width: 100%;
    }
`;

const BarButton = styled(Button)`
    && {
        min-width: 0;
        padding: 8px;
        color: ${props =>
    props.active === 'true'
        ? AppConstants.COLOR_CHART_ACTIVE_BUTTON
        : AppConstants.COLOR_CHART_INACTIVE_BUTTON};
    }
`;

const BarIcon = styled(EqualizerIcon)`
    && {
        font-size: 32px;
    }
`;

const PieIcon = styled(DonutLargeIcon)`
    && {
        font-size: 32px;
    }
`;

function BarPieToggle(props) {
    const {
        onSelectChartType,
        selection
    } = props;

    const { t } = useTranslation();

    return (
        <ToggleContainer>
            <Tooltip title={t('common.charting.barPieToggle.bar')} placement="bottom" disableFocusListener={true}>
                <BarButton
                    id="bar-toggle-button"
                    onClick={() => onSelectChartType(supportedChartingViews.chartBar)}
                    active={(selection === supportedChartingViews.chartBar).toString()}
                >
                    <BarIcon />
                </BarButton>
            </Tooltip>
            <Tooltip title={t('common.charting.barPieToggle.pie')} placement="bottom" disableFocusListener={true}>
                <BarButton
                    id="pie-toggle-button"
                    onClick={() => onSelectChartType(supportedChartingViews.chartPie)}
                    active={(selection === supportedChartingViews.chartPie).toString()}
                >
                    <PieIcon />
                </BarButton>
            </Tooltip>
        </ToggleContainer>
    );
}

BarPieToggle.propTypes = {
    selection: PropTypes.string.isRequired,
    onSelectChartType: PropTypes.func.isRequired
};

export default BarPieToggle;
