import React from 'react';
import styles from './ErrorPage.module.css';
import { useTranslation } from 'react-i18next';

export const ErrorPage = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <h1>{t('dialog.oops.title')}</h1>
            <img className={styles.errorImg} src="/img/bad_reception.svg" alt="error" />
            <p className={styles.errorMsg}>{t('dialog.oops.message')}</p>
        </div>
    );
};
