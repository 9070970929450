export default class NewCheckDetails {
    constructor(nameOnAccount, accountNumber, routingNumber, accountType) {
        this.name_on_account = nameOnAccount;
        this.account_number = accountNumber;
        this.routing_number = routingNumber;
        this.account_type = accountType;
    }

    getDisplayName() {
        return this.account_type;
    }
}
