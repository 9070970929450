import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemedTextField } from 'common/styles';

/**
 * See https://github.com/s-yadav/react-number-format#readme for more
 * information about NumberFormat options.
 * @param {*} props
 */
function ExpirationDateCustomFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <Cleave
            {...other}
            htmlRef={inputRef}
            options={{date: true, datePattern: ['m', 'y']}}
            onChange={onChange}
        />
    );
}

ExpirationDateCustomFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function ExpirationDateField(props) {
    return (
        <ThemedTextField
            {...props}
            type="text"
            InputProps={{ inputComponent: ExpirationDateCustomFormat }}
        />
    );
}
