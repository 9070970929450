import { ThemedListContainer, ThemedListItem } from 'common/styles';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Displays a row in a {@link MembershipList}.
 */
function MembershipListItem(props) {
    const {
        membership,
        onClickMembership
    } = props;

    const { t } = useTranslation();

    return (
        <ThemedListItem key={membership.memberNumber} button onClick={() => onClickMembership(membership.memberNumber)}>
            <img src="/img/membership.svg" width="41" height="41" alt={t('common.aria.membershipIcon')} />
            <ListItemText primary={membership.getDisplayName(t)} secondary={membership.getSubTitle(t)} />
        </ThemedListItem>
    );
}

MembershipListItem.propTypes = {
    membership: PropTypes.object.isRequired,
    onClickMembership: PropTypes.func.isRequired
};

/**
 * Displays a list of memberships in session.
 */
function MembershipList(props) {
    const {
        memberships = props.memberships || [],
        onClickMembership
    } = props;

    const { t } = useTranslation();

    return (
        <ThemedListContainer>
            <List>
                {memberships.map(membership => {
                    return (
                        <MembershipListItem
                            key={membership.memberNumber}
                            membership={membership}
                            onClickMembership={onClickMembership}
                            t={t}
                        />
                    );
                })}
            </List>
        </ThemedListContainer>
    );
}

MembershipList.propTypes = {
    memberships: PropTypes.array.isRequired,
    onClickMembership: PropTypes.func.isRequired
};

export default MembershipList;
