import BarChart, { BarChartUnit } from 'common/components/charting/BarChart';
import ChartDataTabs, { supportedChartingViews, supportedTabs } from 'common/components/charting/ChartDataTabs';
import AccountBillsList from 'common/components/charting/AccountBillsList';
import AppConstants from 'AppConstants.js';
import BillingHistoryBarChartAdapter from 'common/charting/BillingHistoryBarChartAdapter';
import BillingHistoryPieChartAdapter from 'common/charting/BillingHistoryPieChartAdapter';
import ChartIcon from 'common/components/ChartIcon';
import ChartStatistics from 'common/components/charting/ChartStatistics';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import dayjs from 'dayjs';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import LeftNav from 'common/components/LeftNav';
import { mapStateToProps } from 'reducers';
import { navigations } from 'features/billing/navigations';
import Page from 'common/components/Page';
import PieChart from 'common/components/charting/PieChart';
import React from 'react';
import StatementsIcon from 'common/components/StatementsIcon';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { timeScale } from 'common/components/charting/ChartSettingsDrawer';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    tabsIndicator: {
        backgroundColor: AppConstants.COLOR_PRIMARY_LIGHT
    },
    tabRoot: {
        maxWidth: 'none',
        width: '160px',
        paddingTop: 0,
        [theme.breakpoints.down('xs')]: {
            width: '50%'
        }
    },
    tabSelected: {
        color: AppConstants.COLOR_PRIMARY_LIGHT
    },
    typography: {
        fontSize: 50
    }
});

class BillingHistoryContainer extends ContainerBase {
    constructor(props) {
        const {
            appState = props.appState || {},
            dispatch
        } = props;

        const accountsSorted = appState.accountsSorted && appState.accountsSorted(props.t);
        const accountsToGraph = accountsSorted.slice(0, 4);

        super(props, {
            currentView: supportedChartingViews.chartBar,
            showTemperatureData: true,
            timeScale: timeScale.MONTHLY,
            accounts: accountsSorted,
            selectedAccounts: accountsToGraph,
            barChartSeries: [],
            barChartXAxis: [],
            barTemperatures: [],
            chartIsLoading: true,
            pieChartLegend: [],
            pieChartSeries: [],
            totalAverage: 0,
            highTemperatureAverage: 0,
            lowTemperatureAverage: 0,
            chartVersion: 0,
            dataIsLoading: true,
            dataSelectedAccount: accountsSorted[0],
            billingHistory: [],
            selectedTab: supportedTabs.chartTab
        });

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });
    }

    componentDidMount() {
        this.getBillingHistoryAndDrawCharts(this.state.selectedAccounts);
    }

    render() {
        const {
            accounts,
            barChartSeries,
            barChartXAxis,
            barTemperatures,
            billingHistory,
            chartIsLoading,
            chartVersion,
            currentView,
            dataIsLoading,
            highTemperatureAverage,
            lowTemperatureAverage,
            pieChartLegend,
            pieChartSeries = this.state.pieChartSeries || [],
            selectedAccounts,
            selectedTab,
            showTemperatureData,
            totalAverage
        } = this.state;

        const {
            classes = this.props.classes || {},
            t
        } = this.props;

        const xAxis = barChartXAxis;
        const xAxisStart = xAxis.length >= 30 ? xAxis[xAxis.length - 30] : null;
        const temperatureAverages = `${highTemperatureAverage}° / ${lowTemperatureAverage}°`;

        const tabs = (
            <Tabs
                value={selectedTab}
                onChange={this.onChangeTab}
                classes={{
                    root: classes.tabsRoot,
                    indicator: classes.tabsIndicator
                }}
            >
                <Tab
                    value={supportedTabs.chartTab}
                    icon={<ChartIcon t={t} />}
                    classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected
                    }}
                />
                <Tab
                    value={supportedTabs.dataTab}
                    icon={<StatementsIcon t={t} />}
                    classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected
                    }}
                />
            </Tabs>
        );

        const stats = () => chartIsLoading ? (
            <ChartStatistics
                description1={t('billing.history.callouts.averageMonthlyBill')}
                metric1="--"
                description2={t('common.charting.chartStatistics.temperatureAverage')}
                metric2="-- / --"
            />
        ) : (
            <ChartStatistics
                description1={t('billing.history.callouts.averageMonthlyBill')}
                metric1={`$${parseInt(totalAverage, 10).toLocaleString()}`}
                description2={t('common.charting.chartStatistics.temperatureAverage')}
                metric2={temperatureAverages}
            />
        );

        return (
            <Page padding="none">
                <LeftNav
                    title="billing.header"
                    to="/billing"
                    navigations={navigations}
                    padding="none"
                    reverseBackground={true}
                    leftDesktopContent={stats}
                >
                    <ChartDataTabs
                        title={t('billing.history.header')}
                        accounts={accounts}
                        onSelectAccount={this.onSelectAccount}
                        onChangeView={this.onChangeView}
                        topPhoneContent={stats}
                        showTemperatureData={showTemperatureData}
                        supportsTimeScale={false}
                        timeScale="monthly"
                        selectedAccounts={selectedAccounts}
                        onSettingsUpdated={this.onSettingsUpdated}
                        tabs={tabs}
                        selectedChartView={currentView}
                        selectedTab={selectedTab}
                    >
                        {
                            selectedTab === supportedTabs.chartTab
                            && currentView === supportedChartingViews.chartBar
                            && <BarChart
                                key={chartVersion}
                                series={barChartSeries}
                                xaxis={barChartXAxis}
                                xaxisStart={xAxisStart}
                                temperatureSeries={barTemperatures}
                                showTemperature={showTemperatureData}
                                units={BarChartUnit.USD}
                                isLoading={chartIsLoading}
                            />
                        }
                        {
                            selectedTab === supportedTabs.chartTab
                            && currentView === supportedChartingViews.chartPie
                            && <PieChart
                                key={chartVersion}
                                series={pieChartSeries.map((value, i) => ({
                                    name: pieChartLegend[i],
                                    value: value
                                }))}
                                calloutValue={`$${parseInt(totalAverage, 10).toLocaleString()}`}
                                calloutDescription={t('billing.history.callouts.averageMonthlyBill')}
                                isLoading={chartIsLoading}
                            />
                        }
                        {
                            selectedTab === supportedTabs.dataTab &&
                            <AccountBillsList loading={dataIsLoading} bills={billingHistory}/>
                        }
                    </ChartDataTabs>
                </LeftNav>
            </Page>
        );
    }

    /*
     * This is called within the data tab only
     */
    onSelectAccount = selectedAccount => {
        this.setState({ dataIsLoading: true });
        this.getBillingHistoryAndShowData(selectedAccount);
    }

    onChangeTab = (event, selectedTab) => {
        const {
            dataIsLoading,
            dataSelectedAccount
        } = this.state;

        this.setState({ selectedTab: selectedTab });

        if (selectedTab === supportedTabs.dataTab && dataIsLoading) {
            this.getBillingHistoryAndShowData(dataSelectedAccount);
        }
    }

    onChangeView = chartingView => {
        const {
            chartVersion
        } = this.state;

        const barChartVersion = chartingView === supportedChartingViews.chartBar
            ? chartVersion + 1
            : chartVersion;

        this.setState({
            currentView: chartingView,
            chartVersion: barChartVersion
        });
    }

    /*
     * This is called within the chart tab only
     */
    onSettingsUpdated = (showTemperatureData, timeScale, updatedAccountSelections = []) => {
        const {
            selectedAccounts = this.state.selectedAccounts || []
        } = this.state;

        let requiresNewFetch = false;
        if (updatedAccountSelections.length !== selectedAccounts.length) {
            requiresNewFetch = true;
        } else {
            for (let i = 0; i < updatedAccountSelections.length; i++) {
                if (selectedAccounts[i].accountNumber !== updatedAccountSelections[i].accountNumber) {
                    requiresNewFetch = true;
                    break;
                }
            }
        }

        if (requiresNewFetch) {
            this.setState(state => ({
                showTemperatureData: showTemperatureData,
                selectedAccounts: updatedAccountSelections,
                barChartSeries: [],
                pieChartSeries: [],
                chartIsLoading: true,
                chartVersion: state.chartVersion + 1
            }));
            this.getBillingHistoryAndDrawCharts(updatedAccountSelections);
        } else {
            this.setState(state => ({
                showTemperatureData: showTemperatureData,
                selectedAccounts: updatedAccountSelections,
                chartVersion: state.chartVersion + 1
            }));
        }
    }

    getBillingHistoryAndDrawCharts(selectedAccounts = []) {
        const { t } = this.props;
        const { chartVersion } = this.state;

        if (selectedAccounts === undefined || selectedAccounts.length === 0) {
            this.setState({ chartIsLoading: false });
            return;
        }

        const { startDate, endDate } = this.getTimeParameters();

        const temperaturePromise = this.api.getAccountTemperatures(
            selectedAccounts[0].accountNumber,
            startDate,
            endDate,
            'monthly'
        );

        const billingPromises = selectedAccounts.map(it =>
            this.api.getBillingHistory(it.accountNumber, startDate, endDate)
        );

        Promise.all([temperaturePromise, ...billingPromises])
            .then(temperatureAndBillingResponses => {
                const barChartData = BillingHistoryBarChartAdapter.adapt(
                    selectedAccounts,
                    temperatureAndBillingResponses,
                    t,
                    startDate,
                    endDate
                );

                const pieChartData = BillingHistoryPieChartAdapter.adapt(
                    temperatureAndBillingResponses
                );

                this.setState({
                    chartVersion: chartVersion + 1,
                    barChartSeries: barChartData.series,
                    barChartXAxis: barChartData.xaxis,
                    pieChartLegend: barChartData.legend,
                    pieChartSeries: pieChartData.averages,
                    totalAverage: pieChartData.totalAverage,
                    highTemperatureAverage: pieChartData.highTemperatureAverage,
                    lowTemperatureAverage: pieChartData.lowTemperatureAverage,
                    barTemperatures: barChartData.temperatureSeries,
                    chartIsLoading: false
                });
            })
            .catch(error => {
                this.setState({ chartIsLoading: false });
                this.showErrorDialog(error);
            });
    }

    getBillingHistoryAndShowData(selectedAccount) {
        const { startDate, endDate } = this.getTimeParameters();
        this.api
            .getBillingHistory(
                selectedAccount.accountNumber,
                startDate,
                endDate
            )
            .then(billingResponse => {
                this.setState({
                    dataIsLoading: false,
                    billingHistory: billingResponse
                });
            })
            .catch(error => {
                this.showErrorDialog(error);
            });
    }

    getTimeParameters() {
        const today = dayjs();

        const endDate = today
            .startOf('month')
            .subtract(1, 'day')
            .startOf('day');

        const startDate = today
            .startOf('month')
            .subtract(59, 'month')
            .startOf('day');

        return {
            startDate: startDate,
            endDate: endDate
        };
    }
}

export default connect(mapStateToProps)(withStyles(styles)(withTranslation()(BillingHistoryContainer)));
