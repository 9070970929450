import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid/Grid';
import mapStateToProps from 'reducers';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

class AccountType extends React.Component {
    render() {
        const {
            account = this.props.account || {},
            className,
            t
        } = this.props;

        return (
            <Grid container direction="row" justifyContent="space-between" className={className}>
                <div>{t('common.accounts.type')}</div>
                <div>{t(`common.accounts.types.${account.type}`)}</div>
            </Grid>
        );
    }
}

AccountType.propTypes = {
    account: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(withTranslation()(AccountType));
