import styled, { materialUiTheme } from 'theme';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { comparePaymentMethods } from 'features/billing/comparators';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import { DialogBasic } from 'common/components/dialogs';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Intro } from 'features/wizard/styles';
import LoadingIndicator from 'common/components/LoadingIndicator';
import { mapStateToProps } from 'reducers';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import NewCardDialog from 'features/billing/components/NewCardDialog';
import NewCheckDialog from 'features/billing/components/NewCheckDialog';
import PaymentMethod from 'model/PaymentMethod';
import ProgressButton from 'common/components/ProgressButton';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemedActionUpper } from 'common/styles';
import Transition from 'react-transition-group/Transition';
import { updateAutomaticPayments } from 'actions';
import { withTranslation } from 'react-i18next';
import WizardStep from 'features/wizard/components/WizardStep';

const WizardLoadingIndicator = styled(LoadingIndicator)`
    margin-top: 60px;
`;

const defaultStyle = {
    transition: `opacity 500ms ease-in-out`,
    opacity: 0
};

const transitionStyles = {
    entering: { opacity: 0, display: 'flex' },
    entered: { opacity: 1 },
    exited: { display: 'none' }
};

const TransitionContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const CenterCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    ${materialUiTheme.breakpoints.only('sm')} {
        position: relative;
    }
`;

const AutoPaymentsImage = styled.img`
    height: 154px;
    margin-top: 10px;
    margin-bottom: 20px;
`;

const IntroSetup = styled(Intro)`
    margin-bottom: 60px;
`;

const IntroSelect = styled(Intro)`
    margin-top: 20px;
    margin-bottom: 20px;
`;

const SetupAutoPaymentsButton = styled(Button)`
    && {
        min-width: 240px;
    }
`;

const NoThanksButton = styled(Button)`
    && {
        min-width: 240px;
        margin-top: 16px;
    }
`;

const SaveButton = styled(ProgressButton)`
    && {
        min-width: 240px;
    }
`;

const CancelButton = styled(Button)`
    && {
        min-width: 240px;
        margin-top: 16px;
    }
`;

const SelectAccountFormGroup = styled(FormGroup)`
    margin-bottom: 20px;
`;

const SelectMethodLink = styled(ThemedActionUpper)`
    margin-bottom: 30px;
`;

class WizardAutomaticPaymentsContainer extends ContainerBase {
    constructor(props) {
        const {
            appState = props.appState || {},
            dispatch
        } = props;

        const wizardContext = (appState && appState.wizardContext) || {};
        const wizardAccounts = (wizardContext.getAccountsAddedByWizard &&
            wizardContext.getAccountsAddedByWizard(appState)) || [];

        const accountsNotSetup = wizardAccounts.filter(it => !it.automaticPayment) || [];

        const selectedAccounts = accountsNotSetup.map(it => {
            return { account: it, selected: false };
        });

        super(props, {
            processing: false,
            showAccountSelection: false,
            selectedPaymentMethod: null,
            selectedPaymentMethodType: '',
            selectMenuAnchorEl: null,
            selectedAccounts,
            paymentMethods: [],
            loadingPaymentMethods: false,
            linkText: 'wizard.automaticPayments.selectPaymentMethod',
            basicDialogIsOpen: false,
            errorMessageTitle: '',
            errorMessageBody: '',
            isNewCardDialogOpen: false
        });

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });
        this.newCardDialogRef = React.createRef();
        this.newCheckDialogRef = React.createRef();
    }

    onContinue = () => {
        const { onContinue } = this.props;

        onContinue();
    };

    render() {
        const { t } = this.props;

        const {
            basicDialogIsOpen,
            errorMessageBody,
            errorMessageTitle,
            linkText,
            loadingPaymentMethods,
            selectedAccounts = this.state.selectedAccounts || [],
            selectMenuAnchorEl,
            showAccountSelection,
            paymentMethods = this.state.paymentMethods || [],
            processing
        } = this.state;

        return (
            <WizardStep heading="wizard.automaticPayments.header">
                <TransitionContainer>
                    <Transition in={!showAccountSelection} timeout={0}>
                        {state => (
                            <CenterCol
                                style={{
                                    ...defaultStyle,
                                    ...transitionStyles[state]
                                }}
                            >
                                <AutoPaymentsImage src="/img/wizard_automatic_payments.svg" />
                                <IntroSetup>
                                    {t('wizard.automaticPayments.intro')}
                                </IntroSetup>
                                <SetupAutoPaymentsButton
                                    key="continue"
                                    variant="contained"
                                    color="primary"
                                    onClick={this.onSetupToggle}
                                >
                                    {t('wizard.automaticPayments.buttonContinue')}
                                </SetupAutoPaymentsButton>
                                <NoThanksButton
                                    key="skip"
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.onContinue}
                                >
                                    {t('wizard.automaticPayments.buttonSkip')}
                                </NoThanksButton>
                            </CenterCol>
                        )}
                    </Transition>
                    <Transition in={showAccountSelection} timeout={0}>
                        {loadingPaymentMethods ? (
                            <WizardLoadingIndicator />
                        ) : (
                            state => (
                                <CenterCol
                                    style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }}
                                >
                                    <IntroSelect>
                                        {t('wizard.automaticPayments.selectAccount')}
                                    </IntroSelect>
                                    <SelectAccountFormGroup>
                                        {selectedAccounts.map(
                                            (it, i) => (
                                                <FormControlLabel
                                                    key={it.account.accountNumber}
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            checked={it.selected}
                                                            onChange={this.checkboxChange(i)}
                                                            value="true"
                                                            disabled={processing}
                                                        />
                                                    }
                                                    label={it.account.getDisplayName(t)}
                                                />
                                            )
                                        )}
                                    </SelectAccountFormGroup>
                                    <SelectMethodLink onClick={e => this.onSelectMethod(e)}>
                                        {t(linkText)}
                                    </SelectMethodLink>

                                    <SaveButton
                                        key="save"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.onSave}
                                        loading={processing}
                                    >
                                        {t('common.saveButton')}
                                    </SaveButton>
                                    <CancelButton
                                        key="cancel"
                                        color="primary"
                                        onClick={this.onSetupToggle}
                                        disabled={processing}
                                    >
                                        {t('common.cancel')}
                                    </CancelButton>
                                </CenterCol>
                            )
                        )}
                    </Transition>
                </TransitionContainer>
                <Menu
                    id="select-menu"
                    anchorEl={selectMenuAnchorEl}
                    open={Boolean(selectMenuAnchorEl)}
                    onClose={this.handleSelectMenuClose}
                >
                    {paymentMethods
                        .sort((a, b) => comparePaymentMethods(a, b, t))
                        .map(paymentMethod => {
                            return (
                                <MenuItem
                                    key={paymentMethod.id}
                                    value={paymentMethod.id}
                                    onClick={e => this.handleSelectMenuItemClick(paymentMethod, e)}
                                >
                                    {t(paymentMethod.getDisplayName(t))}
                                </MenuItem>
                            );
                        })}
                    <MenuItem
                        value="enter-card"
                        onClick={e => this.handleSelectMenuItemClick('enter-card', e)}
                    >
                        {t('billing.enterCard.header')}
                    </MenuItem>
                    <MenuItem
                        value="enter-check"
                        onClick={e => this.handleSelectMenuItemClick('enter-check', e)}
                    >
                        {t('billing.enterCheck.header')}
                    </MenuItem>
                </Menu>
                <DialogBasic
                    message={t(errorMessageBody)}
                    onCloseDialog={this.closeBasicDialog}
                    open={basicDialogIsOpen}
                    title={t(errorMessageTitle)}
                />
                <NewCardDialog ref={this.newCardDialogRef} onSave={this.onNewCardSave} />
                <NewCheckDialog ref={this.newCheckDialogRef} onSave={this.onNewCheckSave} />
            </WizardStep>
        );
    };

    onSetupToggle = () => {
        const { showAccountSelection } = this.state;

        if (showAccountSelection) {
            this.setState({ showAccountSelection: false });
        } else {
            this.setState({
                showAccountSelection: true,
                loadingPaymentMethods: true
            });
            this.loadPaymentMethods();
        }
    };

    checkboxChange = i => ({ target } = {}) => {
        const { selectedAccounts } = this.state;
        const updated = selectedAccounts;
        updated[i].selected = target.checked;
        this.setState({ selectedAccounts: updated });
    };

    onSelectMethod = e => {
        e.preventDefault();
        this.setState({ selectMenuAnchorEl: e.currentTarget });
    };

    handleSelectMenuItemClick = value => {
        const { processing } = this.state;
        const { t } = this.props;

        if (processing === true) {
            return;
        }

        switch (value) {
            case 'enter-check':
                this.newCheckDialogRef.current.getWrappedInstance().open();
                this.setState({ selectMenuAnchorEl: null });
                break;

            case 'enter-card':
                this.newCardDialogRef.current.getWrappedInstance().open();
                this.setState({ selectMenuAnchorEl: null });
                break;

            default:
                this.setState({
                    selectMenuAnchorEl: null,
                    selectedPaymentMethod: value,
                    selectedPaymentMethodType: 'saved',
                    linkText: value.getDisplayName(t)
                });
        }
    };

    handleSelectMenuClose = () => {
        this.setState({ selectMenuAnchorEl: null });
    };

    loadPaymentMethods = () => {
        this.api
            .getPaymentMethods()
            .then(responses => {
                const paymentMethods = responses.map(it => new PaymentMethod(it));
                this.setState({
                    loadingPaymentMethods: false,
                    paymentMethods: paymentMethods
                });
            })
            .catch(error => {
                this.setState({ loadingPaymentMethods: false });
                this.showErrorDialog(error);
            });
    };

    onSave = () => {
        const {
            selectedAccounts = this.state.selectedAccounts || [],
            selectedPaymentMethod,
            selectedPaymentMethodType
        } = this.state;

        const { dispatch, onContinue } = this.props;

        if (!this.isFormValid()) {
            return;
        }

        this.setState({ processing: true });
        const promises = selectedAccounts
            .filter(it => it.selected)
            .map(it =>
                this.api.setupAutomaticPayment(
                    it.account.accountNumber,
                    selectedPaymentMethod,
                    selectedPaymentMethodType
                )
            );

        Promise.all(promises)
            .then(() => {
                const automaticPaymentUpdates = selectedAccounts
                    .filter(it => it.selected)
                    .map(it => {
                        return {
                            accountNumber: it.account.accountNumber,
                            newStatus: true
                        };
                    });
                dispatch(updateAutomaticPayments(automaticPaymentUpdates));
                onContinue();
            })
            .catch(error => {
                this.setState({ processing: false });

                if (
                    error.response &&
                    error.response.code &&
                    error.response.code === 422 &&
                    error.response.errors
                ) {
                    let errorShown = false;
                    // eslint-disable-next-line no-unused-vars
                    for (const errorDetail of error.response.errors) {
                        if (
                            errorDetail.field === 'card_details.name_on_card' &&
                            errorDetail.error_code === 'invalid'
                        ) {
                            errorShown = true;
                            this.setState({
                                errorMessageTitle: 'billing.makePayment.error.invalidNameOnCard.title',
                                errorMessageBody: 'billing.makePayment.error.invalidNameOnCard.body'
                            });
                            this.showBasicDialog();
                            break;
                        } else if (
                            errorDetail.field === 'card_number' &&
                            errorDetail.error_code === 'unsupported_card_brand'
                        ) {
                            errorShown = true;
                            this.setState({
                                errorMessageTitle: 'billing.validation.creditCard.unsupportedCardBrand.title',
                                errorMessageBody: 'billing.validation.creditCard.unsupportedCardBrand.message'
                            });
                            this.showBasicDialog();
                            break;
                        } else if (
                            errorDetail.field === 'routing_number' &&
                            errorDetail.error_code === 'unsupported_financial_institution'
                        ) {
                            errorShown = true;
                            this.setState({
                                errorMessageTitle: 'billing.validation.checking.unsupportedFinancialInstitution.title',
                                errorMessageBody: 'billing.validation.checking.unsupportedFinancialInstitution.message'
                            });
                            this.showBasicDialog();
                            break;
                        }
                    }

                    if (!errorShown) {
                        this.showErrorDialog(error);
                    }
                } else {
                    this.showErrorDialog(error);
                }
            });
    };

    isFormValid() {
        const {
            selectedAccounts = this.state.selectedAccounts || [],
            selectedPaymentMethod
        } = this.state;

        if (selectedAccounts.filter(it => it.selected).length === 0) {
            this.setState({
                errorMessageTitle: 'billing.automaticPayments.validation.noAccountSelected.title',
                errorMessageBody: 'billing.automaticPayments.validation.noAccountSelected.body'
            });
            this.showBasicDialog();
            return false;
        }

        if (selectedPaymentMethod === null) {
            this.setState({
                errorMessageTitle: 'billing.automaticPayments.validation.noPaymentMethodSelected.title',
                errorMessageBody: 'billing.automaticPayments.validation.noPaymentMethodSelected.body'
            });
            this.showBasicDialog();
            return false;
        }

        return true;
    }

    onNewCardSave = cardDetails => {
        const { t } = this.props;

        this.setState({
            selectMenuAnchorEl: null,
            selectedPaymentMethod: cardDetails,
            selectedPaymentMethodType: 'card',
            linkText: cardDetails.getDisplayName(t)
        });
    };

    onNewCheckSave = checkDetails => {
        this.setState({
            selectMenuAnchorEl: null,
            selectedPaymentMethod: checkDetails,
            selectedPaymentMethodType: 'checking',
            linkText: checkDetails.getDisplayName()
        });
    };
}

WizardAutomaticPaymentsContainer.propTypes = {
    onContinue: PropTypes.func.isRequired,
    wizardContext: PropTypes.object.isRequired,
    orientation: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(withTranslation()(WizardAutomaticPaymentsContainer));
