import EqualizerIcon from '@material-ui/icons/Equalizer';
import React from 'react';
import styled from 'theme';
import { useTranslation } from 'react-i18next';

const TabIconContainer = styled.span`
    display: flex;
    align-items: center;
    font-size: 14px;
`;

const StyledPollIcon = styled(EqualizerIcon)`
    && {
        font-size: 16px;
        margin-right: 10px;
    }
`;

function ChartIcon() {
    const { t } = useTranslation();

    return (
        <TabIconContainer>
            <StyledPollIcon />
            {t('common.charting.tabs.charts')}
        </TabIconContainer>
    );
}

export default ChartIcon;
