import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NavButton = (props) => {
    const {
        isCancel,
        isMixedCase,
        spacingRight,
        to,
        ...rest
    } = props;

    const { t } = useTranslation();

    const buttonStyles = {
        marginRight: !!spacingRight ? '20px' : '0',
        textTransform: isMixedCase === true ? 'inherit' : 'uppercase'
    };

    return (
        <>
            {to && (
                <Button {...rest} style={buttonStyles} to={to} href={to} component={RouterLink}>
                    {isCancel ? t('common.cancel') : props.children}
                </Button>
            )}
            {!to && (
                <Button {...rest} style={buttonStyles}>
                    {isCancel ? t('common.cancel') : props.children}
                </Button>
            )}
        </>
    );
};

NavButton.propTypes = {
    isCancel: PropTypes.bool,
    isMixedCase: PropTypes.bool,
    spacingRight: PropTypes.bool,
    to: PropTypes.string
};

NavButton.defaultProps = {
    isCancel: false,
    spacingRight: false,
    isMixedCase: false
};
