import 'locales';
import 'react-app-polyfill/ie11';
import 'polyfill/Array.prototype.find';
import 'polyfill/String.prototype.startsWith';
import { ErrorBoundary, ErrorPage } from 'common/components/error-boundary';
import styled, { materialUiTheme } from 'theme';
import { AppBar } from 'common/components/app-bar';
import AppConstants from 'AppConstants.js';
import { BrowserRouter } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import checkBrowser from 'utils/check-browser';
import { createStore } from 'reducers/storeBuilder';
import CssBaseline from '@material-ui/core/CssBaseline';
import { DialogError } from './common/components/dialogs';
import Footer from 'common/components/Footer';
import { Helmet } from 'react-helmet';
import { MuiThemeProvider } from '@material-ui/core/styles';
import NotificationBanner from 'common/components/NotificationBanner';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'common/components/Root';
import Routes from 'common/components/Routes';
import SoleilFonts from 'fonts/FontLoader';

const store = createStore();

window.bugsnagClient = Bugsnag.start({
    apiKey: 'bf21cd6d146ea9b99fecd806c8f13d03',
    appVersion: process.env.REACT_APP_VERSION,
    releaseStage: process.env.REACT_APP_ENV,
    enabledReleaseStages: ['qa', 'production']
});

const browser = checkBrowser();
if (browser.unsupported) {
    document.getElementById('unsupported-browser').style.display = 'block';
} else {
    const ContentContainer = styled(Routes)`
        display: flex;
        flex-grow: 1;
        background-color: #ffffff;
        max-width: ${AppConstants.MAX_WIDTH}px;
        width: 100%;
        position: relative;
    `;

    ReactDOM.render(
        <React.Fragment>
            <SoleilFonts/>
            <Provider store={store}>
                <MuiThemeProvider theme={materialUiTheme}>
                    <ErrorBoundary
                        render={() => (
                            <BrowserRouter>
                                <Root>
                                    {process.env.REACT_APP_ENV !== 'production' && (
                                        <Helmet>
                                            <meta
                                                name="robots"
                                                content="noindex"
                                            />
                                        </Helmet>
                                    )}
                                    <CssBaseline />
                                    <AppBar />
                                    <NotificationBanner />
                                    <DialogError />
                                    <ContentContainer />
                                    <Footer />
                                </Root>
                            </BrowserRouter>
                        )}
                        renderError={() => <ErrorPage />}
                    >
                    </ErrorBoundary>
                </MuiThemeProvider>
            </Provider>
        </React.Fragment>,
        document.getElementById('root')
    );

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
        });
    }
}
