import AppState from 'model/AppState';
import appState from 'reducers/appStateReducer';
import { combineReducers } from 'redux';

/**
 * This is the default mapper between the Redux store and component properties.
 * Use this to define the appState property in your container or component.
 * @param {*} state
 */
export const mapStateToProps = state => {
    const appState = new AppState(state.appState);
    return { appState };
};

export default combineReducers({
    appState
});
