import Button from '@material-ui/core/Button';
import DebounceButton from "../DebounceButton";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const DialogConfirm = (props) => {
    const {
        confirmButtonLabel,
        message,
        messageIsMarkdown,
        onCloseDialog,
        open,
        title,
        loading
    } = props;

    const { t } = useTranslation();

    const content = messageIsMarkdown ?
        <Markdown>{message}</Markdown> :
        <DialogContentText>{message}</DialogContentText>;

    return (
        <Dialog
            open={open}
            onClose={e => onCloseDialog(false, e)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                <Button onClick={e => onCloseDialog(false, e)} color="primary">
                    {t('common.cancel')}
                </Button>

                <DebounceButton
                    debounceTime={1000}
                    loading={loading}
                    onClick={e => onCloseDialog(true, e)}
                    color="primary"
                >
                    {confirmButtonLabel}
                </DebounceButton>
            </DialogActions>
        </Dialog>
    );
};

DialogConfirm.propTypes = {
    confirmButtonLabel: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    messageIsMarkdown: PropTypes.bool,
    onCloseDialog: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    loading: PropTypes.bool
};

DialogConfirm.defaultProps = {
    messageIsMarkdown: false,
    loading: false
};
