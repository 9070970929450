import { ThemedListContainer, ThemedListItem } from 'common/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PropTypes from 'prop-types';
import React from 'react';
import ThreeLineListItemText from 'common/components/ThreeLineListItemText';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

/**
 * Displays a row in an {@link AuthorizedUserList}.
 */
function AuthorizedUserListItem(props) {
    const {
        authorizedUser = props.authorizedUser || {},
        onClickDelete
    } = props;

    const { t } = useTranslation();
    const authorizedUserUserDetails = authorizedUser.user || {};
    const userName = authorizedUserUserDetails.first_name + ' ' + authorizedUserUserDetails.last_name;

    let grantedItem;
    if (authorizedUser.account_number !== null) {
        grantedItem = t('common.accounts.subtitle', { accountNumber: authorizedUser.account_number });
    } else {
        grantedItem = t('common.memberships.subtitle', { memberNumber: authorizedUser.member_number });
    }

    const formattedDate = t('settings.authorizedUsers.grantedOn', {
        date: new Date(authorizedUser.created_at).toLocaleDateString('en-US')
    });

    return (
        <ThemedListItem key={authorizedUser.id}>
            <img src="/img/membership.svg" width="41" height="41" alt="Authorized User Icon" />

            <ThreeLineListItemText primary={userName} secondary={grantedItem} tertiary={formattedDate} />

            <ListItemSecondaryAction>
                <Tooltip title={t('settings.authorizedUsers.confirmDeleteModal.confirmButton')} placement="bottom">
                    <IconButton aria-label={t('common.aria.deleteButton')} onClick={() => onClickDelete(authorizedUser)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ThemedListItem>
    );
}

AuthorizedUserListItem.propTypes = {
    authorizedUser: PropTypes.object.isRequired,
    onClickDelete: PropTypes.func.isRequired
};

/**
 * Displays a list of authorized users.
 */
function AuthorizedUserList(props) {
    const {
        authorizedUsers = props.authorizedUsers || [],
        onClickDelete
    } = props;

    const { t } = useTranslation();

    return (
        <ThemedListContainer>
            <List>
                {authorizedUsers.map(authorizedUser => {
                    return (
                        <AuthorizedUserListItem
                            key={authorizedUser.id}
                            authorizedUser={authorizedUser}
                            onClickDelete={onClickDelete}
                            t={t}
                        />
                    );
                })}
            </List>
        </ThemedListContainer>
    );
}

AuthorizedUserList.propTypes = {
    authorizedUsers: PropTypes.array.isRequired,
    onClickDelete: PropTypes.func.isRequired
};

export default AuthorizedUserList;
