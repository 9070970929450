import AppConstants from 'AppConstants.js';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

function GridContainer(props) {
    const { classes, spacing, ...other } = props;
    const allowedSpacings = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    let protectedSpacing = spacing;
    if (!allowedSpacings.find(allowedSpacing => allowedSpacing === spacing)) {
        protectedSpacing = 10;
    }

    return (
        <Grid
            {...other}
            className={classes.root}
            container={true}
            spacing={protectedSpacing}
        >
            {props.children}
        </Grid>
    );
}
GridContainer.propTypes = {
    spacing: PropTypes.number
};

GridContainer.defaultProps = {
    spacing: AppConstants.GRID_CONTAINER_SPACING
};

// Using JSS instead of CSS here because that is what withStyles supports
const styles = () => ({
    root: {
        paddingLeft: AppConstants.GRID_CONTAINER_PADDING,
        paddingRight: AppConstants.GRID_CONTAINER_PADDING,
        marginTop: 0,
        marginBottom: 0
    }
});

export default withStyles(styles)(GridContainer);
