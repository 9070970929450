import { login, logout } from 'actions';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import GetUserTask from 'common/tasks/GetUserTask';
import GridContainer from 'common/components/GridContainer';
import GridItem from 'common/components/GridItem';
import Hidden from '@material-ui/core/Hidden';
import LoadingIndicator from 'common/components/LoadingIndicator';
import { mapStateToProps } from 'reducers';
import Page from 'common/components/Page';
import React from 'react';
import styled from 'theme';
import { ThemedPageHeader } from 'common/styles';
import { withTranslation } from 'react-i18next';

const CenterCol = styled(GridItem)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const CenteredHeader = styled(ThemedPageHeader)`
    margin-top: 100px;
    text-align: center;
`;
const BadRecptionImage = styled.img`
    height: 154px;
    width: 154px;
    margin-top: 20px;
    margin-bottom: 20px;
`;
const Message = styled.p`
    text-align: center;
    font-size: 20px;
`;
const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-top: 60px;
`;

class MemberImpersonationContainer extends ContainerBase {
    constructor(props) {
        const {
            dispatch,
            history,
            location = props.location || {}
        } = props;

        super(props, {
            processing: true
        });

        // Using hash identifier here to prevent access token from
        // being exposed on the network.
        // See https://blog.httpwatch.com/2011/03/01/6-things-you-should-know-about-fragment-urls/
        let impersonationAccessToken = location.hash;
        if (impersonationAccessToken.length < 2) {
            this.setState({ processing: false });
            return;
        }
        impersonationAccessToken = impersonationAccessToken.substring(1);

        dispatch(logout());

        const authenticatedApi = new FireflyAuthenticatedApi({
            accessToken: impersonationAccessToken,
            dispatch
        });

        const getUserTask = new GetUserTask(authenticatedApi);
        getUserTask
            .execute()
            .then(getUserResponse => {
                dispatch(
                    login(
                        impersonationAccessToken,
                        null,
                        getUserResponse.user,
                        getUserResponse.accountDetails,
                        true
                    )
                );
                history.push('/');
            })
            .catch(() => {
                this.setState({ processing: false });
            });
    }
    render() {
        const { t } = this.props;
        const { processing } = this.state;

        return (
            <Page>
                <GridContainer>
                    <Hidden smDown={true}>
                        <GridItem xs={3} />
                    </Hidden>
                    <CenterCol xs={12} md={6}>
                        <CenteredHeader>
                            {t('memberImpersonation.header')}
                        </CenteredHeader>
                        {processing ? (
                            <LoadingContainer>
                                <LoadingIndicator />
                            </LoadingContainer>
                        ) : (
                            <React.Fragment>
                                <BadRecptionImage src="/img/failure.svg" />
                                <Message>
                                    {t('memberImpersonation.message')}
                                </Message>
                            </React.Fragment>
                        )}
                    </CenterCol>
                </GridContainer>
            </Page>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(MemberImpersonationContainer));
