import { ContinueButton, Intro } from 'features/wizard/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { mapStateToProps } from 'reducers';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import { withTranslation } from 'react-i18next';
import WizardStep from 'features/wizard/components/WizardStep';

const NotificationsFormGroup = styled(FormGroup)`
    margin-bottom: 20px;
`;

class WizardNotificationsContainer extends ContainerBase {
    constructor(props) {
        super(props, {
            outages: true,
            billing: true
        });
    }

    render() {
        const { t } = this.props;

        const {
            billing,
            loading,
            outages
        } = this.state;

        return (
            <WizardStep heading="wizard.notifications.header">
                <Intro>{t('wizard.notifications.intro')}</Intro>

                <NotificationsFormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={outages}
                                disabled={loading}
                                onChange={this.handleCheckboxChange('outages')}
                                value="true"
                            />
                        }
                        label={t('wizard.notifications.outages')}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={billing}
                                disabled={loading}
                                onChange={this.handleCheckboxChange('billing')}
                                value="true"
                            />
                        }
                        label={t('wizard.notifications.billing')}
                    />
                </NotificationsFormGroup>

                <ContinueButton
                    key="continue"
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={this.saveAndContinue}
                >
                    {t('common.continue')}
                </ContinueButton>
            </WizardStep>
        );
    }

    handleCheckboxChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.checked });
    };

    saveAndContinue = () => {
        const {
            appState,
            dispatch,
            onContinue,
            wizardContext = this.props.wizardContext || {}
        } = this.props;

        this.setState({ loading: true });

        const api = new FireflyAuthenticatedApi({ appState, dispatch });

        const accountNumbers = wizardContext.accountNumbersAddedByWizard || [];
        const promises = accountNumbers.map(accountNumber =>
            api.saveAccountNotificationPreferences(
                accountNumber,
                this.createPreferences()
            )
        );
        Promise.all(promises)
            .then(() => {
                onContinue();
            })
            .catch(error => {
                this.setState({ loading: false });
                this.showErrorDialog(error);
            });
    };

    createPreferences() {
        const {
            billing,
            outages
        } = this.state;
        const outagesMethods = outages === true ? ['push'] : [];
        const billingMethods = billing === true ? ['push'] : [];

        return [
            this.createPreference('bill_due', billingMethods),
            this.createPreference('bill_generated', billingMethods),
            this.createPreference('bill_past_due', billingMethods),
            this.createPreference('payment_failure', billingMethods),
            this.createPreference('payment_success', billingMethods),
            this.createPreference('outages', outagesMethods),
            this.createPreference('pre_cutoff', outagesMethods),
            this.createPreference('post_cutoff', outagesMethods)
        ];
    };

    createPreference(category, method) {
        return {
            notification_category: category,
            selected_delivery_methods: method
        };
    };
}

WizardNotificationsContainer.propTypes = {
    onContinue: PropTypes.func.isRequired,
    wizardContext: PropTypes.object.isRequired,
    orientation: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(withTranslation()(WizardNotificationsContainer));
