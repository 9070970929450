export default class UsagePieChartAdapter {
    static adapt(temperatureAndUsageResponses) {
        const [
            temperatureResponse,
            ...usageResponses
        ] = temperatureAndUsageResponses;

        const metricTotals = usageResponses.map(() => ({ count: 0, total: 0 }));
        usageResponses.forEach((account, i) => {
            account.forEach(metric => {
                if (metric.value > 0) {
                    metricTotals[i].count += 1;
                    metricTotals[i].total += metric.value;
                }
            });
        });
        const averages = metricTotals.map(it => {
            if (it.count > 0) {
                return Math.round(it.total / it.count);
            }
            return 0;
        });
        const totalAverage = averages.reduce(
            (accumulator, current) => accumulator + current
        );

        let highTempCount = 0;
        let highTempTotal = 0;
        let lowTempCount = 0;
        let lowTempTotal = 0;
        temperatureResponse.forEach(temperature => {
            if (temperature.high !== 0) {
                highTempCount += 1;
                highTempTotal += temperature.high;
            }
            if (temperature.low !== 0) {
                lowTempCount += 1;
                lowTempTotal += temperature.low;
            }
        });
        const highAverage =
            highTempCount > 0 ? Math.round(highTempTotal / highTempCount) : 0;
        const lowAverage =
            lowTempCount > 0 ? Math.round(lowTempTotal / lowTempCount) : 0;

        return {
            averages: averages,
            totalAverage: totalAverage,
            highTemperatureAverage: highAverage,
            lowTemperatureAverage: lowAverage
        };
    }
}
