import dayjs from 'dayjs';
import { timeScale } from 'common/components/charting/ChartSettingsDrawer';

export default class UsageBarChartAdapter {
    static adapt(
        selectedAccounts,
        temperatureAndUsageResponses,
        t,
        startDate,
        endDate,
        selectedTimeScale = timeScale.MONTHLY
    ) {
        const [
            temperatureResponse,
            ...usageResponses
        ] = temperatureAndUsageResponses;
        const xaxis = [];
        const days = [];
        const usageDates = usageResponses
            .filter(dataSet => dataSet.length > 0)
            .map(dataSet => dayjs(dataSet[0].date_time));
        const earliestUsageDate = usageDates.length > 0
            ? usageDates.reduce((acc, cur) => {
                return acc.isAfter(cur) ? cur : acc;
            })
            : dayjs(0);
        let sequenceDate = dayjs(startDate).isAfter(earliestUsageDate)
            ? dayjs(startDate)
            : earliestUsageDate;
        let format;
        let unit;
        let dateCompareEnd;
        switch (selectedTimeScale) {
            case timeScale.DAILY:
                format = 'MMM D';
                unit = 'day';
                dateCompareEnd = 10;
                break;
            case timeScale.MONTHLY:
                format = 'MMM YYYY';
                unit = 'month';
                dateCompareEnd = 10;
                break;
            default:
                format = 'YYYY';
                unit = 'year';
                dateCompareEnd = 5;
                endDate = endDate
                    .add(1, 'month')
                    .set('date', 1)
                    .startOf('day');
        }

        while (!sequenceDate.isAfter(endDate)) {
            xaxis.push(sequenceDate.format(format));
            days.push(sequenceDate.toISOString());
            sequenceDate = sequenceDate.add(1, unit);
        }

        const legend = [];
        const series = selectedAccounts.map((it, i) => {
            const name = it.getDisplayName(t);
            legend.push(name);
            const data = days.map(day => {
                const usageMetric = usageResponses[i].find(
                    searchMetric =>
                        searchMetric.date_time.substring(0, dateCompareEnd) ===
                        day.substring(0, dateCompareEnd)
                );
                if (usageMetric) {
                    return usageMetric.value;
                }
                return 0;
            });
            return {
                name: name,
                data: data
            };
        });

        const temps = days.map(day => {
            const temperatureMetric = temperatureResponse.find(
                searchMetric =>
                    searchMetric.date.substring(0, 10) === day.substring(0, 10)
            );
            if (temperatureMetric) {
                return {
                    high: temperatureMetric.high,
                    low: temperatureMetric.low
                };
            }
            return {
                high: 0,
                low: 0
            };
        });

        return {
            xaxis: xaxis,
            series: series,
            legend: legend,
            temperatureSeries: temps
        };
    }
}
