class RequestType {
    applyHeaders() {
        return {};
    }

    prepareUrl(url, queryString) {
        if (queryString === null) {
            return url;
        }

        return (
            url + '?' +
            Object.keys(queryString)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(queryString[key]))
                .join('&')
        );
    }

    prepareBody(data) {
        return data;
    }
}

class EmptyRequestType extends RequestType {
    applyHeaders() {
        return {
            Accept: 'application/json',
            'X-Firefly-Client': 'Web'
        };
    }

    prepareBody(_) {
        return '';
    }
}

class FormRequestType extends RequestType {
    applyHeaders() {
        return {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Firefly-Client': 'Web'
        };
    }

    prepareBody(data) {
        // sb: originally, we were using URLSearchParams to wrap our data
        // object, but that API is not available in IE11 or most versions of
        // Edge. I tried a few polyfills, but they didn't work. It turns out,
        // the functionality we need is really easy to implement in ES6, so the
        // following solves the problem across browsers.

        return Object.keys(data)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&');
    }
}

class JsonRequestType extends RequestType {
    applyHeaders() {
        return {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Firefly-Client': 'Web'
        };
    }

    prepareBody(data) {
        return JSON.stringify(data);
    }
}

class RequestTypes {
    static EMPTY = new EmptyRequestType();
    static FORM = new FormRequestType();
    static JSON = new JsonRequestType();
}

export default RequestTypes;
