import React, { Fragment } from 'react';
import styled, { materialUiTheme } from 'theme';
import Card from '@material-ui/core/Card';
import GridContainer from 'common/components/GridContainer';
import GridItem from 'common/components/GridItem';
import Hidden from '@material-ui/core/Hidden';
import Page from 'common/components/Page';
import ProgressButton from 'common/components/ProgressButton';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PageWithBackground = styled(Page)`
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    align-items: center;
    ${materialUiTheme.breakpoints.up('sm')} {
        background-image: url('/img/login_bg.jpg');
    }
`;
const CenterCol = styled(GridItem)`
    display: flex;
    flex-direction: column;
`;
const StatusImage = styled.img`
    height: 154px;
    margin-top: 20px;
    margin-bottom: 20px;
`;
const StatusMessage = styled.p`
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 25px;
    text-align: center;
`;
const StatusButtonRow = styled.div`
    display: flex;
    justify-content: center;
`;
const FormCard = styled(Card)`
    padding: 50px;
`;

function ResponsiveFormCard(props) {
    return (
        <Fragment>
            <Hidden xsDown={true}>
                <FormCard>{props.children}</FormCard>
            </Hidden>
            <Hidden smUp={true}>{props.children}</Hidden>
        </Fragment>
    );
}

function MessageCard(props) {
    const {
        message,
        src,
        buttonLabel,
        onClickHandler,
        loading
    } = props;

    const { t } = useTranslation();

    return (
        <PageWithBackground>
            <GridContainer>
                <GridItem xs={false} md={2} lg={3} />
                <GridItem xs={12} md={8} lg={6}>
                    <ResponsiveFormCard>
                        <CenterCol xs={12}>
                            <StatusImage src={src} />
                            <StatusMessage>{message}</StatusMessage>
                            <StatusButtonRow>
                                <ProgressButton
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    loading={loading}
                                    onClick={onClickHandler}
                                >
                                    {t(buttonLabel)}
                                </ProgressButton>
                            </StatusButtonRow>
                        </CenterCol>
                    </ResponsiveFormCard>
                </GridItem>
            </GridContainer>
        </PageWithBackground>
    );
}

MessageCard.propTypes = {
    container: PropTypes.object.isRequired,
    message: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func.isRequired
};

MessageCard.defaultProps = {
    buttonLabel: 'common.ok'
};

export default MessageCard;
