import AccountDetailsContainer from 'features/settings/containers/AccountDetailsContainer';
import AccountsContainer from 'features/settings/containers/AccountsContainer';
import AddAuthorizedUserContainer from 'features/settings/containers/AddAuthorizedUserContainer';
import AddAutomaticPaymentContainer from 'features/billing/containers/AddAutomaticPaymentContainer';
import AddCardContainer from 'features/billing/containers/AddCardContainer';
import AddCheckContainer from 'features/billing/containers/AddCheckContainer';
import AuthorizedUsersContainer from 'features/settings/containers/AuthorizedUsersContainer';
import AutomaticPaymentsContainer from 'features/billing/containers/AutomaticPaymentsContainer';
import BillingContainer from 'features/billing/containers/BillingContainer';
import BillingHistoryContainer from 'features/billing/containers/BillingHistoryContainer';
import BillingPaymentHistoryContainer from 'features/billing/payment-history/pages/PaymentHistoryContainer';
import BillingPaymentMethodsContainer from 'features/billing/containers/PaymentMethodsContainer';
import ChangeEmailContainer from 'features/settings/containers/ChangeEmailContainer';
import ChangePasswordContainer from 'features/settings/containers/ChangePasswordContainer';
import ElectricUsageContainer from 'features/usage/containers/ElectricUsageContainer';
import ExpiredSessionContainer from 'features/login/containers/ExpiredSessionContainer';
import ForgotPasswordContainer from 'features/login/containers/ForgotPasswordContainer';
import HomeContainer from 'features/home/containers/HomeContainer';
import LinkAccountContainer from 'features/wizard/containers/LinkAccountContainer';
import LinkMembershipContainer from 'features/wizard/containers/LinkMembershipContainer';
import LoginContainer from 'features/login/containers/LoginContainer';
import MakePaymentContainer from 'features/billing/containers/MakePaymentContainer';
import MemberImpersonationContainer from 'features/login/containers/MemberImpersonationContainer';
import MembershipDetailsContainer from 'features/settings/containers/MembershipDetailsContainer';
import MembershipsContainer from 'features/settings/containers/MembershipsContainer';
import NotFoundContainer from 'common/containers/NotFoundContainer';
import NotificationsContainer from 'features/notifications/containers/NotificationsContainer';
import PaymentSuccessfulContainer from 'features/billing/containers/PaymentSuccessfulContainer';
import PrivateRoute from 'common/components/PrivateRoute';
import PropTypes from 'prop-types';
import React from 'react';
import ResetPasswordContainer from 'features/login/containers/ResetPasswordContainer';
import { Route } from 'react-router-dom';
import SettingsContainer from 'features/settings/containers/SettingsContainer';
import { Switch } from 'react-router-dom';
import TapEnrollmentContainer from 'features/settings/containers/TapEnrollmentContainer';
import UsageContainer from 'features/usage/containers/UsageContainer';
import UserProfileContainer from 'features/settings/containers/UserProfileContainer';
import VerifyEmailContainer from 'features/login/containers/VerifyEmailContainer';
import VerifySmsContainer from 'features/settings/containers/VerifySmsContainer';
import WaterUsageContainer from 'features/usage/containers/WaterUsageContainer';
import WizardAliasesContainer from 'features/wizard/containers/WizardAliasesContainer';
import WizardAutomaticPaymentsContainer from 'features/wizard/containers/WizardAutomaticPaymentsContainer';
import WizardCompleteContainer from 'features/wizard/containers/WizardCompleteContainer';
import WizardGoPaperlessContainer from 'features/wizard/containers/WizardGoPaperlessContainer';
import WizardIntroContainer from 'features/wizard/containers/WizardIntroContainer';
import WizardNewsletterContainer from 'features/wizard/containers/WizardNewsletterContainer';
import WizardNotificationsContainer from 'features/wizard/containers/WizardNotificationsContainer';
import WizardStepperContainer from 'features/wizard/containers/WizardStepperContainer';
import WizardTapEnrollmentContainer from 'features/wizard/containers/WizardTapEnrollmentContainer';

export default function Routes(props) {
    return (
        <div {...props}>
            <Switch>
                <PrivateRoute
                    exact={true}
                    path="/"
                    component={HomeContainer}
                />

                {/*BILLING*/}
                <PrivateRoute
                    exact={true}
                    path="/billing"
                    component={BillingContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/billing/:account_number/payment-history"
                    component={BillingPaymentHistoryContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/billing/history"
                    component={BillingHistoryContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/billing/payment-methods"
                    component={BillingPaymentMethodsContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/billing/payment-methods/new-card"
                    component={AddCardContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/billing/payment-methods/new-check"
                    component={AddCheckContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/billing/make-payment"
                    component={MakePaymentContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/billing/payment-successful"
                    component={PaymentSuccessfulContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/billing/automatic-payments"
                    component={AutomaticPaymentsContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/billing/automatic-payments/add"
                    component={AddAutomaticPaymentContainer}
                />
                <Route
                    exact={true}
                    path="/forgot-password"
                    component={ForgotPasswordContainer}
                />
                <Route
                    exact={true}
                    path="/login"
                    component={LoginContainer}
                />
                <Route
                    exact={true}
                    path="/expired-session"
                    component={ExpiredSessionContainer}
                />
                <Route
                    exact={true}
                    path="/impersonate"
                    component={MemberImpersonationContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/notifications"
                    component={NotificationsContainer}
                />

                {/*SETTINGS*/}
                <PrivateRoute
                    exact={true}
                    path="/settings"
                    component={SettingsContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/settings/memberships"
                    component={MembershipsContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/settings/memberships/:member_number"
                    component={MembershipDetailsContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/settings/memberships/:member_number/tap-enrollment"
                    component={TapEnrollmentContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/settings/accounts"
                    component={AccountsContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/settings/accounts/:account_number"
                    component={AccountDetailsContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/settings/authorized-users"
                    component={AuthorizedUsersContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/settings/authorized-users/new"
                    component={AddAuthorizedUserContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/settings/user-profile"
                    component={UserProfileContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/settings/user-profile/change-email"
                    component={ChangeEmailContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/settings/user-profile/change-password"
                    component={ChangePasswordContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/settings/user-profile/update-sms"
                    component={VerifySmsContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/usage"
                    component={UsageContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/usage/electric"
                    component={ElectricUsageContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/usage/water"
                    component={WaterUsageContainer}
                />
                <Route
                    exact={true}
                    path="/users/reset-password/:token"
                    component={ResetPasswordContainer}
                />
                <Route
                    exact={true}
                    path="/users/verify-email/:token"
                    component={VerifyEmailContainer}
                />

                {/*WIZARD*/}
                <PrivateRoute
                    exact={true}
                    path="/wizard"
                    component={WizardIntroContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/wizard/link-account"
                    component={LinkAccountContainer}
                />
                <PrivateRoute
                    exact={true}
                    path="/wizard/link-membership"
                    component={LinkMembershipContainer}
                />
                <PrivateRoute
                    path="/wizard/step/:step"
                    component={WizardStepperContainer}
                />

                <Route component={NotFoundContainer} />
            </Switch>
        </div>
    );
}

export function WizardRoutes(props) {
    return (
        <Switch>
            <Route
                exact={true}
                path="/wizard/step/aliases"
                component={() => <WizardAliasesContainer {...props} />}
            />
            <Route
                exact={true}
                path="/wizard/step/automatic-payments"
                component={() => (
                    <WizardAutomaticPaymentsContainer {...props} />
                )}
            />
            <Route
                exact={true}
                path="/wizard/step/complete"
                component={() => <WizardCompleteContainer {...props} />}
            />
            <Route
                exact={true}
                path="/wizard/step/paperless"
                component={() => <WizardGoPaperlessContainer {...props} />}
            />
            <Route
                exact={true}
                path="/wizard/step/newsletter"
                component={() => <WizardNewsletterContainer {...props} />}
            />
            <Route
                exact={true}
                path="/wizard/step/notifications"
                component={() => <WizardNotificationsContainer {...props} />}
            />
            <Route
                exact={true}
                path="/wizard/step/tap"
                component={() => <WizardTapEnrollmentContainer {...props} />}
            />
        </Switch>
    );
}

WizardRoutes.propTypes = {
    onContinue: PropTypes.func.isRequired,
    wizardContext: PropTypes.object.isRequired,
    orientation: PropTypes.string
};

WizardRoutes.defaultProps = {
    orientation: 'horizontal'
};
