export const changeEmail = newEmail => ({
    type: 'CHANGE_EMAIL',
    newEmail: newEmail
});

export const linkAccounts = (user, accountDetails, wizardContext) => ({
    type: 'LINK_ACCOUNTS',
    user: user,
    accountDetails: accountDetails,
    wizardContext: wizardContext
});

export const login = (
    accessToken,
    refreshToken,
    user,
    accountDetails,
    isImpersonated = false
) => ({
    type: 'LOGIN',
    accessToken: accessToken,
    refreshToken: refreshToken,
    user: user,
    accountDetails: accountDetails,
    isImpersonated: isImpersonated
});

export const logout = () => ({
    type: 'LOGOUT'
});

export const expireToken = () => ({
    type: 'EXPIRE_TOKEN'
});

export const refreshData = (user, accountDetails) => ({
    type: 'REFRESH_DATA',
    user: user,
    accountDetails: accountDetails
});

export const unlinkAccount = accountNumber => ({
    type: 'UNLINK_ACCOUNT',
    accountNumber: accountNumber
});

export const unlinkMembership = memberNumber => ({
    type: 'UNLINK_MEMBERSHIP',
    memberNumber: memberNumber
});

export const updateAccessToken = (accessToken, refreshToken) => ({
    type: 'UPDATE_ACCESS_TOKEN',
    accessToken: accessToken,
    refreshToken: refreshToken
});

export const updateAccountAliases = accountAliasUpdates => ({
    type: 'UPDATE_ACCOUNT_ALIASES',
    accountAliasUpdates: accountAliasUpdates
});

export const updateAutomaticPayments = automaticPaymentUpdates => ({
    type: 'UPDATE_ACCOUNT_AUTOMATIC_PAYMENT',
    automaticPaymentUpdates: automaticPaymentUpdates
});

export const updateMembershipAlias = aliasUpdate => ({
    type: 'UPDATE_MEMBERSHIP_ALIAS',
    aliasUpdate: aliasUpdate
});

export const updateOutageStatus = (accountNumber, outageStatus) => ({
    type: 'UPDATE_OUTAGE_STATUS',
    accountNumber: accountNumber,
    outageStatus: outageStatus
});

export const updateProfile = (firstName, lastName, newsletter) => ({
    type: 'UPDATE_PROFILE',
    firstName: firstName,
    lastName: lastName,
    newsletter: newsletter
});

export const updateSmsNumber = smsNumberFormatted => ({
    type: 'UPDATE_SMS_NUMBER',
    smsNumberFormatted: smsNumberFormatted
});

export const updateWizardContext = wizardContext => ({
    type: 'UPDATE_WIZARD_CONTEXT',
    wizardContext: wizardContext
});

export const updateErrorDialogState = errorDialogIsOpen => ({
    type: 'UPDATE_ERROR_DIALOG_STATE',
    errorDialogIsOpen: errorDialogIsOpen
});

export const updateErrorDialogError = error => ({
    type: 'UPDATE_ERROR_DIALOG_ERROR',
    error: error
});
