import { addPaymentMethodFromCard, addPaymentMethodFromCheck } from '../addPaymentMethod';
import CardTextFields, { cardInputFieldNames, createCardPaymentMethod } from '../components/CardTextFields';
import CheckTextFields, { checkingInputFieldNames, createCheckPaymentMethod } from '../components/CheckTextFields';
import LoadingIndicator, { LoadingContainer } from '../../../common/components/LoadingIndicator';
import { ThemedActionUpper, ThemedListContainer } from '../../../common/styles';
import AppConstants from '../../../AppConstants';
import BillingPage from '../components/BillingPage';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { comparePaymentMethods } from '../comparators';
import { connect } from 'react-redux';
import ContainerBase from '../../../common/containers/ContainerBase';
import { DialogBasic } from '../../../common/components/dialogs';
import FireflyAuthenticatedApi from '../../../api/FireflyAuthenticatedApi';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { mapStateToProps } from '../../../reducers';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { NavButton } from '../../../common/components/nav-button';
import PaymentMethod from '../../../model/PaymentMethod';
import ProgressButton from '../../../common/components/ProgressButton';
import React from 'react';
import styled from '../../../theme';
import { updateAutomaticPayments } from '../../../actions';
import Validator from '../../../validation/Validator';
import { withTranslation } from 'react-i18next';

const SelectAccountFormGroup = styled(FormGroup)`
    margin-bottom: 20px;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
`;

const CancelButton = styled(NavButton)`
    && {
        margin-left: 20px;
    }
`;

const SaveButton = styled(ProgressButton)`
    && {
        width: 100px;
    }
`;

const SelectMethodLink = styled(ThemedActionUpper)`
    display: flex;
    margin-bottom: 20px;
`;

const LastMenuItem = styled(MenuItem)`
&& {
    border-bottom: solid 1px ${AppConstants.COLOR_DIVIDER};
}
`;

const FORM_CARD = 'formCard';
const FORM_CHECK = 'formCheck';

class AddAutomaticPaymentContainer extends ContainerBase {
    constructor(props) {
        const {
            appState = props.appState || {},
            dispatch,
            location,
            t
        } = props;

        const accountsSorted = (appState.accountsSorted && appState.accountsSorted(t)) || [];
        const availableAccounts = accountsSorted.filter(it => !it.automaticPayment);

        let selectedAccounts;
        if ((location && location.state) && location.state.selectedAccounts) {
            selectedAccounts = location.state.selectedAccounts;
        } else {
            selectedAccounts = availableAccounts.map(it => {
                return { account: it, selected: false };
            });
        }

        super(props, {
            processing: false,
            loadingPaymentMethods: true,
            paymentMethods: [],
            selectedPaymentMethod: null,
            selectedPaymentMethodType: null,
            selectMenuAnchorEl: null,
            basicDialogIsOpen: false,
            errorMessageTitle: '',
            errorMessageBody: '',
            availableAccounts: availableAccounts,
            selectedAccounts: selectedAccounts,
            linkText: 'wizard.automaticPayments.selectPaymentMethod',
            ...cardInputFieldNames,
            ...checkingInputFieldNames
        });

        this.state.noMoreCharge = appState.noMoreCharge;
        this.state.noMoreChecks = appState.noMoreChecks;

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });

        this.validator = new Validator(this);
    }

    configureValidations(config) {
        // Validations for the credit card form.
        config.requireNotEmpty('nameOnCard', FORM_CARD);
        config.requireCreditCard('creditCard', FORM_CARD);
        config.requireExpirationDate('expirationDate', FORM_CARD);
        config.requireCvv('cvv', 'creditCard', FORM_CARD);
        config.requireNotEmpty('addressLine1', FORM_CARD);
        config.requireNotEmpty('city', FORM_CARD);
        config.requireNotEmpty('state', FORM_CARD);
        config.requireNotEmpty('zip', FORM_CARD);

        // Validations for the bank account form.
        config.requireNotEmpty('nameOnAccount', FORM_CHECK);
        config.requireRoutingNumber('routingNumber', FORM_CHECK);
        config.requireConfirmRoutingNumber(
            'routingNumberConfirm',
            'routingNumber',
            FORM_CHECK
        );
        config.requireNotEmpty('accountNumber', FORM_CHECK);
        config.requireConfirmAccountNumber(
            'accountNumberConfirm',
            'accountNumber',
            FORM_CHECK
        );
        config.requireNotEmpty('accountType', FORM_CHECK);
    }

    componentDidMount() {
        this.loadPaymentMethods();
    }

    componentDidUpdate() {
        this.validator.maybeFocus();
    }

    onSelectMethod = e => {
        e.preventDefault();
        this.setState({ selectMenuAnchorEl: e.currentTarget });
    };

    checkboxChange = accountNumber => ({ target } = {}) => {
        const { selectedAccounts } = this.state;
        const updated = selectedAccounts;

        updated.forEach(it => {
            if (it.account.accountNumber === accountNumber) {
                it.selected = target && target.checked;
            }
        });

        this.setState({ selectedAccounts: updated });
    };

    handleSelectMenuItemClick = value => {
        const { processing } = this.state;
        const { t } = this.props;

        if (processing === true) {
            return;
        }

        switch (value) {
            case 'enter-check':
                this.setState({
                    selectMenuAnchorEl: null,
                    selectedPaymentMethod: null,
                    selectedPaymentMethodType: 'checking',
                    linkText: t('billing.enterCheck.header')
                });
                break;

            case 'enter-card':
                this.setState({
                    selectMenuAnchorEl: null,
                    selectedPaymentMethod: null,
                    selectedPaymentMethodType: 'card',
                    linkText: t('billing.enterCard.header')
                });
                break;

            default:
                if (!value.id) {
                    this.showErrorDialog(Error('Unsupported menu option.'));
                }

                this.setState({
                    selectMenuAnchorEl: null,
                    selectedPaymentMethod: value,
                    selectedPaymentMethodType: 'saved',
                    linkText: value.getDisplayName(t)
                });
        }
    };

    onNewCardSave = cardDetails => {
        const { t } = this.props;

        this.setState({
            selectMenuAnchorEl: null,
            selectedPaymentMethod: cardDetails,
            selectedPaymentMethodType: 'card',
            linkText: cardDetails.getDisplayName(t)
        });
    };

    onNewCheckSave = checkDetails => {
        this.setState({
            selectMenuAnchorEl: null,
            selectedPaymentMethod: checkDetails,
            selectedPaymentMethodType: 'checking',
            linkText: checkDetails.getDisplayName()
        });
    };

    loadPaymentMethods = () => {
        this.api
            .getPaymentMethods()
            .then(responses => {
                const paymentMethods = responses.map(it => new PaymentMethod(it));
                this.setState({
                    loadingPaymentMethods: false,
                    paymentMethods: paymentMethods
                });
            })
            .catch(error => {
                this.setState({ loadingPaymentMethods: false });
                this.showErrorDialog(error);
            });
    };

    handleOnSubmit = () => {
        const {
            saveAccount,
            saveCard,
            selectedAccounts,
            selectedPaymentMethod,
            selectedPaymentMethodType
        } = this.state;

        const {
            dispatch,
            history,
        } = this.props;

        if (!this.isFormValid()) {
            return;
        }

        this.setState({ processing: true });

        let paymentMethod;
        switch (selectedPaymentMethodType) {
            case 'card':
                paymentMethod = createCardPaymentMethod(this);
                break;

            case 'checking':
                paymentMethod = createCheckPaymentMethod(this);
                break;

            default:
                paymentMethod = selectedPaymentMethod;
        }

        const promises = selectedAccounts
            .filter(it => it.selected)
            .map(it =>
                this.api.setupAutomaticPayment(
                    it.account.accountNumber,
                    paymentMethod,
                    selectedPaymentMethodType
                )
            );

        Promise.all(promises)
            .then(() => {
                let promise = Promise.resolve();
                if (selectedPaymentMethodType === 'card' && saveCard) {
                    promise = addPaymentMethodFromCard(this);
                } else if (selectedPaymentMethodType === 'checking' && saveAccount) {
                    promise = addPaymentMethodFromCheck(this);
                }
                promise
                    .then(() => {
                        this.setState({ processing: false });
                        const automaticPaymentUpdates = selectedAccounts
                            .filter(it => it.selected)
                            .map(it => {
                                return {
                                    accountNumber: it.account.accountNumber,
                                    newStatus: true
                                };
                            });
                        dispatch(updateAutomaticPayments(automaticPaymentUpdates));
                        history.push('/billing/automatic-payments/', { isEnrolledSnackbarOpen: true });
                    })
                    .catch(error => {
                        this.showErrorDialog(error);
                    });
            })
            .catch(error => {
                this.setState({ processing: false });

                if (error.status !== 422 || !error.response.errors) {
                    this.showErrorDialog(error);
                    return;
                }

                if (
                    error.response.errors.some(
                        it =>
                            it.field === 'routing_number' &&
                            it.error_code ===
                            'unsupported_financial_institution'
                    )
                ) {
                    this.setState({
                        errorMessageTitle: 'billing.validation.checking.unsupportedFinancialInstitution.title',
                        errorMessageBody: 'billing.validation.checking.unsupportedFinancialInstitution.message'
                    });
                    this.showBasicDialog();
                } else if (
                    error.response.errors.some(
                        it =>
                            it.field === 'card_number' &&
                            it.error_code === 'unsupported_card_brand'
                    )
                ) {
                    this.setState({
                        errorMessageTitle: 'billing.validation.creditCard.unsupportedCardBrand.title',
                        errorMessageBody: 'billing.validation.creditCard.unsupportedCardBrand.message'
                    });
                    this.showBasicDialog();
                } else {
                    this.showErrorDialog(error);
                }
            });
    };

    isFormValid() {
        const {
            selectedAccounts,
            selectedPaymentMethodType
        } = this.state;

        if (selectedAccounts.filter(it => it.selected).length === 0) {
            this.setState({
                errorMessageTitle: 'billing.automaticPayments.validation.noAccountSelected.title',
                errorMessageBody: 'billing.automaticPayments.validation.noAccountSelected.body'
            });
            this.showBasicDialog();
            return false;
        }

        if (selectedPaymentMethodType === null) {
            this.setState({
                errorMessageTitle: 'billing.automaticPayments.validation.noPaymentMethodSelected.title',
                errorMessageBody: 'billing.automaticPayments.validation.noPaymentMethodSelected.body'
            });
            this.showBasicDialog();
            return false;
        }

        if (selectedPaymentMethodType === 'card') {
            return this.validator.validate(FORM_CARD);
        }

        if (selectedPaymentMethodType === 'checking') {
            return this.validator.validate(FORM_CHECK);
        }

        return true;
    }

    isChecked = accountNumber => {
        const { selectedAccounts } = this.state;

        return selectedAccounts.some(
            it => it.account.accountNumber === accountNumber && it.selected
        );
    };

    filterPaymentMethods = (paymentMethods) => {
        const { t } = this.props;
        const { noMoreCharge, noMoreChecks } = this.state;

        if (noMoreCharge) {
            paymentMethods = paymentMethods.filter(it => it.type === 'check');
        }

        if (noMoreChecks) {
            paymentMethods = paymentMethods.filter(it => it.type === 'card');
        }

        return paymentMethods
            .sort((a, b) => comparePaymentMethods(a, b, t));
    }

    render() {
        const { t } = this.props;

        const {
            availableAccounts,
            basicDialogIsOpen,
            errorMessageBody,
            errorMessageTitle,
            linkText,
            loadingPaymentMethods,
            noMoreCharge,
            noMoreChecks,
            paymentMethods,
            processing,
            selectMenuAnchorEl,
            selectedPaymentMethodType
        } = this.state;

        return (
            <BillingPage
                title={'billing.automaticPayments.addHeader'}
                select="/billing/automatic-payments"
                indentedHeader={false}
            >
                {loadingPaymentMethods ? (
                    <LoadingContainer>
                        <LoadingIndicator />
                    </LoadingContainer>
                ) : (
                    <div>
                        <p>{t('wizard.automaticPayments.selectAccount')}</p>
                        <SelectAccountFormGroup>
                            {availableAccounts.map(account => (
                                <FormControlLabel
                                    key={account.accountNumber}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            onChange={this.checkboxChange(account.accountNumber)}
                                            value="true"
                                            disabled={this.state.processing}
                                            checked={this.isChecked(account.accountNumber)}
                                        />
                                    }
                                    label={account.getDisplayName(t)}
                                />
                            ))}
                        </SelectAccountFormGroup>
                        <SelectMethodLink onClick={e => this.onSelectMethod(e)}>
                            {t(linkText)}
                        </SelectMethodLink>
                        <Menu
                            id="select-menu"
                            anchorEl={selectMenuAnchorEl}
                            open={Boolean(selectMenuAnchorEl)}
                            onClose={() => this.setState({ selectMenuAnchorEl: null })}
                        >
                            {this.filterPaymentMethods(paymentMethods)
                                .map((paymentMethod, index) => {
                                    if (index === paymentMethods.length - 1) {
                                        return (
                                            <LastMenuItem
                                                key={paymentMethod.id}
                                                value={paymentMethod.id}
                                                onClick={e => this.handleSelectMenuItemClick(paymentMethod, e)}
                                            >
                                                {t(paymentMethod.getDisplayName(t))}
                                            </LastMenuItem>
                                        );
                                    }

                                    return (
                                        <MenuItem
                                            key={paymentMethod.id}
                                            value={paymentMethod.id}
                                            onClick={e => this.handleSelectMenuItemClick(paymentMethod, e)}
                                        >
                                            {t(paymentMethod.getDisplayName(t))}
                                        </MenuItem>
                                    );
                                })}
                            {!noMoreCharge && (
                                <MenuItem
                                    value="enter-card"
                                    onClick={e => this.handleSelectMenuItemClick('enter-card', e)}
                                >
                                    {t('billing.enterCard.header')}
                                </MenuItem>
                            )}
                            {!noMoreChecks && (
                                <MenuItem
                                    value="enter-check"
                                    onClick={e => this.handleSelectMenuItemClick('enter-check', e)}
                                >
                                    {t('billing.enterCheck.header')}
                                </MenuItem>
                            )}
                        </Menu>

                        <ThemedListContainer>
                            {selectedPaymentMethodType === 'card' && (
                                <CardTextFields
                                    parent={this}
                                    t={t}
                                    alwaysShowAlias={false}
                                    showSaveCheckbox={true}
                                />
                            )}

                            {selectedPaymentMethodType === 'checking' && (
                                <CheckTextFields
                                    parent={this}
                                    t={t}
                                    alwaysShowAlias={false}
                                    showSaveCheckbox={true}
                                />
                            )}
                        </ThemedListContainer>

                        <ButtonRow>
                            <SaveButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                loading={processing}
                                onClick={this.handleOnSubmit}
                            >
                                {t('common.saveButton')}
                            </SaveButton>

                            <CancelButton color="primary" to="/billing/automatic-payments" disabled={processing}>
                                {t('common.cancel')}
                            </CancelButton>
                        </ButtonRow>
                    </div>
                )}
                <DialogBasic
                    message={t(errorMessageBody)}
                    onCloseDialog={this.closeBasicDialog}
                    open={basicDialogIsOpen}
                    title={t(errorMessageTitle)}
                />
            </BillingPage>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(AddAutomaticPaymentContainer));
