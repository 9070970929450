export default class BillingAddress {
    constructor(addressLine1, addressLine2, city, state, zip) {
        this.line1 = addressLine1;
        this.line2 = addressLine2;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.country = 'US';
    }
}
