export default class Membership {
    constructor(rawData) {
        Object.assign(this, rawData);
    }

    getDisplayName(t) {
        const useDefault = this.alias === null || this.alias === '';

        if (useDefault) {
            return t('common.memberships.titleNoAlias', { memberNumber: this.memberNumber });
        }

        return this.alias;
    }

    getSubTitle(t) {
        return t('common.memberships.subtitle', { memberNumber: this.memberNumber });
    }
}
