import { EmptyContainer, EmptyImage, EmptyMessage, ThemedFabButton, ThemedListItem } from 'common/styles';
import LoadingIndicator, { LoadingContainer } from 'common/components/LoadingIndicator';
import { useTranslation, withTranslation } from 'react-i18next';
import AccountTypeIcon from 'common/components/AccountTypeIcon';
import AddIcon from '@material-ui/icons/Add';
import BillingPage from 'features/billing/components/BillingPage';
import Button from '@material-ui/core/Button/Button';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { DialogBasic } from '../../../common/components/dialogs';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import IconButton from '@material-ui/core/IconButton/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { mapStateToProps } from 'reducers';
import React from 'react';
import styled from 'theme';
import SuccessSnackbar from 'common/components/SuccessSnackbar';
import { ThemedListContainer } from 'common/styles';
import ThreeLineListItemText from 'common/components/ThreeLineListItemText';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { updateAutomaticPayments } from 'actions';

const FormattedListItemText = styled(ThreeLineListItemText)`
    word-break: break-word;
`;

const AccountListItem = props => {
    const {
        account = props.account || {},
        onClickAccount
    } = props;

    const { t } = useTranslation();

    return (
        <ThemedListItem key={account.accountNumber}>
            <AccountTypeIcon key={`typeIcon_${account.accountNumber}`} accountType={account.type} alt={account.type} />
            <FormattedListItemText
                primary={account.getDisplayName(t)}
                secondary={account.getSubTitle(t)}
                tertiary={t(account.automaticPayment
                    ? 'billing.automaticPayments.enrolled'
                    : 'billing.automaticPayments.notEnrolled'
                )}
            />
            {account.automaticPayment && (
                <Tooltip title={t('billing.automaticPayments.unenroll')} placement="bottom">
                    <ListItemSecondaryAction>
                        <IconButton
                            aria-label={t('common.aria.deleteButton')}
                            aria-haspopup="true"
                            onClick={onClickAccount(account.accountNumber)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </Tooltip>
            )}
        </ThemedListItem>
    );
};

class AutomaticPaymentsContainer extends ContainerBase {
    constructor(props) {
        const {
            appState,
            dispatch,
            location = props.location || {}
        } = props;

        let isEnrolledSnackbarOpen = false;
        if (location.state && location.state.isEnrolledSnackbarOpen) {
            isEnrolledSnackbarOpen = location.state && location.state.isEnrolledSnackbarOpen;
        }
        super(props, {
            basicDialogIsOpen: false,
            confirmDeleteDialogIsOpen: false,
            errorMessageTitle: '',
            errorMessageBody: '',
            isEnrolledSnackbarOpen: isEnrolledSnackbarOpen,
            isUnenrolledSnackbarOpen: false,
            selectedAccount: null,
            processing: false,
            deleteSuccess: false
        });

        this.state.noMoreCharge = appState.noMoreCharge;
        this.state.noMoreChecks = appState.noMoreChecks;

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });
    }

    handleAdd = () => {
        const { noMoreCharge, noMoreChecks } = this.state;
        const { history } = this.props;

        if (noMoreCharge && noMoreChecks) {
            this.setState({
                errorMessageTitle: 'billing.common.unableToPay.title',
                errorMessageBody: 'billing.common.unableToPay.message'
            });
            this.showBasicDialog();
        } else {
            history.push('/billing/automatic-payments/add');
        }
    }

    handleEnrolledSnackbarClose = () => {
        this.setState({ isEnrolledSnackbarOpen: false });
    }

    handleUnenrolledSnackbarClose = () => {
        this.setState({ isUnenrolledSnackbarOpen: false });
    }

    handleUnenrollAccountClick = accountNumber => () => {
        this.setState({
            confirmDeleteDialogIsOpen: true,
            selectedAccount: accountNumber
        });
    }

    handleUnenrollAccount = () => {
        const { selectedAccount } = this.state;

        const { dispatch } = this.props;

        this.setState({ processing: true });
        const accountNumber = selectedAccount;
        this.api
            .deleteAutomaticPayment(accountNumber)
            .then(() => {
                this.setState({ processing: false, deleteSuccess: true });
                dispatch(updateAutomaticPayments([
                    { accountNumber: accountNumber, newStatus: false }
                    ])
                );
            })
            .catch(err => {
                this.setState({ processing: false });
                this.showErrorDialog(err);
            });
        this.setState({
            confirmDeleteDialogIsOpen: false,
            selectedAccount: null
        });
    }

    handleDeleteSuccessSnackbarClose = () => {
        this.setState({ deleteSuccess: false });
    }

    render() {
        const {
            appState = this.props.appState || {},
            t
        } = this.props;

        const {
            basicDialogIsOpen,
            confirmDeleteDialogIsOpen,
            errorMessageBody,
            errorMessageTitle,
            deleteSuccess,
            isEnrolledSnackbarOpen,
            isUnenrolledSnackbarOpen,
            processing
        } = this.state;

        const accounts = (appState.accountsSorted && appState.accountsSorted(t)) || [];
        const hasAccounts = accounts.length !== 0;

        return (
            <BillingPage title={'billing.automaticPayments.header'} indentedHeader={true}>
                {processing ? (
                    <LoadingContainer>
                        <LoadingIndicator />
                    </LoadingContainer>
                ) : (
                    <div>
                        <ThemedFabButton
                            size="small"
                            color="primary"
                            aria-label={t('billing.automaticPayments.aria.addPaymentMethodMenu')}
                            onClick={this.handleAdd}
                        >
                            <AddIcon />
                        </ThemedFabButton>

                        {!hasAccounts ? (
                            <EmptyContainer>
                                <EmptyImage src="/img/empty_state_default.svg" />
                                <EmptyMessage>
                                    {t('common.noAccountsLinkedMessage')}
                                </EmptyMessage>
                            </EmptyContainer>
                        ) : (
                            <ThemedListContainer>
                                <List>
                                    {accounts.map(it => (
                                        <AccountListItem
                                            key={it.accountNumber}
                                            account={it}
                                            t={t}
                                            onClickAccount={e => this.handleUnenrollAccountClick(e)}
                                        />
                                    ))}
                                </List>
                            </ThemedListContainer>
                        )}
                    </div>
                )}
                <SuccessSnackbar
                    id="unenrolled"
                    open={isUnenrolledSnackbarOpen}
                    onClose={this.handleUnenrolledSnackbarClose}
                    message={t('settings.accounts.accountDetails.successMessage')}
                />
                <SuccessSnackbar
                    id="enrolled"
                    open={isEnrolledSnackbarOpen}
                    onClose={this.handleEnrolledSnackbarClose}
                    message={t('billing.automaticPayments.notifications.enrolled')}
                />

                <DialogBasic
                    message={t(errorMessageBody)}
                    onCloseDialog={this.closeBasicDialog}
                    open={basicDialogIsOpen}
                    title={t(errorMessageTitle)}
                />
                <Dialog
                    open={confirmDeleteDialogIsOpen}
                    onClose={this.closeConfirmDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {t('billing.automaticPayments.confirmDelete.title')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t('billing.automaticPayments.confirmDelete.body')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={_ => this.setState({ confirmDeleteDialogIsOpen: false })}
                            color="primary"
                        >
                            {t('common.cancel')}
                        </Button>

                        <Button
                            onClick={this.handleUnenrollAccount}
                            color="primary"
                        >
                            {t('common.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <SuccessSnackbar
                    id="deleteSuccess"
                    open={deleteSuccess}
                    onClose={this.handleDeleteSuccessSnackbarClose}
                    message={t('billing.automaticPayments.deleteSuccess')}
                />
            </BillingPage>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(AutomaticPaymentsContainer));
