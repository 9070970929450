import { EmptyImage, EmptyMessage } from 'common/styles';
import NotificationsTable, { MobileNotificationsTable } from 'common/components/NotificationsTable';
import styled, { materialUiTheme } from 'theme';
import { useTranslation, withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import connect from 'react-redux/es/connect/connect';
import ContainerBase from 'common/containers/ContainerBase';
import Divider from '@material-ui/core/Divider';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import Hidden from '@material-ui/core/Hidden';
import { mapStateToProps } from 'reducers';
import Page from 'common/components/Page';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { ThemedPageHeader } from 'common/styles';

const StyledPage = styled(Page)`
    && {
        display: flex;
        flex-direction: column;
        ${materialUiTheme.breakpoints.up('md')} {
            padding: 24px;
            background-color: #eee;
        }
    }
`;
const PageHeader = styled(ThemedPageHeader)`
    && {
        ${materialUiTheme.breakpoints.down('sm')} {
            margin: 24px 0 24px 24px;
        }
        ${materialUiTheme.breakpoints.down('xs')} {
            margin: 24px 0 24px 16px;
        }
    }
`;
const Grow = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
const StyledPaper = styled(Paper)`
    && {
        flex-grow: 1;
    }
`;
const StyledPaperCentered = styled(StyledPaper)`
    && {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;
const MobileCentered = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 100px;
`;
function ResponsivePaper(props) {
    const { t } = useTranslation();
    const { children } = props;

    return (
        <Grow>
            <PageHeader>{t('notifications.header')}</PageHeader>
            <Hidden smDown={true}>
                <StyledPaperCentered>{children}</StyledPaperCentered>
            </Hidden>
            <Hidden mdUp={true}>
                <Divider />
                <MobileCentered>{children}</MobileCentered>
            </Hidden>
        </Grow>
    );
}

class NotificationsContainer extends ContainerBase {
    constructor(props) {
        const {
            appState,
            dispatch,
        } = props;

        super(props, {
            loadingNotifications: true,
            notifications: []
        });

        this.api = new FireflyAuthenticatedApi({
            appState: appState,
            dispatch: dispatch
        });
    }

    componentDidMount() {
        this.loadNotifications();
    }

    render() {
        const {
            notifications = this.state.notifications || [],
            loadingNotifications
        } = this.state;

        const { t } = this.props;

        const hasNotificationsToDisplay = notifications.length > 0;

        return (
            <StyledPage>
                {loadingNotifications ? (
                    <ResponsivePaper t={t} container={this}>
                        <CircularProgress />
                    </ResponsivePaper>
                ) : !hasNotificationsToDisplay ? (
                    <ResponsivePaper t={t} container={this}>
                        <EmptyImage narrow src="/img/empty_state_notifications.svg" />
                        <EmptyMessage>
                            {t('notifications.emptyStateMessage')}
                        </EmptyMessage>
                    </ResponsivePaper>
                ) : (
                    <Grow>
                        <PageHeader>{t('notifications.header')}</PageHeader>
                        <Hidden smDown={true}>
                            <StyledPaper>
                                <NotificationsTable notifications={notifications} t={t} />
                            </StyledPaper>
                        </Hidden>
                        <Hidden mdUp={true}>
                            <Divider />
                            <MobileNotificationsTable notifications={notifications} t={t} />
                        </Hidden>
                    </Grow>
                )}
            </StyledPage>
        );
    }

    loadNotifications = () => {
        this.api
            .getNotifications()
            .then(response => {
                this.setState({
                    loadingNotifications: false,
                    notifications: response
                });
            })
            .catch(error => {
                this.setState({ loadingNotifications: false });
                this.showErrorDialog(error);
            });
    }
}

export default connect(mapStateToProps)(withTranslation()(NotificationsContainer));
