import cardValidator from 'card-validator';

export function addPaymentMethodFromCard(parent) {
    return new Promise((resolve, reject) => {
        parent.setState({ processing: true });

        const {
            cardAlias,
            nameOnCard,
            creditCard,
            expirationDate,
            cvv,
            addressLine1,
            addressLine2,
            city,
            state,
            zip
        } = parent.state;

        const expirationDateObject = cardValidator.expirationDate(
            expirationDate
        );

        const expMonth = expirationDateObject.month;
        const expYear = expirationDateObject.year;

        parent.api
            .addPaymentMethodFromCard(
                cardAlias,
                nameOnCard,
                creditCard,
                expMonth,
                expYear,
                cvv,
                addressLine1,
                addressLine2,
                city,
                state,
                zip
            )
            .then(() => {
                parent.setState({ processing: false });
                resolve();
            })
            .catch(error => {
                parent.setState({ processing: false });
                if (
                    error.response &&
                    error.response.code &&
                    error.response.code === 422 &&
                    error.response.errors
                ) {
                    let errorShown = false;
                    // eslint-disable-next-line no-unused-vars
                    for (const errorDetail of error.response.errors) {
                        if (
                            errorDetail.field === 'card_details.name_on_card' &&
                            errorDetail.error_code === 'invalid'
                        ) {
                            errorShown = true;
                            parent.showFieldError(
                                'nameOnCard',
                                'billing.paymentMethods.creditCard.errors.invalidNameOnCard'
                            );
                            break;
                        } else if (
                            errorDetail.field === 'card_number' &&
                            errorDetail.error_code === 'invalid'
                        ) {
                            errorShown = true;
                            parent.showFieldError(
                                'creditCard',
                                'billing.paymentMethods.creditCard.errors.invalidCard'
                            );
                            break;
                        } else if (
                            errorDetail.field === 'card_number' &&
                            errorDetail.error_code === 'unsupported_card_brand'
                        ) {
                            errorShown = true;
                            parent.showFieldError(
                                'creditCard',
                                'billing.paymentMethods.creditCard.errors.unsupportedBrand'
                            );
                            break;
                        }
                    }
                    if (!errorShown) {
                        parent.showErrorDialog(error);
                    }
                } else {
                    parent.showErrorDialog(error);
                }
                reject();
            });
    });
}

export function addPaymentMethodFromCheck(parent) {
    return new Promise((resolve, reject) => {
        parent.setState({ processing: true });

        const {
            nameOnAccount,
            accountNumber,
            routingNumber,
            accountType,
            accountAlias
        } = parent.state;

        parent.api
            .addPaymentMethodFromCheck(
                nameOnAccount,
                accountNumber,
                routingNumber,
                accountType,
                accountAlias
            )
            .then(() => {
                parent.setState({ processing: false });
                resolve();
            })
            .catch(error => {
                parent.setState({ processing: false });
                if (
                    error.response.code !== 422 ||
                    !error.response.errors.some(
                        it => it.field === 'routing_number'
                    )
                ) {
                    parent.showErrorDialog(error);
                }

                switch (error.response.errors[0].error_code) {
                    case 'unsupported_financial_institution':
                        parent.showFieldError(
                            'routingNumber',
                            'billing.validation.checking.unsupportedFinancialInstitution.message'
                        );
                        break;
                    default:
                        parent.showErrorDialog(error);
                }
                reject();
            });
    });
}
