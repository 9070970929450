import { connect } from 'react-redux';
import { mapStateToProps } from 'reducers';
import UsageContainerBase from 'features/usage/containers/UsageContainerBase';
import { withTranslation } from 'react-i18next';

class WaterUsageContainer extends UsageContainerBase {
    constructor(props) {
        super(props, 'water', 'gallons');
    }
}
export default connect(mapStateToProps)(withTranslation()(WaterUsageContainer));
