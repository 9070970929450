import React from 'react';
import ValidationConfiguration from 'validation/ValidationConfiguration';

export const ERROR_SUFFIX = 'Error';
export const FOCUS_SUFFIX = 'Focus';

export default class Validator {
    constructor(container) {
        if (typeof container.configureValidations !== 'function') {
            console.warn(
                'Validator was passed a container with a missing configureValidations function.'
            );
        }

        this.validationConfiguration = new ValidationConfiguration();
        this.container = container;
        container.configureValidations(this.validationConfiguration);
        this.currentFocus = this.createFocusReferences();
        this.newFocusRequired = true;
    }

    createFocusReferences() {
        /**
         * See https://material-ui.com/api/input/#props for documentation on
         * the inputRef attribute.
         *
         * See https://reactjs.org/docs/refs-and-the-dom.html for documentation
         * on why we are using createRef().
         */
        let firstFieldRef = null;

        this.validationConfiguration.validations.forEach(validation => {
            const focusName = validation.fieldName + FOCUS_SUFFIX;
            const fieldRef = React.createRef();
            this.container[focusName] = fieldRef;

            if (firstFieldRef === null) {
                firstFieldRef = fieldRef;
            }
        });

        return firstFieldRef;
    }

    maybeFocus() {
        setTimeout(() => {
            if (
                this.newFocusRequired === true &&
                this.currentFocus.current !== null
            ) {
                this.newFocusRequired = false;
                this.currentFocus.current.focus();
            }
        }, 100);
    }

    setFocus(fieldName) {
        const focusName = fieldName + FOCUS_SUFFIX;
        this.currentFocus = this.container[focusName];
        this.newFocusRequired = true;
    }

    validate(formFilter = null) {
        let validations;
        if (formFilter === null) {
            validations = this.validationConfiguration.validations;
        } else {
            validations = this.validationConfiguration.validations.filter(
                validation => validation.formName === formFilter
            );
        }

        let isValid = true;
        let newFocusField = null;
        const newState = {};
        validations.forEach(validation => {
            const errorName = validation.fieldName + ERROR_SUFFIX;
            if (validation.isValid(this.container)) {
                newState[errorName] = null;
            } else {
                isValid = false;
                newState[errorName] = validation.errorMessageResource;
                if (newFocusField === null) {
                    newFocusField = validation.fieldName;
                }
            }
        });

        if (newFocusField !== null) {
            this.setFocus(newFocusField);
        }

        this.container.setState(newState);
        return isValid;
    }
}
