import { EmptyContainer, EmptyImage, EmptyMessage, ThemedFabButton, ThemedListItem } from 'common/styles';
import { useTranslation, withTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import BillingPage from 'features/billing/components/BillingPage';
import Button from '@material-ui/core/Button/Button';
import { comparePaymentMethods } from 'features/billing/comparators';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { DialogBasic } from '../../../common/components/dialogs';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import IconButton from '@material-ui/core/IconButton/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import LoadingIndicator from 'common/components/LoadingIndicator';
import { mapStateToProps } from 'reducers';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import PaymentMethod from 'model/PaymentMethod';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import SuccessSnackbar from 'common/components/SuccessSnackbar';
import { ThemedListContainer } from 'common/styles';
import { ThemedTextField } from 'common/styles';

const ListIcon = styled.img`
    display: flex;
    height: 41px;
    width: 41px;
`;
const FormattedListItemText = styled(ListItemText)`
    word-break: break-word;
    max-width: 85%;
`;

/**
 * Displays a row in an {@link AccountList}.
 */
function PaymentMethodListItem(props) {
    const {
        onClickMoreMenu,
        paymentMethod,
    } = props;

    const { t } = useTranslation();

    const title = new PaymentMethod(paymentMethod).getDisplayName(t);

    const imageName = paymentMethod.type === 'card'
        ? '/img/payment_method_card.svg'
        : '/img/payment_method_check.svg';

    return (
        <ThemedListItem key={paymentMethod.id}>
            <ListIcon src={imageName} alt={t('billing.paymentMethods.aria.paymentMethodTypeIndicator')} />
            <FormattedListItemText primary={title} />
            <ListItemSecondaryAction>
                <IconButton
                    aria-label={t('billing.paymentMethods.aria.moreMenuOptions')}
                    aria-haspopup="true"
                    onClick={onClickMoreMenu(paymentMethod)}
                >
                    <MoreIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ThemedListItem>
    );
}

PaymentMethodListItem.propTypes = {
    paymentMethod: PropTypes.object.isRequired,
    onClickMoreMenu: PropTypes.func.isRequired
};

/**
 * Displays a list of accounts in session.
 */
function PaymentMethodList(props) {
    const {
        onClickMoreMenu,
        paymentMethods = props.paymentMethods || []
    } = props;

    const { t } = useTranslation();

    return (
        <ThemedListContainer>
            <List>
                {paymentMethods.map(paymentMethod => {
                    return (
                        <PaymentMethodListItem
                            key={paymentMethod.id}
                            paymentMethod={paymentMethod}
                            onClickMoreMenu={onClickMoreMenu}
                            t={t}
                        />
                    );
                })}
            </List>
        </ThemedListContainer>
    );
}

PaymentMethodList.propTypes = {
    paymentMethods: PropTypes.array.isRequired,
    onClickMoreMenu: PropTypes.func.isRequired
};

class PaymentMethodsContainer extends ContainerBase {
    constructor(props) {
        const {
            appState = props.appState || {},
            dispatch,
            location = props.location || {}
        } = props;

        let isSnackbarOpen = false;

        if (location.state && location.state.showAddPaymentMethodSnackbar) {
            isSnackbarOpen = location.state.showAddPaymentMethodSnackbar;
        }
        super(props, {
            basicDialogIsOpen: false,
            errorMessageTitle: '',
            errorMessageBody: '',
            processing: false,
            loadingPaymentMethods: true,
            paymentMethods: [],
            addMenuAnchorEl: null,
            moreMenuAnchorEl: null,
            selectedPaymentMethod: null,
            isSnackbarOpen: isSnackbarOpen,
            isPaymentDeletedSnackbarOpen: false,
            isUpdatedAliasSnackbarOpen: false,
            newAlias: '',
            newAliasDialogIsOpen: false,
            confirmDeleteDialogIsOpen: false
        });

        this.state.noMoreCharge = appState.noMoreCharge;
        this.state.noMoreChecks = appState.noMoreChecks;

        this.api = new FireflyAuthenticatedApi({ appState, dispatch});
    }

    componentDidMount() {
        this.loadPaymentMethods();
    }

    handleAdd = ({ currentTarget } = {}) => {
        const { noMoreCharge, noMoreChecks } = this.state;
        const { history } = this.props;

        if (noMoreCharge && noMoreChecks) {
            this.setState({
                errorMessageTitle: 'billing.common.unableToPay.title',
                errorMessageBody: 'billing.common.unableToPay.message'
            });
            this.showBasicDialog();
        } else if (noMoreCharge === true) {
            history.push('/billing/payment-methods/new-check');
        } else if (noMoreChecks === true) {
            history.push('/billing/payment-methods/new-card');
        } else {
            this.setState({ addMenuAnchorEl: currentTarget });
        }
    }

    handleSnackbarClose = () => {
        this.setState({ isSnackbarOpen: false });
    }

    handlePaymentDeletedSnackbarClose = () => {
        this.setState({ isPaymentDeletedSnackbarOpen: false });
    }

    handleUpdatedAliasSnackbarClose = () => {
        this.setState({ isUpdatedAliasSnackbarOpen: false });
    }

    handleAddMenuItemClick = value => {
        const { history } = this.props;

        this.setState({ addMenuAnchorEl: null });

        if (value === 'add-card') {
            history.push('/billing/payment-methods/new-card');
        } else if (value === 'add-check') {
            history.push('/billing/payment-methods/new-check');
        }
    }

    handleAddMenuClose = () => {
        this.setState({ addMenuAnchorEl: null });
    }

    handleMoreMenuItemClick = value => {
        const {
            selectedPaymentMethod = this.state.selectedPaymentMethod || {}
        } = this.state;

        this.setState({ moreMenuAnchorEl: null });

        switch (value) {
            case 'delete':
                this.setState({ confirmDeleteDialogIsOpen: true });
                break;
            case 'rename':
                this.setState({
                    newAlias: selectedPaymentMethod.alias,
                    newAliasDialogIsOpen: true
                });
                break;
            default:
                this.showErrorDialog(Error('Unhandled menu option.'));
        }
    }

    handleMoreMenuClose = () => {
        this.setState({ moreMenuAnchorEl: null });
    }

    handleDeletePayment = () => {
        const {
            selectedPaymentMethod = this.state.selectedPaymentMethod || {}
        } = this.state;

        this.setState({ processing: true });
        this.api
            .deletePaymentMethod(selectedPaymentMethod.id)
            .then(() => {
                const { paymentMethods } = this.state;
                let spliceId = null;
                paymentMethods.forEach((it, i) => {
                    if (it.id === selectedPaymentMethod.id) {
                        spliceId = i;
                    }
                });

                if (spliceId !== null) {
                    paymentMethods.splice(spliceId, 1);
                }

                this.setState({
                    paymentMethods: paymentMethods,
                    processing: false,
                    confirmDeleteDialogIsOpen: false,
                    isPaymentDeletedSnackbarOpen: true
                });
            })
            .catch(error => {
                this.setState({ processing: false });
                this.showErrorDialog(error);
            });
    }

    render() {
        const {
            t
        } = this.props;

        const {
            addMenuAnchorEl,
            basicDialogIsOpen,
            confirmDeleteDialogIsOpen,
            errorMessageBody,
            errorMessageTitle,
            isPaymentDeletedSnackbarOpen,
            isSnackbarOpen,
            isUpdatedAliasSnackbarOpen,
            loadingPaymentMethods,
            moreMenuAnchorEl,
            newAlias,
            newAliasDialogIsOpen,
            paymentMethods = this.state.paymentMethods || [],
            processing
        } = this.state;

        const hasPaymentMethodsToDisplay = paymentMethods.length > 0;
        const loadingOrProcessing = loadingPaymentMethods || processing;
        return (
            <BillingPage title={'billing.paymentMethods.header'} indentedHeader={true}>
                {loadingOrProcessing ? (
                    <LoadingIndicator />
                ) : (
                    <div>
                        <ThemedFabButton
                            size="small"
                            color="primary"
                            aria-label={t('billing.paymentMethods.aria.addPaymentMethodMenu')}
                            onClick={this.handleAdd}
                        >
                            <AddIcon />
                        </ThemedFabButton>

                        {!hasPaymentMethodsToDisplay ? (
                            <EmptyContainer>
                                <EmptyImage src="/img/empty_state_payment_methods.svg" />
                                <EmptyMessage>
                                    {t('billing.paymentMethods.emptyStateMessage')}
                                </EmptyMessage>
                            </EmptyContainer>
                        ) : (
                            <PaymentMethodList
                                paymentMethods={paymentMethods
                                    .map(it => new PaymentMethod(it))
                                    .sort((a, b) =>
                                        comparePaymentMethods(a, b, t)
                                    )}
                                onClickMoreMenu={this.onClickMoreMenu}
                                t={t}
                            />
                        )}
                    </div>
                )}

                <Menu
                    id="add-menu"
                    anchorEl={addMenuAnchorEl}
                    open={Boolean(addMenuAnchorEl)}
                    onClose={this.handleAddMenuClose}
                >
                    <MenuItem value="add-card" onClick={e => this.handleAddMenuItemClick('add-card', e)}>
                        {t('billing.addCard.header')}
                    </MenuItem>
                    <MenuItem value="add-check" onClick={e => this.handleAddMenuItemClick('add-check', e)}>
                        {t('billing.addCheck.header')}
                    </MenuItem>
                </Menu>

                <Menu
                    id="more-menu"
                    anchorEl={moreMenuAnchorEl}
                    open={Boolean(moreMenuAnchorEl)}
                    onClose={this.handleMoreMenuClose}
                >
                    <MenuItem value="rename" onClick={e => this.handleMoreMenuItemClick('rename', e)}>
                        {t('common.rename')}
                    </MenuItem>
                    <MenuItem value="delete" onClick={e => this.handleMoreMenuItemClick('delete', e)}>
                        {t('common.delete')}
                    </MenuItem>
                </Menu>
                <SuccessSnackbar
                    id="delete-saved-payment"
                    open={isPaymentDeletedSnackbarOpen}
                    onClose={this.handlePaymentDeletedSnackbarClose}
                    message={t('settings.accounts.accountDetails.deleteSuccessMessage')}
                />
                <SuccessSnackbar
                    id="rename-saved-payment"
                    open={isUpdatedAliasSnackbarOpen}
                    onClose={this.handleUpdatedAliasSnackbarClose}
                    message={t('billing.paymentMethods.paymentRenamed')}
                />
                <SuccessSnackbar
                    id="add-saved-payment"
                    open={isSnackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message={t('settings.accounts.accountDetails.successMessage')}
                />

                <DialogBasic
                    message={t(errorMessageBody)}
                    onCloseDialog={this.closeBasicDialog}
                    open={basicDialogIsOpen}
                    title={t(errorMessageTitle)}
                />
                <Dialog
                    open={newAliasDialogIsOpen}
                    onClose={() => {this.setState({
                        newAliasDialogIsOpen: false,
                        newAlias: ''
                    });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{t('billing.paymentMethods.editAlias')}</DialogTitle>
                    <DialogContent>
                        <ThemedTextField
                            id="newAlias"
                            inputRef={this.newAliasFocus}
                            label={t('common.alias')}
                            type="text"
                            value={newAlias}
                            onChange={this.handleInputChange('newAlias')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {this.setState({
                                newAliasDialogIsOpen: false,
                                newAlias: ''
                            });
                            }}
                            color="primary"
                        >
                            {t('common.cancel')}
                        </Button>

                        <Button onClick={() => this.handleUpdateAlias()} color="primary">
                            {t('common.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={confirmDeleteDialogIsOpen}
                    onClose={() => {this.setState({ confirmDeleteDialogIsOpen: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {t('billing.paymentMethods.confirmDelete.title')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t('billing.paymentMethods.confirmDelete.message')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => this.setState({ confirmDeleteDialogIsOpen: false })}
                            color="primary"
                        >
                            {t('common.cancel')}
                        </Button>

                        <Button onClick={this.handleDeletePayment} color="primary">
                            {t('common.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </BillingPage>
        );
    }

    handleInputChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.value });
    }

    handleUpdateAlias = () => {
        const {
            newAlias,
            selectedPaymentMethod = this.state.selectedPaymentMethod || {},
            paymentMethods
        } = this.state;

        this.setState({ newAliasDialogIsOpen: false, processing: true });

        this.api
            .updatePaymentMethod(newAlias, selectedPaymentMethod.id)
            .then(() => {
                paymentMethods.forEach(it => {
                    if (it.id === selectedPaymentMethod.id) {
                        it.alias = newAlias === '' ? null : newAlias;
                    }
                });

                this.setState({
                    paymentMethods: paymentMethods,
                    processing: false,
                    newAlias: '',
                    isUpdatedAliasSnackbarOpen: true
                });
            })
            .catch(error => {
                this.setState({ processing: false });
                this.showErrorDialog(error);
            });
    }

    loadPaymentMethods = () => {
        this.api
            .getPaymentMethods()
            .then(response => {
                this.setState({
                    loadingPaymentMethods: false,
                    paymentMethods: response
                });
            })
            .catch(error => {
                this.setState({ loadingPaymentMethods: false });
                this.showErrorDialog(error);
            });
    }

    onClickMoreMenu = paymentMethod => ({ currentTarget } = {}) => {
        this.setState({
            selectedPaymentMethod: paymentMethod,
            moreMenuAnchorEl: currentTarget
        });
    }
}

export default connect(mapStateToProps)(withTranslation()(PaymentMethodsContainer));
