import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import FireflyAuthenticatedApi from "../../../api/FireflyAuthenticatedApi";
import { Intro } from 'features/wizard/styles';
import { mapStateToProps } from 'reducers';
import { NavButton } from 'common/components/nav-button';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import WizardStep from 'features/wizard/components/WizardStep';

const CompleteImage = styled.img`
    height: 154px;
    margin-top: 10px;
    margin-bottom: 20px;
`;

const OkButton = styled(NavButton)`
    && {
        min-width: 140px;
    }
`;

const CompleteIntro = styled(Intro)`
    margin-bottom: 30px;
`;

class WizardCompleteContainer extends ContainerBase {
    /**
     * Handles the onClick event for the OK button. Performs any necessary
     * wizard completion steps and then routes the user to the home page.
     */
    handleOnComplete = () => {
        const {
            appState = this.props.appState || {},
            dispatch,
            history,
            wizardContext
        } = this.props;

        let onComplete;
        if (wizardContext.wasFromOnboarding === true) {
            const authenticatedApi = new FireflyAuthenticatedApi({ appState, dispatch });
            const user = appState.user || {};
            onComplete = authenticatedApi.removeOnboardingRequirement(user.id);
        } else {
            onComplete = Promise.resolve(true);
        }

        onComplete.then(() => {
            history.push('/');
        });
    };

    render() {
        const { t } = this.props;

        return (
            <WizardStep>
                <CompleteImage src="/img/wizard_complete.svg" />
                <CompleteIntro>{t('wizard.complete.intro')}</CompleteIntro>
                <OkButton
                    key="continue"
                    variant="contained"
                    color="primary"
                    onClick={this.handleOnComplete}
                >
                    {t('common.ok')}
                </OkButton>
            </WizardStep>
        );
    }
}

WizardCompleteContainer.propTypes = {
    orientation: PropTypes.string.isRequired,
    wizardContext: PropTypes.object.isRequired
};

export default withRouter(connect(mapStateToProps)(withTranslation()(WizardCompleteContainer)));
