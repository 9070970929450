import * as echarts from 'echarts';
import styled, { materialUiTheme } from 'theme';
import AppConstants from 'AppConstants';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

const centerTextRadiusDesktop = 105;
const centerTextRadiusPhone = 60;

const ChartContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;
const Chart = styled.div`
    width: 100%;
    height: 500px;
`;
const CenterText = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    padding: 16px;
    width: ${centerTextRadiusDesktop * 2}px;
    height: ${centerTextRadiusDesktop * 2}px;
    transform: translate(
        -${centerTextRadiusDesktop}px,
        -${centerTextRadiusDesktop}px
    );
    border-radius: 50%;
    ${materialUiTheme.breakpoints.down('sm')} {
        padding: 8px;
        width: ${centerTextRadiusPhone * 2}px;
        height: ${centerTextRadiusPhone * 2}px;
        transform: translate(
            -${centerTextRadiusPhone}px,
            -${centerTextRadiusPhone}px
        );
    }
`;
const CalloutValue = styled.div`
    font-family: Soleil, sans-serif;
    font-size: 32px;
    color: ${AppConstants.COLOR_TEXT};
    text-align: center;
    ${materialUiTheme.breakpoints.down('sm')} {
        font-size: 20px;
    }
`;
const CalloutDescription = styled.div`
    font-family: Soleil, sans-serif;
    font-size: 24px;
    color: ${AppConstants.CHART_TEXT_SUB};
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    ${materialUiTheme.breakpoints.down('sm')} {
        font-size: 14px;
    }
`;
const MessageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
`;
const NoData = styled.span`
    color: ${AppConstants.COLOR_TEXT_SUB};
    font-size: 14px;
`;

class PieChart extends React.Component {
    constructor(props) {
        super(props);

        this.chartRef = React.createRef();
        this.chart = null;
    }

    componentDidMount() {
        this.drawOrUpdateChart();
    }

    componentDidUpdate() {
        this.drawOrUpdateChart();
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    drawOrUpdateChart() {
        const {
            series = this.props.series || []
        } = this.props;

        if (series.length > 0) {
            this.chart = echarts.init(this.chartRef.current);
            this.chart.setOption(this.getConfig());
        }
    }

    getConfig() {
        const {
            series = this.props.series || []
        } = this.props;

        return {
            tooltip: {
                trigger: 'item',
                backgroundColor: '#ffffff',
                textStyle: {
                    color: AppConstants.COLOR_TEXT,
                    fontFamily: 'Soleil'
                },
                extraCssText: 'box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);',
                padding: 16
            },
            legend: {
                data: series.map(data => ({ icon: 'circle', name: data.name })),
                itemGap: 20,
                textStyle: {
                    color: AppConstants.COLOR_TEXT,
                    fontFamily: 'Soleil'
                }
            },
            series: [
                {
                    type: 'pie',
                    radius: ['42%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    data: series.map((data, i) => ({
                        name: data.name,
                        value: data.value,
                        itemStyle: {
                            color: AppConstants.CHART_COLORS[i],
                            borderWidth: 2,
                            borderColor: '#ffffff'
                        }
                    }))
                }
            ]
        };
    }

    render() {
        const {
            calloutValue,
            calloutDescription,
            isLoading,
            series = this.props.series || [],
            t,
        } = this.props;

        if (series.length === 0) {
            return (
                <MessageContainer>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <NoData>{t('common.charting.barChart.noData')}</NoData>
                    )}
                </MessageContainer>
            );
        }

        return (
            <ChartContainer>
                <Chart ref={this.chartRef} />
                <CenterText>
                    <CalloutValue>{calloutValue}</CalloutValue>
                    <CalloutDescription>
                        {calloutDescription}
                    </CalloutDescription>
                </CenterText>
            </ChartContainer>
        );
    }
}

PieChart.propTypes = {
    series: PropTypes.array.isRequired,
    calloutValue: PropTypes.string.isRequired,
    calloutDescription: PropTypes.string.isRequired,
    isLoading: PropTypes.bool
};
PieChart.defaultProps = {
    isLoading: false
};

export default withTranslation()(PieChart);
