/**
 * Converts the accounts array and the associated account details from its API
 * form to its appState form.
 */
export function mergeAccountsAndDetails(accounts, accountDetails) {
    return accounts.map(account => {
        const accountDetail = accountDetails.find(
            it => account.account_number === it.account_number
        );

        let amountDue;
        let amountDueDate = null;
        let amountDueDateType = 'not_due';

        if (accountDetail.past_due_amount > 0) {
            amountDue = accountDetail.past_due_amount;
        } else if (accountDetail.prepaid) {
            amountDue = (accountDetail.prepaid_balance || 0) * -1;
        } else {
            amountDue = accountDetail.balance;
        }

        if (accountDetail.past_due_amount > 0) {
            amountDueDateType = 'past_due';
        } else if (accountDetail.balance > 0) {
            amountDueDate = accountDetail.display_due_date;
            amountDueDateType = 'due';
        }

        return {
            accountNumber: account.account_number,
            memberNumber: account.member_number,
            type: account.type,
            alias: account.alias,
            canGrantToOthers: account.can_grant_to_others,
            cutoff: accountDetail.cutoff,
            delinquent: accountDetail.delinquent,
            disconnected: accountDetail.disconnected,
            automaticPayment: accountDetail.automatic_payment,
            noMoreCharge: accountDetail.no_more_charge,
            noMoreChecks: accountDetail.no_more_checks,
            balance: accountDetail.balance,
            pastDueAmount: accountDetail.past_due_amount,
            lastPaymentAmount: accountDetail.last_payment_amount,
            lastBillDate: accountDetail.last_bill_date,
            lastBillDueDate: accountDetail.last_bill_due_date,
            lastPaymentDate: accountDetail.last_payment_date,
            prepaid: accountDetail.prepaid,
            prepaidBalance: accountDetail.prepaid_balance,
            prepaidMeterStatus: accountDetail.prepaid_meter_status,
            budget: accountDetail.budget,
            budgetAmount: accountDetail.budget_amount,
            activeArrangement: accountDetail.active_arrangement,
            arrangementBalance: accountDetail.arrangement_balance,
            arrangementInstallmentAmount: accountDetail.arrangement_installment_amount,
            arrangementInstallmentDueDate: accountDetail.arrangement_installment_due_date,
            displayDueDate: accountDetail.display_due_date,
            displayAsCutoff: accountDetail.display_as_cutoff,
            outageStatus: accountDetail.outage_status,
            amountDue: amountDue,
            amountDueDate: amountDueDate,
            amountDueDateType: amountDueDateType
        };
    });
}

export default class Account {
    constructor(rawData) {
        Object.assign(this, rawData);
    }

    getDisplayName(t) {
        const accountType = t('common.accounts.types.' + this.type);

        return this.alias != null && this.alias !== ''
                ? this.alias
                : t('common.accounts.titleNoAlias', {
                      accountNumber: this.accountNumber,
                      type: accountType
                  });
    };

    getSubTitle(t) {
        return t('common.accounts.subtitle', {
            accountNumber: this.accountNumber
        });
    };
}
