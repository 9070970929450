import { EmptyContainer, EmptyImage, EmptyMessage } from 'common/styles';
import styled, { materialUiTheme } from 'theme';
import { useTranslation, withTranslation } from 'react-i18next';
import AccountTypeIcon from 'common/components/AccountTypeIcon';
import AppConstants from 'AppConstants';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import CurrencyAmount from 'common/components/CurrencyAmount';
import { DialogBasic } from '../../../common/components/dialogs';
import Divider from '@material-ui/core/Divider';
import { DueDate } from 'common/components/due-date';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import GetUserTask from 'common/tasks/GetUserTask';
import Hidden from '@material-ui/core/Hidden/Hidden';
import IconButton from '@material-ui/core/IconButton';
import LeftNav from 'common/components/LeftNav';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import LoadingIndicator from 'common/components/LoadingIndicator';
import { mapStateToProps } from 'reducers';
import { NavButton } from 'common/components/nav-button';
import { navigations } from 'features/billing/navigations';
import Page from 'common/components/Page';
import PropTypes from 'prop-types';
import React from 'react';
import { refreshData } from 'actions';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ThemedListHeader } from 'common/styles';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Typography from '@material-ui/core/Typography';

const PageContent = styled.div`
    padding-left: 0;
    display: flex;
    flex-direction: column;
`;
const HeaderRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: -8px 0 4px 0;
`;
const CustomHeader = styled(ThemedListHeader)`
    margin-bottom: 0;
`;
const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 140px;
    ${materialUiTheme.breakpoints.down('xs')} {
        padding-top: 60px;
    }
`;
const AccountListItem = styled(ListItem)`
    && {
        padding-left: 12px;
        padding-right: 12px;
    }
`;
const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 16px 12px 0;
    ${materialUiTheme.breakpoints.down('xs')} {
        justify-content: center;
        padding-right: 0;
    }
`;

const MobileMenuList = styled(List)`
    margin-top: 16px !important;
`;

const AccountTitleTypography = styled(Typography).attrs({
    variant: 'subtitle1'
})`
    && {
        ${materialUiTheme.breakpoints.down('sm')} {
            font-size: 0.875rem;
        }
    }
`;

const AccountSubtitleTypography = styled(Typography).attrs({
    variant: 'subtitle2',
    color: 'textSecondary'
})`
    && {
        ${materialUiTheme.breakpoints.down('sm')} {
            font-size: 0.75rem;
        }
    }
`;

const AccountListItemText = styled(ListItemText)``;

const AmountDueListItemText = styled(ListItemText)`
    && {
        padding-right: 0;
        text-align: right;
    }
`;
const CustomSecondaryAction = styled(ListItemSecondaryAction)`
    && {
        right: 12px;
    }
`;
const CustomDivider = styled(Divider)`
    && {
        margin: 0 12px;
    }
`;
const TotalDueContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 12px;
`;

const TotalDueLabelText = styled.div`
    color: ${AppConstants.COLOR_TEXT_SUB};
    flex-grow: 1;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
`;

const TotalDueText = styled.div`
    flex-grow: 1;
    font-weight: 400;
    text-align: right;
`;

function BillingAccountListItem(props) {
    const {
        account = props.account || {},
        onClickAccount
    } = props;

    const { t } = useTranslation();
    const title = account.getDisplayName(t);
    const subtitle = account.getSubTitle(t);

    const dueBy = (
        <DueDate amountDueDate={account.amountDueDate} amountDueDateType={account.amountDueDateType} />
    );

    const amountDue = <CurrencyAmount amount={account.amountDue} />;

    return (
        <AccountListItem key={account.accountNumber} button onClick={() => onClickAccount(account.accountNumber)}>
            <AccountTypeIcon accountType={account.type} />
            <AccountListItemText
                primary={<AccountTitleTypography>{title}</AccountTitleTypography>}
                secondary={<AccountSubtitleTypography component={'span'}>{subtitle}</AccountSubtitleTypography>}
            />
            <CustomSecondaryAction>
                <AmountDueListItemText
                    primary={<AccountTitleTypography>{amountDue}</AccountTitleTypography>}
                    secondary={<AccountSubtitleTypography component={'span'}>{dueBy}</AccountSubtitleTypography>}
                />
            </CustomSecondaryAction>
        </AccountListItem>
    );
}

BillingAccountListItem.propTypes = {
    account: PropTypes.object.isRequired,
    onClickAccount: PropTypes.func.isRequired
};

function BillingAccountList(props) {
    const {
        accounts = props.accounts || [],
        onClickAccount
    } = props;

    const { t } = useTranslation();

    return (
        <div>
            <List>
                {accounts.map(account => {
                    return (
                        <BillingAccountListItem
                            key={account.accountNumber}
                            account={account}
                            onClickAccount={onClickAccount}
                            t={t}
                        />
                    );
                })}
            </List>
        </div>
    );
}

BillingAccountList.propTypes = {
    accounts: PropTypes.array.isRequired,
    onClickAccount: PropTypes.func.isRequired
};

class BillingContainer extends ContainerBase {
    constructor(props) {
        const {
            appState,
            dispatch,
            location = props.location || {}
        } = props;

        let returnedFromSuccessfulPayment = false;
        if (location.state && location.state.returnedFromSuccessfulPayment) {
            returnedFromSuccessfulPayment = location.state.returnedFromSuccessfulPayment;
        }

        super(props, {
            basicDialogIsOpen: false,
            errorMessageTitle: '',
            errorMessageBody: '',
            processing: false,
            returnedFromSuccessfulPayment: returnedFromSuccessfulPayment
        });

        this.state.noMoreCharge = appState.noMoreCharge;
        this.state.noMoreChecks = appState.noMoreChecks;

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });
    }

    componentDidMount() {
        const {
            appState = this.props.appState || {}
        } = this.props;

        const { returnedFromSuccessfulPayment } = this.state;

        if (returnedFromSuccessfulPayment || appState.isStale()
        ) {
            this.refreshData();
            this.setState({ returnedFromSuccessfulPayment: false });
        }
    }

    handleMakePayment = () => {
        const { history } = this.props;
        const { noMoreCharge, noMoreChecks } = this.state;

        if (noMoreCharge && noMoreChecks) {
            this.setState({
                errorMessageTitle: 'billing.common.unableToPay.title',
                errorMessageBody: 'billing.common.unableToPay.message'
            });
            this.showBasicDialog();
        } else {
            history.push('/billing/make-payment');
        }
    }

    handleViewPaymentHistory = accountNumber => {
        const { history } = this.props;

        history.push('/billing/' + accountNumber + '/payment-history');
    }

    render() {
        const {
            appState = this.props.appState || {},
            t
        } = this.props;

        const {
            basicDialogIsOpen,
            errorMessageBody,
            errorMessageTitle,
            processing
        } = this.state;

        const accounts = (appState.accountsSorted && appState.accountsSorted(t)) || [];
        const totalAmountDue = appState.amountDue;
        const hasAccountsToDisplay = accounts.length > 0;

        return (
            <Page padding="none">
                <LeftNav title="billing.header" to="/billing" navigations={navigations}>
                    <PageContent>
                        <HeaderRow>
                            <CustomHeader>{t('billing.billingSummary.header')}</CustomHeader>
                            {!processing && (
                                <Tooltip title={t('home.refresh')} placement="bottom">
                                    <IconButton onClick={() => { this.refreshData(); }}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </HeaderRow>
                        {processing ? (
                            <LoadingContainer>
                                <LoadingIndicator />
                            </LoadingContainer>
                        ) : hasAccountsToDisplay ? (
                            <div>
                                <BillingAccountList
                                    accounts={accounts}
                                    onClickAccount={this.handleViewPaymentHistory}
                                    t={t}
                                    appState={appState}
                                />

                                <CustomDivider />

                                <TotalDueContainer>
                                    <TotalDueLabelText>
                                        {t('billing.billingSummary.totalDue')}
                                    </TotalDueLabelText>
                                    <TotalDueText>
                                        <CurrencyAmount amount={totalAmountDue} />
                                    </TotalDueText>
                                </TotalDueContainer>

                                <ButtonRow>
                                    <NavButton color="primary" variant="contained" onClick={this.handleMakePayment}>
                                        {t('billing.makePayment.header')}
                                    </NavButton>
                                </ButtonRow>

                                <Hidden smUp={true}>
                                    <MobileMenuList>
                                        <ListItem
                                            key="billing-history"
                                            to="/billing/history"
                                            component={React.forwardRef((props, ref) => ( <Link {...props} ref={ref} /> ))}
                                            button
                                        >
                                            <ListItemText
                                                primary={t('billing.history.header')}
                                            />
                                        </ListItem>
                                        <ListItem
                                            key="automatic-payments"
                                            to="/billing/automatic-payments"
                                            component={React.forwardRef((props, ref) => ( <Link {...props} ref={ref} /> ))}
                                            button
                                        >
                                            <ListItemText primary={t('billing.automaticPayments.header')} />
                                        </ListItem>
                                        <ListItem
                                            key="payment-methods"
                                            to="/billing/payment-methods"
                                            component={React.forwardRef((props, ref) => ( <Link {...props} ref={ref} /> ))}
                                            button
                                        >
                                            <ListItemText
                                                primary={t('billing.paymentMethods.header')}
                                            />
                                        </ListItem>
                                    </MobileMenuList>
                                </Hidden>
                            </div>
                        ) : (
                            <EmptyContainer>
                                <EmptyImage src="/img/empty_state_default.svg" />
                                <EmptyMessage>
                                    {t('common.noAccountsLinkedMessage')}
                                </EmptyMessage>
                            </EmptyContainer>
                        )}
                        <DialogBasic
                            message={t(errorMessageBody)}
                            onCloseDialog={this.closeBasicDialog}
                            open={basicDialogIsOpen}
                            title={t(errorMessageTitle)}
                        />
                    </PageContent>
                </LeftNav>
            </Page>
        );
    }

    refreshData = () => {
        const { dispatch } = this.props;

        this.setState({ processing: true});

        const getUserTask = new GetUserTask(this.api);
        getUserTask
            .execute()
            .then(response => {
                dispatch(refreshData(response.user, response.accountDetails));
                this.setState({ processing: false });
            })
            .catch(error => {
                this.setState({ processing: false });
                this.showErrorDialog(error);
            });
    }
}

export default connect(mapStateToProps)(withTranslation()(BillingContainer));
