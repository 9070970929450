import ProgressButton from 'common/components/ProgressButton';
import styled from 'theme';

export const Intro = styled.p`
    text-align: center;
    font-size: 16px;
    margin-top: 0;
`;

export const ContinueButton = styled(ProgressButton)`
    && {
        min-width: 180px;
    }
`;
