import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'theme';

const ProgressButtonContainer = styled.span`
    position: relative;
`;
const CenteredCircularProgress = styled(CircularProgress)`
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -12px;
    margin-left: -12px;
`;

export default function ProgressButton(props) {
    const {
        children,
        loading,
        ...other
    } = props;
    return (
        <ProgressButtonContainer>
            <Button {...other} disabled={loading}>
                {children}
            </Button>
            {loading && <CenteredCircularProgress size={24} />}
        </ProgressButtonContainer>
    );
}
