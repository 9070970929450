import AppConstants from 'AppConstants.js';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import styled from 'theme';

const CheckIcon = styled(CheckCircleIcon)`
    && {
        font-size: 16px;
        margin-right: 10px;
    }
`;
const Content = styled(SnackbarContent)`
    && {
        background-color: ${AppConstants.COLOR_SNACKBAR_BACKGROUND};
        color: ${AppConstants.COLOR_SNACKBAR_TEXT};
    }
`;
const Message = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

function SuccessSnackbar(props) {
    const { id, open, onClose, message } = props;
    const snackbarId = 'snackbar-' + id;

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            autoHideDuration={6000}
            open={open}
            onClose={onClose}
            ContentProps={{
                'aria-describedby': snackbarId
            }}
        >
            <Content
                message={
                    <Message>
                        <CheckIcon />
                        {message}
                    </Message>
                }
            />
        </Snackbar>
    );
}

SuccessSnackbar.propTypes = {
    id: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired
};

export default SuccessSnackbar;
