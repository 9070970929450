import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import FireflyApi from 'api/FireflyApi';
import { mapStateToProps } from 'reducers';
import MessageCard from 'common/components/MessageCard';
import React from 'react';
import { withTranslation } from 'react-i18next';

class VerifyEmailContainer extends ContainerBase {
    constructor(props) {
        super(props, {
            loading: false,
            token: '',
            view: 'verification' // 'verification', 'invalidToken', 'success',
        });
    }
    render() {
        const { t } = this.props;

        const {
            loading,
            view
        } = this.state;

        switch (view) {
            case 'invalidToken':
                return (
                    <MessageCard
                        container={this}
                        message={t('verifyEmail.invalidToken.message')}
                        src="/img/disconnected_plugs.svg"
                        onClickHandler={this.redirectToLogin}
                    />
                );
            case 'success':
                return (
                    <MessageCard
                        container={this}
                        message={t('verifyEmail.success.message')}
                        src="/img/success_trophy.svg"
                        onClickHandler={this.redirectToLogin}
                    />
                );
            default:
                return (
                    <MessageCard
                        container={this}
                        buttonLabel={t('verifyEmail.title')}
                        message={t('verifyEmail.confirmRequest')}
                        src="/img/verify.svg"
                        onClickHandler={this.validateEmail}
                        loading={loading}
                    />
                );
        }
    }

    validateEmail = () => {
        const {
            match = this.props.match || {}
        } = this.props;

        const token = match.params && match.params.token;
        const api = new FireflyApi();
        this.setState({ loading: true, token: token });

        api.verifyEmail(token)
            .then(() => {
                this.setState({
                    loading: false,
                    view: 'success'
                });
            })
            .catch(error => {
                this.setState({ loading: false });
                switch (error.status) {
                    case 422:
                        if (error.response.errors[0].field === 'token') {
                            this.setState({
                                view: 'invalidToken'
                            });
                        } else {
                            this.showErrorDialog(error);
                        }
                        break;
                    default:
                        this.showErrorDialog(error);
                }
            });
    }

    redirectToLogin = () => {
        const { history } = this.props;

        history.push('/login');
    }
}

export default connect(mapStateToProps)(withTranslation()(VerifyEmailContainer));
