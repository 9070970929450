import styled, { materialUiTheme } from 'theme';
import { ThemedPageHeader, ThemedTextField } from 'common/styles';
import Card from '@material-ui/core/Card';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import { DialogBasic } from 'common/components/dialogs';
import ErrorMessage from 'common/components/ErrorMessage';
import FireflyApi from 'api/FireflyApi';
import GridContainer from 'common/components/GridContainer';
import GridItem from 'common/components/GridItem';
import Hidden from '@material-ui/core/Hidden';
import { mapStateToProps } from 'reducers';
import { NavButton } from 'common/components/nav-button';
import Page from 'common/components/Page';
import ProgressButton from 'common/components/ProgressButton';
import React from 'react';
import Validator from 'validation/Validator';
import { withTranslation } from 'react-i18next';

const PageWithBackground = styled(Page)`
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    align-items: center;
    ${materialUiTheme.breakpoints.up('sm')} {
        background-image: url('/img/login_bg.jpg');
    }
`;
const FormCard = styled(Card)`
    padding: 50px;
`;
const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const CancelButton = styled(NavButton)`
    && {
        margin-right: 20px;
        text-transform: inherit;
    }
`;
const SubmitButton = styled(ProgressButton)`
    width: 120px;
`;
function ResponsiveFormCard({ children }) {
    return (
        <div>
            <Hidden xsDown={true}>
                <FormCard>{children}</FormCard>
            </Hidden>
            <Hidden smUp={true}>{children}</Hidden>
        </div>
    );
}

class ForgotPasswordContainer extends ContainerBase {
    constructor(props) {
        super(props, {
            email: '',
            emailError: null
        });

        this.validator = new Validator(this);
    }

    componentDidMount() {
        this.validator.maybeFocus();
    }

    componentDidUpdate() {
        this.validator.maybeFocus();
    }

    render() {
        const { t } = this.props;

        const {
            basicDialogIsOpen,
            email,
            emailError,
            loading
        } = this.state;

        return (
            <PageWithBackground>
                <GridContainer>
                    <GridItem xs={false} md={2} lg={3} />
                    <GridItem xs={12} md={8} lg={6}>
                        <ResponsiveFormCard>
                            <ThemedPageHeader>
                                {t('forgotPassword.header')}
                            </ThemedPageHeader>

                            <p>{t('forgotPassword.instructions')}</p>

                            <form onSubmit={this.submit}>
                                <ThemedTextField
                                    id="email"
                                    inputRef={this.emailFocus}
                                    label={t('common.labelEmail')}
                                    type="email"
                                    value={email}
                                    disabled={loading}
                                    onChange={this.handleInputChange('email')}
                                    error={emailError !== null}
                                />
                                <ErrorMessage
                                    resourceId={emailError}
                                />
                                <ButtonRow>
                                    <CancelButton color="primary" disabled={loading} to="/login">
                                        {t('common.cancel')}
                                    </CancelButton>
                                    <SubmitButton
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        loading={loading}
                                    >
                                        {t('forgotPassword.buttonSubmit')}
                                    </SubmitButton>
                                </ButtonRow>
                            </form>
                        </ResponsiveFormCard>
                    </GridItem>
                </GridContainer>
                <DialogBasic
                    message={t('forgotPassword.successModal.message')}
                    onCloseDialog={this.closeBasicDialog}
                    open={basicDialogIsOpen}
                    title={t('forgotPassword.successModal.title')}
                />
            </PageWithBackground>
        );
    }

    handleInputChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.value });
    }

    configureValidations(config) {
        config.requireEmail('email');
    }

    submit = e => {
        const { email } = this.state;

        e.preventDefault();

        if (!this.validator.validate()) {
            return;
        }

        this.setState({ loading: true });
        const api = new FireflyApi();
        api.forgotPassword(email)
            .then(() => {
                this.setState({ loading: false });

                this.showBasicDialog();
            })
            .catch(error => {
                switch (error.status) {
                    case 422:
                        switch (error.response.error) {
                            // sb: the only validation checks for this request
                            // are field validations for email of
                            // "missing_field" and "invalid", which should both
                            // be handled by our client-side validation.
                            default:
                                this.setState({ loading: false });
                                this.showErrorDialog(error);
                        }
                        break;
                    default:
                        this.setState({ loading: false });
                        this.showErrorDialog(error);
                }
            });
    }

    onBasicDialogClosed() {
        const { history } = this.props;

        history.push('/login');
    }
}

export default connect(mapStateToProps)(withTranslation()(ForgotPasswordContainer));
