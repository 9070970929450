/**
 * Sums two numbers and returns the result.
 *
 * @param a The first number.
 * @param b The second number.
 * @returns {number} The sum of the two numbers.
 */
function sum(a, b) {
    // Purposely not using Number.parseFloat for IE11 compatibility reasons
    return parseFloat(a) + parseFloat(b);
}

export default sum;
