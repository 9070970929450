import styled, { css } from 'theme';
import AppConstants from 'AppConstants.js';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import React from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';
import { useTranslation } from 'react-i18next';

const Copyright = styled.div`
    text-align: center;
`;

const Version = styled.div`
    text-align: center;
    font-size: 14px;
`;

const FooterContainer = styled.div`
    width: 100%;
    height: ${AppConstants.FOOTER_HEIGHT}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${AppConstants.COLOR_FOOTER_BACKGROUND};
    color: ${AppConstants.COLOR_TEXT_FOOTER};
`;

const SocialRow = styled.div`
    margin-top: 10px;
    text-align: center;
    height: 30px;
`;

const SocialLink = styled.a`
    color: #cccccc;
    width: 60px;
    display: inline-block;
    &:hover {
        color: #ffffff;
    }
`;

const SocialIcon = css`
    && {
        font-size: 30px;
    }
`;

const FacebookStyled = styled(FacebookIcon)`
    ${SocialIcon};
`;

const TwitterStyled = styled(TwitterIcon)`
    ${SocialIcon};
`;

const InstagramStyled = styled(InstagramIcon)`
    ${SocialIcon};
`;

const EmailStyled = styled(EmailIcon)`
    ${SocialIcon};
`;

function Footer() {
    const { t } = useTranslation();

    return (
        <FooterContainer>
            <Copyright>{t('footer.copyright', { date: new Date().getFullYear() })}</Copyright>
            <Version>{t('footer.version')} {process.env.REACT_APP_VERSION}</Version>
            <SocialRow>
                <SocialLink href="https://facebook.com/TalquinElectricCoop" aria-label={t('footer.social.facebook')}>
                    <FacebookStyled />
                </SocialLink>
                <SocialLink href="https://twitter.com/tectwwi" aria-label={t('footer.social.twitter')}>
                    <TwitterStyled />
                </SocialLink>
                <SocialLink href="https://instagram.com/tectwwi" aria-label={t('footer.social.instagram')}>
                    <InstagramStyled />
                </SocialLink>
                <SocialLink
                    href="https://www.talquinelectric.com/contact/member-feedback/"
                    aria-label={t('footer.social.email')}
                    target="_blank"
                >
                    <EmailStyled />
                </SocialLink>
            </SocialRow>
        </FooterContainer>
    );
}

export default Footer;
