import React, { Fragment } from 'react';
import styled, { materialUiTheme } from 'theme';
import AppConstants from 'AppConstants.js';
import BannerNotificationType from 'model/BannerNotificationType';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Close from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import { mapStateToProps } from 'reducers';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const NoticeIcon = styled(ErrorIcon)`
    && {
        font-size: 18px;
        margin-right: 8px;
    }
`;

const ConfirmIcon = styled(CheckCircleIcon)`
    && {
        font-size: 18px;
        margin-right: 8px;
    }
`;

const CloseIcon = styled(Close)`
    && {
        font-size: 18px;
    }
`;

const StyledCollapse = styled(Collapse)`
    && {
        width: 100%;
    }
`;

const BannerContainer = styled.div`
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${AppConstants.COLOR_NOTIFICATION_BACKGROUND};
    color: #fff;
    font-size: 14px;
    padding: 10px 24px;
    text-align: center;
    ${materialUiTheme.breakpoints.down('xs')} {
        padding: 10px 16px;
    }
`;

const BannerContainerAlt = styled(BannerContainer)`
    background-color: ${AppConstants.COLOR_NOTIFICATION_BACKGROUND_ALT};
    color: ${AppConstants.COLOR_NOTIFICATION_ALT};
`;

const CustomIconButton = styled(IconButton)`
    && {
        color: #fff;
        margin: -14px 0 -14px 8px;
    }
`;

class NotificationBanner extends React.Component {
    constructor(props) {
        const {
            appState = props.appState || {},
            t
        } = props;
        super(props);

        this.state = (appState.getNotificationBannerMessage && appState.getNotificationBannerMessage(t)) || {};
    }

    render() {
        const {
            message,
            open,
            type
        } = this.state;

        const {
            location = this.props.location || {},
            t
        } = this.props;

        let showUsageNotice = false;
        const currentPath = location.pathname;
        if (currentPath.startsWith('/usage')) {
            showUsageNotice = true;
        }

        let NotificationIcon;
        switch (type) {
            case BannerNotificationType.CONFIRMATION:
                NotificationIcon = ConfirmIcon;
                break;
            default:
                NotificationIcon = NoticeIcon;
        }

        return (
            <Fragment>
                <StyledCollapse in={open}>
                    <BannerContainer>
                        <NotificationIcon />
                        {message}
                        <CustomIconButton onClick={this.closeNotification}>
                            <CloseIcon />
                        </CustomIconButton>
                    </BannerContainer>
                </StyledCollapse>
                {showUsageNotice === true && (
                    <BannerContainerAlt>
                        {t('usage.inaccurateDataNotice')}
                    </BannerContainerAlt>
                )}
            </Fragment>
        );
    }

    closeNotification = () => {
        this.setState({ open: false });
    }
}

export default connect(mapStateToProps, null, null, { pure: false })(withTranslation()(withRouter(NotificationBanner)));
