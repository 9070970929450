const cacheMixin = superclass =>
    class extends superclass {
        constructor(args) {
            super(args);
            this.cacheMap = new Map();
        }

        /**
         * Returns a promise that intercepts the original getPromise,
         * so that the result can be saved in the cache.
         * @param {string} key
         * @param {Promise} getPromise
         * @returns {Promise} A promise interceptor.
         */
        saveInCache(key, getPromise) {
            return new Promise((resolve, reject) => {
                getPromise
                    .then(response => {
                        this.cacheMap.set(key, response);
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        }

        getFromCache(key) {
            const cachedResponse = this.cacheMap.get(key);
            if (cachedResponse) {
                return new Promise((resolve, _) => {
                    resolve(cachedResponse);
                });
            }

            return null;
        }

        clearCache() {
            this.cacheMap.clear();
        }
    };

export default cacheMixin;
