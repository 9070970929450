import apiMixin from 'api/apiMixin';
import authorizationMixin from 'api/authorizationMixin';
import BillingAddress from 'model/BillingAddress';
import cacheMixin from 'api/cacheMixin';
import NewCardDetails from 'model/NewCardDetails';
import NewCheckDetails from 'model/NewCheckDetails';

export default class FireflyAuthenticatedApi extends authorizationMixin(
    apiMixin(cacheMixin(class {}))
) {
    addAuthorizedUser(emailAddress, memberNumber = null, accountNumber = null) {
        const url = `users/${this.appState.user.id}/authorized-users`;
        return this.postJson(url, {
            target_email: emailAddress,
            member_number: memberNumber,
            account_number: accountNumber
        });
    }

    addPaymentMethodFromCard(
        alias,
        nameOnCard,
        cardNumber,
        expirationMonth,
        expirationYear,
        cvv,
        addressLine1,
        addressLine2,
        city,
        state,
        zip
    ) {
        const billingAddress = new BillingAddress(
            addressLine1,
            addressLine2,
            city,
            state,
            zip
        );

        const newCardDetails = new NewCardDetails(
            nameOnCard,
            cardNumber,
            expirationMonth,
            expirationYear,
            cvv,
            billingAddress
        );

        const request = {
            alias: alias === '' ? null : alias,
            card_details: newCardDetails,
            check_details: null
        };

        const url = `users/${this.appState.user.id}/payment-methods`;
        return this.postJson(url, request);
    }

    addPaymentMethodFromCheck(
        nameOnAccount,
        accountNumber,
        routingNumber,
        accountType,
        alias
    ) {
        const newCheckDetails = new NewCheckDetails(
            nameOnAccount,
            accountNumber,
            routingNumber,
            accountType
        );

        const request = {
            alias: alias === '' ? null : alias,
            check_details: newCheckDetails,
            card_details: null
        };

        const url = `users/${this.appState.user.id}/payment-methods`;
        return this.postJson(url, request);
    }

    confirmSmsVerificationCode(number, code) {
        const url = `users/${this.appState.user.id}/sms-number`;
        return this.postJson(url, {
            phone_number: number,
            verification_code: code
        });
    }

    deleteAutomaticPayment(accountNumber) {
        const url = `accounts/${accountNumber}/automatic-payment`;
        return this.delete(url);
    }

    deletePaymentMethod(id) {
        const url = `users/${this.appState.user.id}/payment-methods/${id}`;
        return this.delete(url);
    }

    getAccountDetails(accountNumber) {
        return this.executeGet(`accounts/${accountNumber}`, {
            fields: 'outage_status'
        });
    }

    getAccountNotificationPreferences(accountNumber) {
        const url = `users/${this.appState.user.id}/accounts/${accountNumber}/notification-preferences`;

        return this.executeGet(url);
    }

    /**
     * Returns temperatures for an account between the specified range
     * (inclusive). Results are provided in the specified interval.
     *
     * @param accountNumber The account number.
     * @param from The start date for temperatures as a dayjs().
     * @param to The end date for temperatures as a dayjs().
     * @param interval The interval (daily or monthly).
     * @returns {Promise} A promise containing an array of temperatures.
     */
    getAccountTemperatures(accountNumber, from, to, interval) {
        const cacheKey = `getAccountTemperatures-${from.toISOString}-${to.toISOString}-${interval}`;
        const cachedResponsePromise = this.getFromCache(cacheKey);
        if (cachedResponsePromise) {
            return cachedResponsePromise;
        }

        const url = `accounts/${accountNumber}/temperatures`;
        const getPromise = this.executeGet(url, {
            from: from.format('YYYY-MM-DD'),
            to: to.format('YYYY-MM-DD'),
            interval: interval
        });

        return this.saveInCache(cacheKey, getPromise);
    }

    getAccountUsage(accountNumber, startDate, endDate, interval) {
        const startDateFormatted = startDate.toISOString();
        const endDateFormatted = endDate.toISOString();

        const cacheKey = `getAccountUsage-${accountNumber}-${startDateFormatted}-${endDateFormatted}-${interval}`;
        const cachedResponsePromise = this.getFromCache(cacheKey);
        if (cachedResponsePromise) {
            return cachedResponsePromise;
        }

        const url = `accounts/${accountNumber}/usage`;
        const getPromise = this.executeGet(url, {
            start_date: startDateFormatted,
            end_date: endDateFormatted,
            interval: interval
        });

        return this.saveInCache(cacheKey, getPromise);
    }

    getAnnouncementNotifications() {
        const url = `users/${this.appState.user.id}/notification-preferences`;

        return this.executeGet(url);
    }

    getAuthorizedUsers() {
        const url = `users/${this.appState.user.id}/authorized-users`;

        return this.executeGet(url);
    }

    /**
     * Returns the billing history for an account between the start and end
     * dates (inclusive).
     *
     * @param accountNumber The account number.
     * @param startDate The start date as a dayjs().
     * @param endDate The end date as a dayjs().
     * @returns {Promise} A promise containing an array of bills.
     */
    getBillingHistory(accountNumber, startDate, endDate) {
        const cacheKey = `getBillingHistory-${accountNumber}-${startDate.toISOString}-${endDate.toISOString}`;
        const cachedResponsePromise = this.getFromCache(cacheKey);
        if (cachedResponsePromise) {
            return cachedResponsePromise;
        }

        const url = `accounts/${accountNumber}/billing-history`;
        const getPromise = this.executeGet(url, {
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD')
        });

        return this.saveInCache(cacheKey, getPromise);
    }

    getNotifications() {
        const url = `users/${this.appState.user.id}/notifications`;

        return this.executeGet(url);
    }

    getOnboardingRequirement(userId) {
        const url = `users/${userId}/onboarding`;

        return this.executeGet(url);
    }

    getPaperlessBillingSetting(memberNumber) {
        const url = `memberships/${memberNumber}/paperless-billing`;
        return this.executeGet(url);
    }

    getPaymentHistory(accountNumber) {
        const url = `accounts/${accountNumber}/payment-history`;
        return this.executeGet(url);
    }

    getPaymentMethods() {
        const url = `users/${this.appState.user.id}/payment-methods`;

        return this.executeGet(url);
    }

    getTapEnrollmentSetting(accountNumber) {
        const url = `accounts/${accountNumber}/tap-enrollment`;

        return this.executeGet(url);
    }

    getUser() {
        return this.executeGet('users/current');
    }

    linkAccount(accountNumber, accountPin, alias) {
        return this.postJson(`users/${this.appState.user.id}/accounts`, {
            account_number: accountNumber,
            pin: accountPin,
            alias: alias
        });
    }

    linkMembership(memberNumber, memberPin, alias) {
        return this.postJson(`users/${this.appState.user.id}/memberships`, {
            member_number: memberNumber,
            pin: memberPin,
            alias: alias
        });
    }

    makePayment(accountAmounts, paymentDetails, paymentDetailsType) {
        const url = `users/${this.appState.user.id}/make-payment`;
        let checkDetails = null;
        let cardDetails = null;
        let savedPayment = null;

        switch (paymentDetailsType) {
            case 'checking':
                checkDetails = paymentDetails;
                break;

            case 'card':
                cardDetails = paymentDetails;
                break;

            case 'saved':
                savedPayment = paymentDetails;
                break;

            default:
                return Promise.reject('Unsupported payment type');
        }

        const requestData = {
            payment_method_id: !savedPayment ? null : savedPayment.id,
            card_details: cardDetails,
            check_details: checkDetails,
            amounts: accountAmounts
        };

        return this.postJson(url, requestData);
    }

    removeAuthorizedUser(authorizedUserId) {
        const url = `users/${this.appState.user.id}/authorized-users/${authorizedUserId}`;
        return this.delete(url);
    }

    removeOnboardingRequirement(userId) {
        const url = `users/${userId}/onboarding`;
        return this.delete(url);
    }

    removePhoneNumber() {
        const url = `users/${this.appState.user.id}/sms-number`;
        return this.delete(url);
    }

    reportOutage(accountNumber) {
        const url = `accounts/${accountNumber}/report-outage`;
        return this.postEmpty(url);
    }

    requestSmsVerificationCode(number) {
        const url = `users/${this.appState.user.id}/sms-code`;

        return this.postJson(url, {
            phone_number: number,
            verification_code: null
        });
    }

    saveAccountAlias(accountNumber, aliasRequest) {
        const url = `users/${this.appState.user.id}/accounts/${accountNumber}`;
        return this.putJson(url, aliasRequest);
    }

    saveAccountNotificationPreferences(accountNumber, preferencesToSave) {
        const url = `users/${this.appState.user.id}/accounts/${accountNumber}/notification-preferences`;
        return this.putJson(url, preferencesToSave);
    }

    saveMembershipAlias(membershipNumber, alias) {
        const url = `users/${this.appState.user.id}/memberships/${membershipNumber}`;
        return this.putJson(url, { alias: alias });
    }

    setupAutomaticPayment(accountNumber, paymentDetails, paymentType) {
        const url = `accounts/${accountNumber}/automatic-payment`;

        let checkDetails = null;
        let cardDetails = null;
        let savedPayment = null;

        switch (paymentType) {
            case 'checking':
                checkDetails = paymentDetails;
                break;

            case 'card':
                cardDetails = paymentDetails;
                break;

            case 'saved':
                savedPayment = paymentDetails;
                break;

            default:
                return Promise.reject('Unsupported payment type');
        }

        const requestData = {
            payment_method_id: !savedPayment ? null : savedPayment.id,
            card_details: cardDetails,
            check_details: checkDetails
        };

        return this.postJson(url, requestData);
    }

    unlinkAccount(accountNumber) {
        const url = `users/${this.appState.user.id}/accounts/${accountNumber}`;

        return this.delete(url);
    }

    unlinkMembership(memberNumber) {
        const url = `users/${this.appState.user.id}/memberships/${memberNumber}`;

        return this.delete(url);
    }

    updateAnnouncementNotifications(push, email) {
        const url = `users/${this.appState.user.id}/notification-preferences`;
        const deliveryMethods = [];

        if (push) {
            deliveryMethods.push('push');
        }

        if (email) {
            deliveryMethods.push('email');
        }

        return this.putJson(url, [
            {
                notification_category: 'announcements',
                selected_delivery_methods: deliveryMethods
            }
        ]);
    }

    updateEmail(oldEmail, newEmail, confirmEmail) {
        const url = `users/${this.appState.user.id}/change-email`;

        return this.postJson(url, {
            old_email: oldEmail,
            new_email: newEmail,
            confirm_email: confirmEmail
        });
    }

    updatePaperlessBillingSetting(memberNumber, preference) {
        const url = `memberships/${memberNumber}/paperless-billing`;
        return this.putJson(url, {
            value: preference
        });
    }

    updatePassword(oldPassword, newPassword, confirmPassword) {
        const url = `users/${this.appState.user.id}/change-password`;

        return this.postJson(url, {
            old_password: oldPassword,
            new_password: newPassword,
            confirm_password: confirmPassword
        });
    }

    updatePaymentMethod(newAlias, paymentId) {
        const url = `users/${this.appState.user.id}/payment-methods/${paymentId}`;
        const request = { alias: newAlias };

        return this.putJson(url, request, true);
    }

    updateProfile(firstName, lastName, newsletter, language) {
        const url = `users/${this.appState.user.id}`;

        return this.putJson(
            url,
            {
                first_name: firstName,
                last_name: lastName,
                language: language,
                newsletter: newsletter
            },
            true
        );
    }

    updateTapEnrollmentSetting(accountNumber, preference) {
        const url = `accounts/${accountNumber}/tap-enrollment`;
        return this.putJson(url, {
            value: preference
        });
    }
}
