import { DialogBasic, DialogConfirm } from 'common/components/dialogs';
import AppConstants from 'AppConstants.js';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import LoadingIndicator from 'common/components/LoadingIndicator';
import { mapStateToProps } from 'reducers';
import { NavButton } from 'common/components/nav-button';
import ProgressButton from 'common/components/ProgressButton';
import React from 'react';
import SettingsPage from 'features/settings/components/SettingsPage';
import styled from 'styled-components';
import { ThemedActionUpper } from 'common/styles';
import { withTranslation } from 'react-i18next';

const SelectAccountFormGroup = styled(FormGroup)`
    margin-bottom: 20px;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
`;

const CancelButton = styled(NavButton)`
    && {
        margin-left: 20px;
    }
`;

const SaveButton = styled(ProgressButton)`
    && {
        width: 100px;
    }
`;

const WhatIsTapLink = styled(ThemedActionUpper)`
    margin-bottom: 30px;
`;

class TapEnrollmentContainer extends ContainerBase {
    constructor(props) {
        const {
            appState,
            dispatch,
            match = props.match || {}
        } = props;

        const memberNumber = Number(match.params && match.params.member_number);
        super(props, {
            loadingTapSettings: true,
            processing: false,
            accountEnrollments: [],
            memberNumber,
            referrer: `/settings/memberships/${memberNumber}`,
            confirmDialogIsOpen: false,
            basicDialogIsOpen: false,
            errorMessageTitle: '',
            errorMessageBody: ''
        });

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });
    }

    componentDidMount() {
        const {
            appState = this.props.appState || {},
            t,
        } = this.props;

        const {
            memberNumber
        } = this.state;
        const promises = [];
        const results = [];

        const sortedAccounts = appState.accountsSorted(t);
        sortedAccounts
            .filter( it => it.memberNumber === memberNumber && it.canGrantToOthers)
            .forEach(it =>
                promises.push({
                    account: it,
                    promise: this.api.getTapEnrollmentSetting(it.accountNumber)
                })
            );

        Promise.all(promises.map(it => it.promise))
            .then(responses => {
                responses.forEach((response, i) => {
                    // sb: not all accounts are eligible for TAP, so only include
                    // the account if it got a successful response from the
                    // server (with the current value of TAP).
                    if (response.value) {
                        results.push({
                            enrolledInTap: response.value,
                            account: promises[i].account
                        });
                    }
                });

                this.setState({
                    accountEnrollments: results,
                    loadingTapSettings: false
                });
            })
            .catch(error => {
                this.setState({ loadingTapSettings: false });
                this.showErrorDialog(error);
            });
    }

    checkboxChange = account => ({ target } = {}) => {
        const { accountEnrollments } = this.state;

        const updated = accountEnrollments || [];

        updated.forEach(it => {
            if (it.account.accountNumber === account.accountNumber) {
                it.enrolledInTap = target.checked;
            }
        });

        this.setState({ accountEnrollments: updated });
    };

    handleOnSubmit = () => {
        const {
            accountEnrollments = this.state.accountEnrollments || []
        } = this.state;

        const atLeastOneSelected = accountEnrollments.some(it => it.enrolledInTap);

        if (!atLeastOneSelected) {
            this.saveTapSelections();
        } else {
            this.showConfirmDialog();
        }
    };

    onConfirmDialogClosed = wasConfirmed => {
        if (wasConfirmed) {
            this.saveTapSelections();
        }
    };

    saveTapSelections = () => {
        const {
            accountEnrollments = this.state.accountEnrollments || [],
            referrer
        } = this.state;

        const {
            history
        } = this.props;

        this.setState({ processing: true });
        const promises = [];

        accountEnrollments.forEach(it =>
            promises.push(
                this.api.updateTapEnrollmentSetting(
                    it.account.accountNumber,
                    it.enrolledInTap
                )
            )
        );

        Promise.all(promises)
            .then(() => {
                this.setState({ processing: false });
                history.push(referrer, { tapEnrollmentSuccess: true });
            })
            .catch(error => {
                this.setState({ processing: false });
                if (
                    error.response.error_code &&
                    error.response.error_code === 'tap_does_not_apply'
                ) {
                    this.setState({
                        errorMessageTitle: 'wizard.tapEnrollment.error.tapDoesNotApply.title',
                        errorMessageBody: 'wizard.tapEnrollment.error.tapDoesNotApply.body'
                    });
                    this.showBasicDialog();
                } else {
                    this.showErrorDialog(error);
                }
            });
    };

    render() {
        const { t } = this.props;

        const {
            accountEnrollments = this.state.accountEnrollments || [],
            basicDialogIsOpen,
            confirmDialogIsOpen,
            errorMessageBody,
            errorMessageTitle,
            loadingTapSettings,
            processing,
            referrer
        } = this.state;

        return (
            <SettingsPage
                title={'wizard.tapEnrollment.header'}
                subtitle={'wizard.tapEnrollment.intro'}
                select="/settings/memberships"
            >
                {loadingTapSettings && <LoadingIndicator />}
                {!loadingTapSettings && (
                    <div>
                        <WhatIsTapLink href={AppConstants.TAP_INFO_URL} target="_blank" rel="noopener noreferrer">
                            {t('wizard.tapEnrollment.tapInfoLink')}
                        </WhatIsTapLink>
                        <SelectAccountFormGroup>
                            {accountEnrollments.map(it => {
                                return (
                                    <FormControlLabel
                                        key={it.account.accountNumber}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={it.enrolledInTap}
                                                onChange={this.checkboxChange(it.account)}
                                                value="true"
                                                disabled={processing}
                                            />
                                        }
                                        label={it.account.getDisplayName(t)}
                                    />
                                );
                            })}
                        </SelectAccountFormGroup>
                        <ButtonRow>
                            <SaveButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                loading={processing}
                                onClick={this.handleOnSubmit}
                            >
                                {t('common.saveButton')}
                            </SaveButton>

                            <CancelButton color="primary" to={referrer} disabled={processing}>
                                {t('common.cancel')}
                            </CancelButton>
                        </ButtonRow>
                        <DialogConfirm
                            container={this}
                            title={t('common.tapTerms.title')}
                            message={t('common.tapTerms.content')}
                            messageIsMarkdown={true}
                            confirmButtonLabel={t('common.agree')}
                            open={confirmDialogIsOpen}
                            onCloseDialog={this.closeConfirmDialog}
                        />
                        <DialogBasic
                            message={t(errorMessageBody)}
                            onCloseDialog={this.closeBasicDialog}
                            open={basicDialogIsOpen}
                            title={t(errorMessageTitle)}
                        />
                    </div>
                )}
            </SettingsPage>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(TapEnrollmentContainer));
