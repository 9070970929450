import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';

const CustomTableRow = styled(TableRow)`
    && {
        vertical-align: top;
    }
`;

const NotificationListItem = styled(ListItem)`
    && {
        align-items: flex-start;
        flex-direction: column;
    }
`;

const NotificationTitle = styled.div`
    font-weight: bold;
`;

const NotificationMessage = styled.div``;

const NotificationTimestamp = styled.div``;

function formatDate(dateString) {
    return new Date(dateString).toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    });
}

function MobileNotificationsTable(props) {
    const {
        notifications = props.notifications || []
    } = props;

    const { t } = useTranslation();

    return (
        <List>
            {notifications.map(notification => {
                const title = t('common.notifications.types.' + notification.type);
                const formattedDate = formatDate(notification.sent_at);

                return (
                    <NotificationListItem key={notification.id} divider={true}>
                        <NotificationTitle>{title}</NotificationTitle>
                        <NotificationMessage>
                            <Markdown>{notification.message}</Markdown>
                        </NotificationMessage>
                        <NotificationTimestamp>
                            {formattedDate}
                        </NotificationTimestamp>
                    </NotificationListItem>
                );
            })}
        </List>
    );
}

MobileNotificationsTable.propTypes = {
    notifications: PropTypes.array.isRequired
};

function NotificationsTable(props) {
    const {
        notifications = props.notifications || []
    } = props;

    const { t } = useTranslation();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>{t('notifications.headings.title')}</TableCell>
                    <TableCell>{t('notifications.headings.message')}</TableCell>
                    <TableCell align="right">{t('common.headings.date')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {notifications.map(notification => {
                    const { t } = props;
                    const title = t('common.notifications.types.' + notification.type);
                    const formattedDate = formatDate(notification.sent_at);

                    return (
                        <CustomTableRow key={notification.id}>
                            <TableCell>
                                <strong>{title}</strong>
                            </TableCell>
                            <TableCell>
                                <Markdown>{notification.message}</Markdown>
                            </TableCell>
                            <TableCell align="right">{formattedDate}</TableCell>
                        </CustomTableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}

NotificationsTable.propTypes = {
    notifications: PropTypes.array.isRequired
};

export default NotificationsTable;
export { MobileNotificationsTable };
