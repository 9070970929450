import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemedTextField } from 'common/styles';

function CvvCustomFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <Cleave
            {...other}
            htmlRef={inputRef}
            options={{blocks: [4], numericOnly: true}}
            onChange={onChange}
        />
    );
}

CvvCustomFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function CvvField(props) {
    return (
        <ThemedTextField
            {...props}
            type="text"
            InputProps={{ inputComponent: CvvCustomFormat }}
        />
    );
}
