import { SingleColumnForm, ThemedPageHeader, ThemedTextField } from 'common/styles';
import { changeEmail } from 'actions';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import ErrorMessage from 'common/components/ErrorMessage';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import LeftNav from 'common/components/LeftNav';
import { mapStateToProps } from 'reducers';
import { NavButton } from 'common/components/nav-button';
import { navigations } from 'features/settings/containers/SettingsContainer';
import Page from 'common/components/Page';
import ProgressButton from 'common/components/ProgressButton';
import React from 'react';
import styled from 'theme';
import Validator from 'validation/Validator';
import { withTranslation } from 'react-i18next';

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
`;
const CancelButton = styled(NavButton)`
    && {
        margin-left: 20px;
    }
`;
const SaveButton = styled(ProgressButton)`
    && {
        width: 100px;
    }
`;

class ChangeEmailContainer extends ContainerBase {
    constructor(props) {
        const {
            appState = props.appState || {}
        } = props;

        super(props, {
            newEmail: '',
            newEmailError: null,
            confirmEmail: '',
            confirmEmailError: null
        });
        const user = appState.user || {};
        this.state.currentEmail = user.email;

        this.validator = new Validator(this);
    }

    componentDidMount() {
        this.validator.maybeFocus();
    }

    componentDidUpdate() {
        this.validator.maybeFocus();
    }

    render() {
        const {
            t
        } = this.props;

        const {
            confirmEmail,
            confirmEmailError,
            currentEmail,
            loading,
            newEmail,
            newEmailError,
        } = this.state;

        return (
            <Page>
                <LeftNav title="settings.header" to="/settings" navigations={navigations} select="/settings/user-profile">
                    <SingleColumnForm>
                        <ThemedPageHeader>
                            {t('settings.changeEmail.header')}
                        </ThemedPageHeader>

                        <form onSubmit={this.submit}>
                            <ThemedTextField
                                id="currentEmail"
                                type="email"
                                label={t(
                                    'settings.changeEmail.currentEmailLabel'
                                )}
                                disabled={true}
                                value={currentEmail}
                            />

                            <ThemedTextField
                                id="newEmail"
                                inputRef={this.newEmailFocus}
                                type="email"
                                label={t('settings.changeEmail.newEmailLabel')}
                                disabled={loading}
                                value={newEmail}
                                onChange={this.handleInputChange('newEmail')}
                                error={newEmailError !== null}
                            />
                            <ErrorMessage resourceId={newEmailError} />

                            <ThemedTextField
                                id="confirmEmail"
                                inputRef={this.confirmEmailFocus}
                                type="email"
                                label={t('settings.changeEmail.confirmEmailLabel')}
                                disabled={loading}
                                value={confirmEmail}
                                onChange={this.handleInputChange('confirmEmail')}
                                error={confirmEmailError !== null}
                            />
                            <ErrorMessage resourceId={confirmEmailError}/>

                            <ButtonRow>
                                <SaveButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    loading={loading}
                                    onClick={this.submit}
                                >
                                    {t('common.saveButton')}
                                </SaveButton>

                                <CancelButton color="primary" disabled={loading} to="/settings/user-profile">
                                    {t('common.cancel')}
                                </CancelButton>
                            </ButtonRow>
                        </form>
                    </SingleColumnForm>
                </LeftNav>
            </Page>
        );
    }

    handleInputChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.value });
    }

    configureValidations(config) {
        config.requireEmail('newEmail');
        config.requireConfirmEmail('confirmEmail', 'newEmail');
    }

    submit = e => {
        const {
            appState,
            dispatch,
            history
        } = this.props;

        const {
            confirmEmail,
            currentEmail,
            newEmail,
        } = this.state;

        e.preventDefault();

        if (!this.validator.validate()) {
            return;
        }

        this.setState({ loading: true });
        const api = new FireflyAuthenticatedApi({ appState, dispatch});
        api.updateEmail(currentEmail, newEmail, confirmEmail)
            .then(() => {
                dispatch(changeEmail(newEmail));
                history.push('/settings/user-profile', { showChangeEmailSuccessSnackbar: true });
            })
            .catch(error => {
                switch (error.status) {
                    case 422:
                        if (
                            error.response.errors &&
                            error.response.errors[0].field === 'new_email' &&
                            error.response.errors[0].error_code ===
                                'already_exists'
                        ) {
                            this.showFieldError('newEmail', 'createUser.error.alreadyRegistered');
                        } else {
                            this.setState({ loading: false });
                            this.showErrorDialog(error);
                        }
                        break;
                    default:
                        this.setState({ loading: false });
                        this.showErrorDialog(error);
                }
            });
    }
}

export default connect(mapStateToProps)(withTranslation()(ChangeEmailContainer));
