import { ThemedListHeader, ThemedPageHeader } from 'common/styles';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import LeftNav from 'common/components/LeftNav';
import { mapStateToProps } from 'reducers';
import { navigations } from 'features/billing/navigations';
import Page from 'common/components/Page';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'theme';
import { withTranslation } from 'react-i18next';

const PageContent = styled.div`
    display: flex;
    flex-direction: column;
`;
class BillingPage extends ContainerBase {
    render() {
        const {
            indentedHeader,
            select,
            t,
            title
        } = this.props;

        return (
            <Page padding="none">
                <LeftNav title="billing.header" to="/billing" select={select} navigations={navigations}>
                    <PageContent indentedHeader={indentedHeader}>
                        {indentedHeader && (
                            <ThemedListHeader>{t(title)}</ThemedListHeader>
                        )}
                        {!indentedHeader && (
                            <ThemedPageHeader>{t(title)}</ThemedPageHeader>
                        )}

                        {this.props.children}
                    </PageContent>
                </LeftNav>
            </Page>
        );
    }
}

BillingPage.propTypes = {
    title: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    select: PropTypes.string,
    indentedHeader: PropTypes.bool.isRequired,
    isRoot: PropTypes.bool
};

BillingPage.defaultProps = {
    isRoot: false
};

export default connect(mapStateToProps)(withTranslation()(BillingPage));
