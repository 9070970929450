import styled, { materialUiTheme } from 'theme';
import AppConstants from 'AppConstants.js';
import React from 'react';
import { withRouter } from 'react-router-dom';

const RootDiv = styled.div`
    background-color: ${AppConstants.COLOR_ROOT_CONTAINER_BACKGROUND};
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Soleil, sans-serif;
    padding-top: ${AppConstants.APP_BAR_HEIGHT_DESKTOP}px;
    ${materialUiTheme.breakpoints.down('sm')} {
        padding-top: ${AppConstants.APP_BAR_HEIGHT_MOBILE}px;
    }
`;
/*
 * This comoponent takes care of auto scrolling to the top of
 * the page whenever the application transitions to a new location.
 */
class Root extends React.Component {
    componentDidUpdate(prevProps) {
        const { location } = this.props;

        if (location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        const { children } = this.props;

        return <RootDiv>{children}</RootDiv>;
    }
}

export default withRouter(Root);
