import AppConstants from 'AppConstants.js';
import React from 'react';
import styled from 'theme';
import { ThemedActionUpper } from 'common/styles';

const Link = styled(ThemedActionUpper)`
    color: ${props => props.textcolor};
`;

export class OutageStatuses {
    static UNKNOWN = 'unknown'
    static ASSUMED = 'assumed'
    static CONFIRMED = 'confirmed'
    static PLANNED = 'planned'
}

const OutageStatus = ({ account, t, onclick, className }) => {
    if (!(account.type === 'electric' || account.type === 'water')) {
        return null;
    }

    let outageText;
    let textColor;

    switch (account.outageStatus) {
        case OutageStatuses.ASSUMED:
        case OutageStatuses.CONFIRMED:
        case OutageStatuses.PLANNED:
            outageText = t('outage.outageReported');
            textColor = AppConstants.COLOR_OUTAGE_REPORTED;
            break;
        default:
            outageText = t('outage.reportOutage');
            textColor = AppConstants.COLOR_PRIMARY;
            break;
    }

    if (account.disconnected) {
        outageText = t('outage.disconnected');
        textColor = AppConstants.COLOR_ERROR;
    }

    return (
        <Link
            className={className}
            textcolor={textColor}
            href="#"
            onClick={e => onclick(e, account)}
        >
            {outageText}
        </Link>
    );
};

export default OutageStatus;
