import React, { useState } from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppConstants from 'AppConstants.js';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { connect } from 'react-redux';
import Grow from '@material-ui/core/Grow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { logout } from 'actions';
import { mapStateToProps } from 'reducers';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import PropTypes from 'prop-types';
import styled from 'theme';
import styles from './ProfileMenu.module.css';
import { ThemedMenuItem } from 'common/styles';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

const LogOut = styled(ThemedMenuItem)`
    min-width: 220px;
    && {
        border-top: solid 1px ${AppConstants.COLOR_DIVIDER};
    }
`;

const ProfileMenu = (props) => {
    const {
        appState = props.appState || {},
        dispatch,
        history
    } = props;

    const [ open, setOpen ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState();
    const { t } = useTranslation();
    const user = appState.user || {};

    const handleToggle = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(state => !state.open);
    };

    const handleClose = ({ target } = {}) => {
        if (target.id) {
            selectMenu(target.id);
        }
        setOpen(false);
    };

    const selectMenu = targetId => {
        switch (targetId) {
            case 'profile':
                history.push('/settings/user-profile');
                break;
            case 'settings':
                history.push('/settings');
                break;
            case 'logout':
                dispatch(logout());
                history.push('/');
                break;
            default:
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div className={styles.container}>
                <Button
                    className={styles.userProfileButton}
                    aria-controls="profile-menu"
                    aria-haspopup="true"
                    aria-label={t('common.aria.profileMenu')}
                    id="profile-button"
                    onClick={handleToggle}
                    color="primary"
                >
                    <AccountCircleIcon className={styles.accountCircle} />
                    {open ? (
                        <KeyboardArrowUpIcon className={styles.chevronUp}/>
                    ) : (
                        <KeyboardArrowDownIcon className={styles.chevronDown}/>
                    )}
                </Button>
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    transition={true}
                    disablePortal={true}
                    placement="bottom-end"
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="profile-menu"
                            role="menu"
                            aria-labelledby="profile-button"
                            style={{
                                transformOrigin:
                                    placement === 'bottom-end'
                                        ? 'center top'
                                        : 'right bottom'
                            }}
                        >
                            <Paper elevation={2}>
                                <MenuList
                                    subheader={
                                        <div className={styles.profileItem}>
                                            <div className={styles.name}>
                                                {user.fullName}
                                            </div>
                                            <div className={styles.userId}>
                                                {user.email}
                                            </div>
                                        </div>
                                    }
                                >
                                    <ThemedMenuItem onClick={handleClose} id="profile">
                                        {t('appbar.profile')}
                                    </ThemedMenuItem>
                                    <ThemedMenuItem onClick={handleClose} id="settings">
                                        {t('appbar.settings')}
                                    </ThemedMenuItem>
                                    <LogOut onClick={handleClose} id="logout">
                                        {t('appbar.logOut')}
                                    </LogOut>
                                </MenuList>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

ProfileMenu.propTypes = {
    appState: PropTypes.object,
    dispatch: PropTypes.func,
    history: PropTypes.object,
    t: PropTypes.func,
};

const ProfileMenuWithRoute = withRouter(ProfileMenu);
export default connect(mapStateToProps)(ProfileMenuWithRoute);
