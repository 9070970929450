import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import GridContainer from 'common/components/GridContainer';
import GridItem from 'common/components/GridItem';
import Hidden from '@material-ui/core/Hidden';
import { mapStateToProps } from 'reducers';
import Page from 'common/components/Page';
import React from 'react';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import styled from 'theme';
import { updateWizardContext } from 'actions';
import { withTranslation } from 'react-i18next';
import { WizardRoutes } from 'common/components/Routes';

const StepperContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StepperFullWidth = styled(Stepper)`
    width: 100%;
    margin-top: 40px;
    margin-bottom: 10px;
`;

const MobileRouteContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 16px;
`;

class WizardStepperContainer extends ContainerBase {
    render() {
        const {
            appState = this.props.appState || {},
            t
        } = this.props;

        const wizardContext = appState.wizardContext || {};
        const activeStep = wizardContext.currentStepIndex;
        const steps = wizardContext.steps || [];

        return (
            <Page padding="container">
                {/* Horizontal stepper for desktop */}
                <Hidden smDown={true}>
                    <StepperContainer>
                        <StepperFullWidth activeStep={activeStep} alternativeLabel={true}>
                            {steps.map((step, index) => {
                                const isComplete = this.isStepComplete(wizardContext, index);
                                return (
                                    <Step key={step.name} completed={isComplete}>
                                        <StepLabel>{t(step.name)}</StepLabel>
                                    </Step>
                                );
                            })}
                        </StepperFullWidth>
                        <GridContainer>
                            <Hidden smDown={true}>
                                <GridItem xs={3} lg={4} />
                            </Hidden>
                            <GridItem xs={6} lg={4}>
                                <WizardRoutes onContinue={this.onContinue} wizardContext={wizardContext} />
                            </GridItem>
                        </GridContainer>
                    </StepperContainer>
                </Hidden>
                {/* Vertical stepper for tablet */}
                <Hidden mdUp={true} xsDown={true}>
                    <div>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => {
                                const isComplete = this.isStepComplete(wizardContext, index);
                                return (
                                    <Step key={step.name + 'tablet'} completed={isComplete}>
                                        <StepLabel>{t(step.name)}</StepLabel>
                                        <StepContent>
                                            <WizardRoutes
                                                onContinue={this.onContinue}
                                                wizardContext={wizardContext}
                                                orientation="vertical"
                                            />
                                        </StepContent>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </div>
                </Hidden>
                {/* No stepper for phone */}
                <Hidden smUp={true}>
                    <MobileRouteContainer>
                        <WizardRoutes
                            onContinue={this.onContinue}
                            wizardContext={wizardContext}
                            orientation="phone"
                        />
                    </MobileRouteContainer>
                </Hidden>
            </Page>
        );
    }

    isStepComplete(wizardContext, index) {
        let isComplete = false;
        const isOnLastStep = index === wizardContext.steps.length - 1;

        if (
            index < wizardContext.currentStepIndex ||
            (index === wizardContext.currentStepIndex && isOnLastStep)
        ) {
            isComplete = true;
        }

        return isComplete;
    };

    onContinue = () => {
        const {
            appState = this.props.appState || {},
            dispatch,
            history,
            location
        } = this.props;

        const wizardContext = appState.wizardContext || {};
        const nextStep = wizardContext.next(location);
        dispatch(updateWizardContext(wizardContext));
        history.push(nextStep.path);
    };
}

export default connect(mapStateToProps)(withTranslation()(WizardStepperContainer));
