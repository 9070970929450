import React, { Fragment, useState } from 'react';
import styled, { materialUiTheme } from 'theme/index';
import AppConstants from 'AppConstants.js';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import { ClickAwayListener } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Switch from '@material-ui/core/Switch';
import { useTranslation } from 'react-i18next';

export const timeScale = {
    DAILY: 'daily',
    MONTHLY: 'monthly',
    YEARLY: 'yearly'
};

const Drawer = styled(Card)`
    && {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 320px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 70px 24px 24px 32px;
    }
`;

const ControlLabelSpan = styled.span`
    font-size: 16px;
`;

const SectionLabel = styled.span`
    font-size: 12px;
    margin-top: 16px;
    color: ${AppConstants.COLOR_TEXT_SUB};
`;

const RadioStyled = styled(Radio)`
    && {
        height: 40px;
    }
`;

const AccountSelectContainer = styled.div`
    height: 220px;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    ${materialUiTheme.breakpoints.down('xs')} {
        height: 190px;
    }
`;

const BillingAccountSelectContainer = styled(AccountSelectContainer)`
    height: 370px;
    ${materialUiTheme.breakpoints.down('xs')} {
        height: 340px;
    }
`;

const CheckboxStyled = styled(Checkbox)`
    && {
        height: 40px;
    }
`;

const ButtonRow = styled.div`
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
`;

const UpdateButton = styled(Button)`
    && {
        width: 140px;
    }
`;

const ControlLabel = props => {
    const { label, ...rest } = props;
    return (
        <FormControlLabel
            {...rest}
            label={<ControlLabelSpan>{label}</ControlLabelSpan>}
        >
            {rest.children}
        </FormControlLabel>
    );
};

/**
 * Renders the settings drawer that appears when clicking on the cog wheel of a
 * chart.
 */
const ChartSettingsDrawer = React.forwardRef((props, ref) => {
    const {
        accounts = props.accounts || [],
        onClose,
        onUpdate,
        selectedAccounts = props.selectedAccounts || [],
        showTemperatureData,
        supportsTimeScale,
        timeScale
    } = props;

    // this holds an array of graphable accounts and their selection state.
    const [accountsSelectionState, setAccountsSelectionState] = useState(accounts.map(account => {
        const selectedIndex = selectedAccounts.findIndex(it => it.accountNumber === account.accountNumber);
        return {
            account,
            isSelected: selectedIndex >= 0
        };
    }));

    const [isTemperatureDisplayed, setIsTemperatureDisplayed] = useState(showTemperatureData);
    const [selectedTimeScale, setSelectedTimeScale] = useState(timeScale);

    const { t } = useTranslation();

    const toggleShowTemperature = () => {
        setIsTemperatureDisplayed(!isTemperatureDisplayed);
    };

    const handleTimeScaleChange = ({ target } = {}) => {
        setSelectedTimeScale(target.value);
    };

    const handleAccountSelect = (selectedAccount, i) => () => {
        const newAccountsSelectionState = [...accountsSelectionState];
        newAccountsSelectionState[i].isSelected = !selectedAccount.isSelected;

        setAccountsSelectionState(newAccountsSelectionState);
    };

    const onUpdateInternal = () => {
        const newSelectedAccounts = accountsSelectionState
            .filter(it => it.isSelected)
            .map(it => it.account);

        onUpdate(isTemperatureDisplayed, selectedTimeScale, newSelectedAccounts);
    };

    const filteredAccounts = accountsSelectionState.filter(it => it.isSelected);
    const maxAccountsReached = filteredAccounts.length >= 4;
    const AccountSelect = supportsTimeScale
        ? AccountSelectContainer
        : BillingAccountSelectContainer;

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Drawer ref={ref}>
                <ControlLabel
                    control={
                        <Switch checked={isTemperatureDisplayed} onChange={toggleShowTemperature} color="primary" />
                    }
                    label={t('common.charting.settingsDrawer.showTemperatureData')}
                />

                {supportsTimeScale ? (
                    <Fragment>
                        <SectionLabel>
                            {t('common.charting.settingsDrawer.timeScale')}
                        </SectionLabel>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="Time Scale"
                                name="timeScale"
                                value={selectedTimeScale}
                                onChange={handleTimeScaleChange}
                            >
                                <ControlLabel
                                    value={'daily'}
                                    control={<RadioStyled color="primary" />}
                                    label={t('common.charting.settingsDrawer.daily')}
                                />
                                <ControlLabel
                                    value={'monthly'}
                                    control={<RadioStyled color="primary" />}
                                    label={t('common.charting.settingsDrawer.monthly')}
                                />
                                <ControlLabel
                                    value={'yearly'}
                                    control={<RadioStyled color="primary" />}
                                    label={t('common.charting.settingsDrawer.yearly')}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Fragment>
                ) : (
                    <Fragment />
                )}

                <SectionLabel>
                    {t('common.charting.settingsDrawer.accountsToInclude')}
                </SectionLabel>
                <AccountSelect>
                    {accountsSelectionState.map((selectedAccount, i) => {
                        return <div key={i}>
                            <ControlLabel
                                control={
                                    <CheckboxStyled
                                        color="primary"
                                        checked={selectedAccount.isSelected}
                                        disabled={!selectedAccount.isSelected && maxAccountsReached}
                                        onChange={handleAccountSelect(selectedAccount, i)}
                                        value="true"
                                    />
                                }
                                label={selectedAccount.account.getDisplayName(t)}
                            />
                        </div>;
                    })}
                </AccountSelect>
                <ButtonRow>
                    <UpdateButton onClick={onUpdateInternal} color="primary" variant="contained">
                        {t('common.update')}
                    </UpdateButton>
                </ButtonRow>
            </Drawer>
        </ClickAwayListener>
    );
});

ChartSettingsDrawer.propTypes = {
    /**
     * An array of all of a user's accounts.
     */
    accounts: PropTypes.array.isRequired,

    /**
     * The function to execute when the drawer is closed.
     */
    onClose: PropTypes.func.isRequired,

    /**
     * The function to execute when the user updates the settings in the drawer.
     */
    onUpdate: PropTypes.func.isRequired,

    /**
     * The initial accounts to graph.
     */
    selectedAccounts: PropTypes.array.isRequired,

    /**
     * The initial state of the temperature display.
     */
    showTemperatureData: PropTypes.bool.isRequired,

    /**
     * Indicates whether the drawer supports daily/monthly/yearly time scales.
     */
    supportsTimeScale: PropTypes.bool.isRequired,

    /**
     * The initial time scale.
     */
    timeScale: PropTypes.string.isRequired
};

ChartSettingsDrawer.defaultProps = {
    showTemperatureData: true,
    supportsTimeScale: true,
    timeScale: timeScale.MONTHLY
};

export default ChartSettingsDrawer;
