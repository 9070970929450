import CardTextFields, { cardInputFieldNames } from '../components/CardTextFields';
import { addPaymentMethodFromCard } from '../addPaymentMethod';
import BillingPage from '../components/BillingPage';
import { connect } from 'react-redux';
import ContainerBase from '../../../common/containers/ContainerBase';
import FireflyAuthenticatedApi from '../../../api/FireflyAuthenticatedApi';
import { mapStateToProps } from '../../../reducers';
import { NavButton } from '../../../common/components/nav-button';
import ProgressButton from '../../../common/components/ProgressButton';
import React from 'react';
import { SingleColumnForm } from '../../../common/styles';
import styled from 'styled-components';
import Validator from '../../../validation/Validator';
import { withTranslation } from 'react-i18next';

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
`;
const CancelButton = styled(NavButton)`
    && {
        margin-left: 20px;
    }
`;
const SaveButton = styled(ProgressButton)`
    && {
        width: 100px;
    }
`;

class AddCardContainer extends ContainerBase {
    constructor(props) {
        const {
            appState,
            dispatch
        } = props;

        super(props, {
            ...cardInputFieldNames,
            processing: false
        });

        this.api = new FireflyAuthenticatedApi({ appState, dispatch });

        this.validator = new Validator(this);
    }

    configureValidations(config) {
        config.requireNotEmpty('nameOnCard');
        config.requireCreditCard('creditCard');
        config.requireExpirationDate('expirationDate');
        config.requireCvv('cvv', 'creditCard');
        config.requireNotEmpty('addressLine1');
        config.requireNotEmpty('city');
        config.requireNotEmpty('state');
        config.requireNotEmpty('zip');
    }

    componentDidMount() {
        this.validator.maybeFocus();
    }

    componentDidUpdate() {
        this.validator.maybeFocus();
    }

    handleOnSubmit = () => {
        if (!this.validator.validate()) {
            return;
        }

        addPaymentMethodFromCard(this).then(() => {
            const { history } = this.props;
            history.push('/billing/payment-methods', { showAddPaymentMethodSnackbar: true });
        });
    }

    render() {
        const { t } = this.props;
        const { processing } = this.state;

        return (
            <BillingPage title={'billing.addCard.header'} select="/billing/payment-methods" indentedHeader={false}>
                <SingleColumnForm>
                    <CardTextFields parent={this} t={t} alwaysShowAlias={true} showSaveCheckbox={false} />
                    <ButtonRow>
                        <SaveButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={this.handleOnSubmit}
                            loading={processing}
                        >
                            {t('common.saveButton')}
                        </SaveButton>

                        <CancelButton color="primary" to="/billing/payment-methods" disabled={processing}>
                            {t('common.cancel')}
                        </CancelButton>
                    </ButtonRow>
                </SingleColumnForm>
            </BillingPage>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(AddCardContainer));
