import ChartDataTabs, { supportedChartingViews, supportedTabs } from 'common/components/charting/ChartDataTabs';
import AccountUsageDataTable from 'common/components/charting/AccountUsageDataTable';
import BarChart from 'common/components/charting/BarChart';
import ChartIcon from '../../../common/components/ChartIcon';
import ChartStatistics from 'common/components/charting/ChartStatistics';
import ContainerBase from 'common/containers/ContainerBase';
import DataIcon from '../../../common/components/DataIcon';
import dayjs from 'dayjs';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import LeftNav from 'common/components/LeftNav';
import { navigations } from 'features/usage/navigations';
import Page from 'common/components/Page';
import PieChart from 'common/components/charting/PieChart';
import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { timeScale } from 'common/components/charting/ChartSettingsDrawer';
import UsageBarChartAdapter from 'common/charting/UsageBarChartAdapter';
import UsagePieChartAdapter from 'common/charting/UsagePieChartAdapter';

/**
 * Base container object for all usage charting containers.
 * @param accountType The account type ('electric' or 'water').
 * @param units Metric unit ('kwh' or 'gallons').
 */
export default class UsageContainerBase extends ContainerBase {
    constructor(props, accountType, units) {
        super(props, {
            currentView: supportedChartingViews.chartBar,
            showTemperatureData: true,
            timeScale: timeScale.MONTHLY,
            barChartSeries: [],
            barChartXAxis: [],
            barTemperatures: [],
            chartIsLoading: true,
            pieChartLegend: [],
            pieChartSeries: [],
            totalAverage: 0,
            highTemperatureAverage: 0,
            lowTemperatureAverage: 0,
            chartVersion: 0,
            dataIsLoading: true,
            dataSelectedAccountUsage: [],
            accountType: accountType,
            units: units,
            selectedTab: supportedTabs.chartTab
        });

        const {
            appState = props.appState || {},
            dispatch,
            t
        } = props;

        const sortedAccounts = (appState.accountsSorted &&  appState.accountsSorted(t)) || [];
        const filteredAccounts = sortedAccounts.filter(account => account.type === accountType);

        this.filteredNavigations = navigations.filter(navigation =>
            appState.hasAccountType && appState.hasAccountType(navigation.type)
        );
        this.state.accounts = filteredAccounts;
        this.state.selectedAccounts = filteredAccounts.slice(0, 4);
        this.state.dataSelectedAccount = filteredAccounts[0];
        this.api = new FireflyAuthenticatedApi({ appState, dispatch});
    }

    componentDidMount() {
        const {
            selectedAccounts,
            timeScale
        } = this.state;

        this.getUsageAndDrawCharts(selectedAccounts, timeScale);
    }

    render() {
        const {
            classes = this.props.classes || {},
            t
        } = this.props;

        const {
            accounts,
            accountType,
            barChartXAxis,
            barChartSeries,
            barTemperatures,
            chartIsLoading,
            chartVersion,
            currentView,
            dataIsLoading,
            dataSelectedAccountUsage,
            highTemperatureAverage,
            lowTemperatureAverage,
            pieChartLegend = this.state.pieChartLegend || [],
            pieChartSeries = this.state.pieChartSeries || [],
            selectedAccounts,
            selectedTab,
            showTemperatureData,
            timeScale,
            totalAverage,
            units
        } = this.state;

        const xAxis = barChartXAxis;
        const xAxisStart = xAxis.length >= 30 ? xAxis[xAxis.length - 30] : null;
        const translatedAverage = t(`usage.units.${units}`, { value: parseInt(totalAverage, 10).toLocaleString() });
        const temperatureAverages = `${highTemperatureAverage}° / ${lowTemperatureAverage}°`;

        const tabs = (
            <Tabs
                value={selectedTab}
                onChange={this.onChangeTab}
                classes={{
                    root: classes.tabsRoot,
                    indicator: classes.tabsIndicator
                }}
            >
                <Tab
                    value={supportedTabs.chartTab}
                    icon={<ChartIcon t={t} />}
                    classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected
                    }}
                />
                <Tab
                    value={supportedTabs.dataTab}
                    icon={<DataIcon t={t} />}
                    classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected
                    }}
                />
            </Tabs>
        );

        const stats = () =>
            chartIsLoading ? (
                <ChartStatistics
                    description1={t(`common.charting.chartStatistics.${timeScale}Average`)}
                    metric1="--"
                    description2={t('common.charting.chartStatistics.temperatureAverage')}
                    metric2="-- / --"
                />
            ) : (
                <ChartStatistics
                    description1={t(`common.charting.chartStatistics.${timeScale}Average`
                    )}
                    metric1={translatedAverage}
                    description2={t('common.charting.chartStatistics.temperatureAverage')}
                    metric2={temperatureAverages}
                />
            );

        return (
            <Page padding="none">
                <LeftNav
                    title="usage.header"
                    to="/usage"
                    navigations={this.filteredNavigations}
                    padding="none"
                    reverseBackground={true}
                    leftDesktopContent={stats}
                >
                    <ChartDataTabs
                        title={t(`usage.${accountType}.${timeScale}Title`)}
                        accounts={accounts}
                        onSelectAccount={this.onSelectAccount}
                        onChangeView={this.onChangeView}
                        topPhoneContent={stats}
                        showTemperatureData={showTemperatureData}
                        timeScale={timeScale}
                        selectedAccounts={selectedAccounts}
                        onSettingsUpdated={this.onSettingsUpdated}
                        tabs={tabs}
                        selectedChartView={currentView}
                        selectedTab={selectedTab}
                    >
                        {
                            selectedTab === supportedTabs.chartTab
                            && currentView === supportedChartingViews.chartBar
                            && <BarChart
                                key={chartVersion}
                                series={barChartSeries}
                                xaxis={barChartXAxis}
                                xaxisStart={xAxisStart}
                                temperatureSeries={barTemperatures}
                                showTemperature={showTemperatureData}
                                units={units}
                                isLoading={chartIsLoading}
                            />
                        }
                        {
                            selectedTab === supportedTabs.chartTab
                            && currentView === supportedChartingViews.chartPie
                            && <PieChart
                                key={chartVersion}
                                series={pieChartSeries.map(
                                    (value, i) => ({
                                        name: pieChartLegend[i],
                                        value: value
                                    })
                                )}
                                calloutValue={totalAverage}
                                calloutDescription={t(`common.charting.pieChart.${timeScale}AverageUsage`)}
                                isLoading={chartIsLoading}
                            />
                        }
                        {
                            selectedTab === supportedTabs.dataTab
                            && <AccountUsageDataTable
                                interval={timeScale}
                                loading={dataIsLoading}
                                accountData={dataSelectedAccountUsage}
                            />
                        }
                    </ChartDataTabs>
                </LeftNav>
            </Page>
        );
    }

    /*
     * This is called within the data tab only
     */
    onSelectAccount = selectedAccount => {
        this.setState({ dataIsLoading: true });
        this.getUsageAndShowData(selectedAccount);
    }

    onChangeTab = (event, selectedTab) => {
        const {
            dataIsLoading,
            dataSelectedAccount
        } = this.state;

        this.setState({ selectedTab: selectedTab });

        if (selectedTab === supportedTabs.dataTab && dataIsLoading) {
            this.getUsageAndShowData(dataSelectedAccount);
        }
    }

    onChangeView = chartingView => {
        const { chartVersion } = this.state;

        const barChartVersion =
            chartingView === supportedChartingViews.chartBar
                ? chartVersion + 1
                : chartVersion;

        this.setState({
            currentView: chartingView,
            chartVersion: barChartVersion
        });
    }

    /*
     * This is called within the chart tab only
     */
    onSettingsUpdated = (
        showTemperatureData,
        selectedTimeScale,
        nextSelectedAccounts = []
    ) => {
        const {
            dataIsLoading,
            selectedAccounts = this.state.selectedAccounts || [],
            timeScale
        } = this.state;

        // Require new data tab load if time scale changes.
        const newDataIsLoading = selectedTimeScale !== timeScale
            ? true
            : dataIsLoading;

        let requiresNewFetch = false;
        if (nextSelectedAccounts.length !== selectedAccounts.length || selectedTimeScale !== timeScale) {
            requiresNewFetch = true;
        } else {
            for (let i = 0; i < nextSelectedAccounts.length; i++) {
                if (selectedAccounts[i].accountNumber !== nextSelectedAccounts[i].accountNumber) {
                    requiresNewFetch = true;
                    break;
                }
            }
        }

        if (requiresNewFetch) {
            this.setState(state => ({
                showTemperatureData: showTemperatureData,
                timeScale: selectedTimeScale,
                selectedAccounts: nextSelectedAccounts,
                barChartSeries: [],
                pieChartSeries: [],
                chartIsLoading: true,
                chartVersion: state.chartVersion + 1,
                dataIsLoading: newDataIsLoading
            }));
            this.getUsageAndDrawCharts(nextSelectedAccounts, selectedTimeScale);
        } else {
            this.setState(state => ({
                showTemperatureData: showTemperatureData,
                timeScale: selectedTimeScale,
                selectedAccounts: nextSelectedAccounts,
                chartVersion: state.chartVersion + 1,
                dataIsLoading: newDataIsLoading
            }));
        }
    }

    getUsageAndDrawCharts(selectedAccounts = [], selectedTimeScale) {
        const { t } = this.props;
        const { chartVersion } = this.state;

        if (selectedAccounts === undefined || selectedAccounts.length === 0) {
            this.setState({ chartIsLoading: false });
            return;
        }

        const timeParameters = this.getTimeParameters(selectedTimeScale);

        let temperaturePromise = null;
        if (selectedAccounts.length > 0) {
            temperaturePromise = this.api.getAccountTemperatures(
                selectedAccounts[0].accountNumber,
                timeParameters.temperatureFrom,
                timeParameters.temperatureTo,
                timeParameters.temperatureInterval
            );
        }

        const usagePromises = selectedAccounts.map(it =>
            this.api.getAccountUsage(
                it.accountNumber,
                timeParameters.startDate,
                timeParameters.endDate,
                timeParameters.interval
            )
        );

        Promise.all([temperaturePromise, ...usagePromises])
            .then(temperatureAndUsageResponse => {
                const barChartData = UsageBarChartAdapter.adapt(
                    selectedAccounts,
                    temperatureAndUsageResponse,
                    t,
                    timeParameters.startDate,
                    timeParameters.endDate,
                    selectedTimeScale
                );

                const pieChartData = UsagePieChartAdapter.adapt(
                    temperatureAndUsageResponse
                );

                this.setState({
                    chartVersion: chartVersion + 1,
                    barChartSeries: barChartData.series,
                    barChartXAxis: barChartData.xaxis,
                    pieChartLegend: barChartData.legend,
                    pieChartSeries: pieChartData.averages,
                    totalAverage: pieChartData.totalAverage,
                    highTemperatureAverage: pieChartData.highTemperatureAverage,
                    lowTemperatureAverage: pieChartData.lowTemperatureAverage,
                    barTemperatures: barChartData.temperatureSeries,
                    chartIsLoading: false
                });
            })
            .catch(error => {
                this.setState({ chartIsLoading: false });
                this.showErrorDialog(error);
            });
    }

    getUsageAndShowData(selectedAccount) {
        const { timeScale } = this.state;

        const timeParameters = this.getTimeParameters(timeScale);
        this.api
            .getAccountUsage(
                selectedAccount.accountNumber,
                timeParameters.startDate,
                timeParameters.endDate,
                timeParameters.interval
            )
            .then(usageResponse => {
                usageResponse.sort((a, b) => {
                    if (a.date_time < b.date_time) {
                        return 1;
                    }
                    if (a.date_time > b.date_time) {
                        return -1;
                    }
                    return 0;
                });
                this.setState({
                    dataIsLoading: false,
                    dataSelectedAccountUsage: usageResponse
                });
            })
            .catch(error => {
                this.showErrorDialog(error);
            });
    }

    getTimeParameters(selectedTimeScale) {
        let startDate;
        let interval;
        let temperatureInterval; // Only different for yearly.
        const endDate = dayjs();
        switch (selectedTimeScale) {
            case timeScale.DAILY:
                startDate = dayjs()
                    .subtract(1, 'year')
                    .add(1, 'day')
                    .startOf('day');
                interval = 'DAILY';
                temperatureInterval = interval;
                break;
            case timeScale.MONTHLY:
                startDate = dayjs()
                    .subtract(59, 'month')
                    .set('date', 1)
                    .startOf('day');
                interval = 'MONTHLY';
                temperatureInterval = interval;
                break;
            default:
                startDate = dayjs()
                    .subtract(59, 'month')
                    .set('date', 1)
                    .startOf('day');
                interval = 'YEARLY';
                temperatureInterval = 'MONTHLY';
        }

        return {
            startDate: startDate,
            endDate: endDate,
            interval: interval,
            temperatureInterval: temperatureInterval,
            temperatureFrom: startDate,
            temperatureTo: endDate
        };
    }
}
