import styled, { materialUiTheme } from 'theme/index';
import AppConstants from 'AppConstants.js';
import PropTypes from 'prop-types';
import React from 'react';

const StatisticsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${materialUiTheme.breakpoints.down('xs')} {
        flex-direction: row;
    }
    ${materialUiTheme.breakpoints.up('sm')} {
        flex-direction: column;
    }
`;
const Metric = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${materialUiTheme.breakpoints.down('xs')} {
        width: 50%;
        margin: 8px 0 24px 0;
    }
    ${materialUiTheme.breakpoints.up('sm')} {
        width: 100%;
        margin: 16px 0;
    }
`;
const MetricDescription = styled.div`
    font-family: Soleil, sans-serif;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 4px;
    color: ${AppConstants.CHART_TEXT_SUB};
    text-transform: uppercase;
    text-align: center;
    ${materialUiTheme.breakpoints.down('xs')} {
        font-size: 14px;
        margin-bottom: 0;
    }
`;
const MetricValue = styled.div`
    font-family: Soleil, sans-serif;
    font-size: 30px;
    line-height: 1;
    color: ${AppConstants.COLOR_TEXT};
    text-align: center;
    ${materialUiTheme.breakpoints.down('xs')} {
        font-size: 20px;
    }
`;

function ChartStatistics({ description1, metric1, description2, metric2 }) {
    return (
        <StatisticsContainer>
            <Metric>
                <MetricDescription>{description1}</MetricDescription>
                <MetricValue>{metric1}</MetricValue>
            </Metric>
            <Metric>
                <MetricDescription>{description2}</MetricDescription>
                <MetricValue>{metric2}</MetricValue>
            </Metric>
        </StatisticsContainer>
    );
}

ChartStatistics.propTypes = {
    description1: PropTypes.string.isRequired,
    metric1: PropTypes.node.isRequired,
    description2: PropTypes.string.isRequired,
    metric2: PropTypes.node.isRequired
};

export default ChartStatistics;
