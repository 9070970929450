import { ThemedPageHeader, ThemedTextField } from 'common/styles';
import { wizardContextFromLaunchSource, WizardLaunchSource } from 'features/wizard/WizardContextBuilder';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import ErrorMessage from 'common/components/ErrorMessage';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import GetUserTask from 'common/tasks/GetUserTask';
import GridContainer from 'common/components/GridContainer';
import GridItem from 'common/components/GridItem';
import { linkAccounts } from 'actions';
import { mapStateToProps } from 'reducers';
import { NavButton } from 'common/components/nav-button';
import Page from 'common/components/Page';
import ProgressButton from 'common/components/ProgressButton';
import React from 'react';
import styled from 'theme';
import Validator from 'validation/Validator';
import { withTranslation } from 'react-i18next';

const WizardPage = styled(Page)`
    padding-top: 100px;
`;

const Intro = styled.p`
    text-align: center;
    margin-top: 24px;
    font-size: 16px;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
`;

class LinkMembershipContainer extends ContainerBase {
    constructor(props) {
        super(props, {
            membershipNumber: '',
            membershipNumberError: null,
            membershipPin: '',
            membershipPinError: null,
            alias: '',
            aliasError: null
        });

        this.validator = new Validator(this);
    }

    render() {
        const { t } = this.props;

        const {
            alias,
            aliasError,
            loading,
            membershipNumber,
            membershipNumberError,
            membershipPin,
            membershipPinError
        } = this.state;

        return (
            <WizardPage padding="container">
                <GridContainer direction="column" alignItems="center">
                    <GridItem xs={12} sm={8} md={4}>
                        <ThemedPageHeader center={true}>{t('wizard.linkMembership.header')}</ThemedPageHeader>
                        <Intro>{t('wizard.linkMembership.intro')}</Intro>
                        <form autoComplete="off" onSubmit={this.linkMembership}>
                            <ThemedTextField
                                id="membershipNumber"
                                inputRef={this.membershipNumberFocus}
                                label={t('wizard.linkMembership.labelMembershipNumber')}
                                type="number"
                                value={membershipNumber}
                                disabled={loading}
                                onChange={this.handleInputChange('membershipNumber')}
                                error={membershipNumberError !== null}
                            />
                            <ErrorMessage resourceId={membershipNumberError}/>

                            <ThemedTextField
                                id="membershipPin"
                                inputRef={this.membershipPinFocus}
                                label={t('wizard.linkMembership.labelMembershipPin')}
                                type="text"
                                value={membershipPin}
                                disabled={loading}
                                onChange={this.handleInputChange('membershipPin')}
                                error={membershipPinError !== null}
                            />
                            <ErrorMessage resourceId={membershipPinError}/>

                            <ThemedTextField
                                id="alias"
                                inputRef={this.aliasFocus}
                                label={t('wizard.linkMembership.labelAlias')}
                                type="text"
                                value={alias}
                                disabled={loading}
                                onChange={this.handleInputChange('alias')}
                                error={aliasError !== null}
                            />
                            <ErrorMessage resourceId={aliasError}/>

                            <ButtonRow>
                                <NavButton
                                    color="primary"
                                    disabled={loading}
                                    onClick={this.cancelWizard}
                                    spacingRight={true}
                                >
                                    {t('common.cancel')}
                                </NavButton>
                                <ProgressButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    loading={loading}
                                >
                                    {t('wizard.linkMembership.buttonContinue')}
                                </ProgressButton>
                            </ButtonRow>
                        </form>
                    </GridItem>
                </GridContainer>
            </WizardPage>
        );
    }

    handleInputChange = name => ({ target } = {}) => {
        this.setState({ [name]: target.value });
    };

    componentDidMount() {
        this.validator.maybeFocus();
    };

    componentDidUpdate() {
        this.validator.maybeFocus();
    };

    configureValidations(config) {
        config.requireNotEmpty('membershipNumber');
        config.requireNotEmpty('membershipPin');
    };

    cancelWizard = () => {
        const {
            history,
            location = this.props.location || {}
        } = this.props;

        if (location.state && location.state.launchSource === WizardLaunchSource.SETTINGS_ADD_MEMBERSHIP) {
            history.push('/settings/memberships');
        } else {
            history.push('/');
        }
    };

    linkMembership = e => {
        const {
            alias,
            membershipNumber,
            membershipPin
        } = this.state;

        const {
            appState,
            dispatch,
            history,
            location = this.props.location || {}
        } = this.props;

        e.preventDefault();

        if (!this.validator.validate()) {
            return;
        }

        const memberNumber = parseInt(membershipNumber, 10);

        let getUserResponse;
        this.setState({ loading: true });
        const api = new FireflyAuthenticatedApi({appState, dispatch });

        api.linkMembership(
            memberNumber,
            membershipPin,
            alias
        )
            .then(() => {
                const getUserTask = new GetUserTask(api);
                return getUserTask.execute();
            })
            .then(response => {
                getUserResponse = response;
                return api.getPaperlessBillingSetting(memberNumber);
            })
            .then(paperlessBillingSettingResponse => {
                this.setState({ loading: false });
                const wizardContext = wizardContextFromLaunchSource((location.state && location.state.launchSource));
                wizardContext.addMembership(
                    getUserResponse.user,
                    getUserResponse.accountDetails,
                    memberNumber,
                    paperlessBillingSettingResponse.value
                );
                const firstStep = wizardContext.next(location);
                dispatch(
                    linkAccounts(
                        getUserResponse.user,
                        getUserResponse.accountDetails,
                        wizardContext
                    )
                );
                history.push(firstStep.path);
            })
            .catch(error => {
                this.setState({ loading: false });
                switch (error.status) {
                    case 422:
                        let firstError = null;
                        if (
                            error.response.errors &&
                            error.response.errors.length > 0 &&
                            error.response.errors[0].field === 'member_number'
                        ) {
                            firstError = error.response.errors[0].error_code;
                        } else if (error.response.error_code) {
                            firstError = error.response.error_code;
                        }

                        switch (firstError) {
                            case 'invalid':
                            case 'invalid_credentials':
                                this.showFieldError(
                                    'membershipPin',
                                    'wizard.linkMembership.error.invalidMembershipAndPin'
                                );
                                break;

                            case 'already_linked':
                                this.showFieldError(
                                    'membershipNumber',
                                    'wizard.linkMembership.error.membershipAlreadyLinked'
                                );
                                break;

                            default:
                                this.showErrorDialog(error);
                        }

                        break;

                    default:
                        this.showErrorDialog(error);
                }
            });
    }
}

export default connect(mapStateToProps)(withTranslation()(LinkMembershipContainer));
