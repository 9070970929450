import { linkAccounts, login } from 'actions';
import styled, { materialUiTheme } from 'theme';
import { TabBar, TabItem } from 'common/components/tabs';
import { wizardContextFromAccountOnboarding, wizardContextFromMembershipOnboarding } from '../../wizard/WizardContextBuilder';
import Card from '@material-ui/core/Card';
import { connect } from 'react-redux';
import ContainerBase from 'common/containers/ContainerBase';
import { DialogBasic } from 'common/components/dialogs';
import ErrorMessage from 'common/components/ErrorMessage';
import FireflyApi from 'api/FireflyApi';
import FireflyAuthenticatedApi from 'api/FireflyAuthenticatedApi';
import GetUserTask from 'common/tasks/GetUserTask';
import GridContainer from 'common/components/GridContainer';
import GridItem from 'common/components/GridItem';
import HelperText from 'common/components/HelperText';
import Hidden from '@material-ui/core/Hidden';
import { mapStateToProps } from 'reducers';
import { NavButton } from 'common/components/nav-button';
import Page from 'common/components/Page';
import ProgressButton from 'common/components/ProgressButton';
import React from 'react';
import ResendEmailDialog from 'features/login/components/ResendEmailDialog';
import SwipeableViews from 'react-swipeable-views';
import { ThemedTextField } from 'common/styles';
import Validator from 'validation/Validator';
import { withTranslation } from 'react-i18next';

const FORM_LOGIN = 'formLogin';
const FORM_CREATE_USER = 'formCreateUser';
const PageWithBackground = styled(Page)`
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    align-items: center;
    ${materialUiTheme.breakpoints.up('sm')} {
        background-image: url('/img/login_bg.jpg');
    }
    && {
        ${materialUiTheme.breakpoints.down('xs')} {
            align-items: flex-start;
            padding-left: 0;
            padding-right: 0;
        }
    }
`;

const TabContainer = styled.div`
    padding: 50px;
    ${materialUiTheme.breakpoints.down('xs')} {
        padding: 24px;
    }
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const LoginButton = styled(ProgressButton)`
    width: 120px;
`;

const CardContainer = styled.div`
    width: 100%;
`;

const Notice = styled.div`
    display: flex;
    margin-top: 30px;
    font-style: italic;
`;

const LegacyPortalLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    margin-top: 30px;
    text-align: center;
`;

const LegacyPortalLink = styled.a`
    color: #008532;
`;

function ResponsiveFormCard(props) {
    return (
        <CardContainer>
            <Hidden xsDown={true}>
                <GridContainer>
                    <GridItem xs={false} md={2} lg={3} />
                    <GridItem xs={12} md={8} lg={6}>
                        <Card>{props.children}</Card>
                    </GridItem>
                </GridContainer>
            </Hidden>
            <Hidden smUp={true}>{props.children}</Hidden>
        </CardContainer>
    );
}

class LoginContainer extends ContainerBase {
    constructor(props) {
        const {
            location = props.location || {}
        } = props;

        super(props, {
            password: '',
            passwordError: null,
            email: '',
            emailError: null,
            firstName: '',
            firstNameError: null,
            lastName: '',
            lastNameError: null,
            newEmail: '',
            newEmailError: null,
            newPassword: '',
            newPasswordError: null,
            newPasswordConfirm: '',
            newPasswordConfirmError: null,
            tabIndex: 0,
            basicDialogTitle: '',
            basicDialogMessage: ''
        });

        if (location.state) {
            this.from = location.state && location.state.from;
        } else {
            this.from = '/';
        }

        this.resendEmailDialogRef = React.createRef();
        this.validator = new Validator(this);
    }

    componentDidMount() {
        this.validator.maybeFocus();
    }

    componentDidUpdate() {
        this.validator.maybeFocus();
    }

    render() {
        const {
            email,
            emailError,
            firstName,
            firstNameError,
            lastName,
            lastNameError,
            loading,
            newEmail,
            newEmailError,
            newPassword,
            newPasswordConfirm,
            newPasswordConfirmError,
            newPasswordError,
            password,
            passwordError,
            tabIndex
        } = this.state;

        const { t } = this.props;

        return (
            <PageWithBackground>
                <ResponsiveFormCard>
                    <TabBar value={tabIndex} onChange={this.handleTabChange} variant="fullWidth">
                        <TabItem label={t('login.tabLogIn')} />
                        <TabItem label={t('login.tabCreateAUser')} />
                    </TabBar>
                    <SwipeableViews index={tabIndex} onChangeIndex={this.handleTabIndexChange}>
                        <TabContainer>
                            <form onSubmit={this.login}>
                                <ThemedTextField
                                    id="email"
                                    inputRef={this.emailFocus}
                                    label={t('login.labelEmail')}
                                    type="email"
                                    value={email}
                                    disabled={loading}
                                    onChange={this.handleInputChange('email')}
                                    error={emailError !== null}
                                />
                                <ErrorMessage resourceId={emailError} />
                                <ThemedTextField
                                    id="password"
                                    inputRef={this.passwordFocus}
                                    label={t('login.labelPassword')}
                                    type="password"
                                    value={password}
                                    disabled={loading}
                                    onChange={this.handleInputChange('password')}
                                    error={passwordError !== null}
                                />
                                <ErrorMessage resourceId={passwordError} />
                                <ButtonRow>
                                    <NavButton
                                        color="primary"
                                        disabled={loading}
                                        to="/forgot-password"
                                        spacingRight={true}
                                        isMixedCase={true}
                                    >
                                        {t('login.buttonForgotPassword')}
                                    </NavButton>
                                    <LoginButton
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        loading={loading}
                                    >
                                        {t('login.buttonLogin')}
                                    </LoginButton>
                                </ButtonRow>
                                <Notice>{t('login.notice')}</Notice>
                                <LegacyPortalLinkContainer>
                                    <LegacyPortalLink target="_blank" href="https://billing.talquinelectric.com/">
                                        {t('login.legacyBillingLinkText')}
                                    </LegacyPortalLink>
                                </LegacyPortalLinkContainer>
                            </form>
                        </TabContainer>
                        <TabContainer>
                            <form autoComplete="off" onSubmit={this.createUser}>
                                <ThemedTextField
                                    id="firstName"
                                    inputRef={this.firstNameFocus}
                                    label={t('createUser.labelFirstName')}
                                    type="text"
                                    value={firstName}
                                    disabled={loading}
                                    onChange={this.handleInputChange('firstName')}
                                    error={firstNameError !== null}
                                />
                                <ErrorMessage resourceId={firstNameError}/>
                                <ThemedTextField
                                    id="lastName"
                                    inputRef={this.lastNameFocus}
                                    label={t('createUser.labelLastName')}
                                    type="text"
                                    value={lastName}
                                    disabled={loading}
                                    onChange={this.handleInputChange('lastName')}
                                    error={lastNameError !== null}
                                />
                                <ErrorMessage resourceId={lastNameError} />
                                <ThemedTextField
                                    id="newEmail"
                                    inputRef={this.newEmailFocus}
                                    label={t('createUser.labelNewEmail')}
                                    type="email"
                                    value={newEmail}
                                    disabled={loading}
                                    onChange={this.handleInputChange('newEmail')}
                                    error={newEmailError !== null}
                                />
                                <ErrorMessage resourceId={newEmailError} />
                                <ThemedTextField
                                    id="newPassword"
                                    inputRef={this.newPasswordFocus}
                                    label={t('createUser.labelNewPassword')}
                                    type="password"
                                    value={newPassword}
                                    disabled={loading}
                                    onChange={this.handleInputChange('newPassword')}
                                    error={newPasswordError !== null}
                                />
                                <ErrorMessage resourceId={newPasswordError} />
                                <HelperText resourceId="createUser.passwordHelperText" />
                                <ThemedTextField
                                    id="newPasswordConfirm"
                                    inputRef={this.newPasswordConfirmFocus}
                                    label={t('createUser.labelNewPasswordConfirm')}
                                    type="password"
                                    value={newPasswordConfirm}
                                    disabled={loading}
                                    onChange={this.handleInputChange('newPasswordConfirm')}
                                    error={newPasswordConfirmError !== null}
                                />
                                <ErrorMessage resourceId={newPasswordConfirmError} />
                                <ButtonRow>
                                    <ProgressButton
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        loading={loading}
                                    >
                                        {t('createUser.buttonCreateUser')}
                                    </ProgressButton>
                                </ButtonRow>
                            </form>
                        </TabContainer>
                    </SwipeableViews>
                </ResponsiveFormCard>
                <DialogBasic
                    title={t(this.state.basicDialogTitle)}
                    onCloseDialog={this.closeBasicDialog}
                    open={this.state.basicDialogIsOpen}
                    message={t(this.state.basicDialogMessage)}
                />
                <ResendEmailDialog ref={this.resendEmailDialogRef} onResend={this.onResendEmail} />
            </PageWithBackground>
        );
    }

    handleTabChange = (event, tabIndex) => {
        this.setState({ tabIndex });
    };

    handleTabIndexChange = tabIndex => {
        this.setState({ tabIndex });
    };

    handleInputChange = name => ({ target }) => {
        this.setState({ [name]: target && target.value });
    };

    configureValidations(config) {
        config.requireEmail('email', FORM_LOGIN);
        config.requireNotEmpty('password', FORM_LOGIN);
        config.requireNotEmpty('firstName', FORM_CREATE_USER);
        config.requireNotEmpty('lastName', FORM_CREATE_USER);
        config.requireEmail('newEmail', FORM_CREATE_USER);
        config.requirePassword('newPassword', FORM_CREATE_USER);
        config.requireConfirmPassword(
            'newPasswordConfirm',
            'newPassword',
            FORM_CREATE_USER
        );
    }

    login = e => {
        const {
            email,
            password
        } = this.state;

        const {
            dispatch,
            history,
        } = this.props;

        e.preventDefault();

        if (!this.validator.validate(FORM_LOGIN)) {
            return;
        }

        this.setState({ loading: true });
        const api = new FireflyApi();
        let loginResponse;

        api.login(email, password)
            .then(response => {
                loginResponse = response;
                const authenticatedApi = new FireflyAuthenticatedApi({
                    accessToken: loginResponse.access_token,
                    dispatch
                });
                const getUserTask = new GetUserTask(authenticatedApi);
                return getUserTask.execute();
            })
            .then(userAndAccountDetails => {
                dispatch(
                    login(
                        loginResponse.access_token,
                        loginResponse.refresh_token,
                        userAndAccountDetails.user,
                        userAndAccountDetails.accountDetails
                    )
                );

                if (userAndAccountDetails.user.accounts.length === 0) {
                    this.from = { pathname: '/wizard' };
                } else {
                    const authenticatedApi = new FireflyAuthenticatedApi({
                        accessToken: loginResponse.access_token,
                        dispatch
                    });

                    authenticatedApi.getOnboardingRequirement(userAndAccountDetails.user.id)
                        .then(onboardingRequirement => {
                            if (onboardingRequirement.required && onboardingRequirement.membership && onboardingRequirement.membership.member_number) {
                                const wizardContext = wizardContextFromMembershipOnboarding(
                                    onboardingRequirement.membership.member_number,
                                    onboardingRequirement.membership.accounts,
                                    onboardingRequirement.membership.isEnrolledInPaperlessBilling,
                                    userAndAccountDetails.accountDetails
                                );

                                const firstStep = wizardContext.start();
                                dispatch(
                                    linkAccounts(
                                        userAndAccountDetails.user,
                                        userAndAccountDetails.accountDetails,
                                        wizardContext
                                    )
                                );
                                this.from = { pathname: firstStep.path };
                            } else if (onboardingRequirement.required && onboardingRequirement.account) {
                                const wizardContext = wizardContextFromAccountOnboarding(
                                    onboardingRequirement.account,
                                    userAndAccountDetails.accountDetails
                                );

                                const firstStep = wizardContext.start();
                                dispatch(
                                    linkAccounts(
                                        userAndAccountDetails.user,
                                        userAndAccountDetails.accountDetails,
                                        wizardContext
                                    )
                                );
                                this.from = { pathname: firstStep.path };
                            } else {
                                this.from = { pathname: '/' };
                            }

                            history.push(this.from);
                        })
                        .catch(() => {});
                }

                history.push(this.from);
            })
            .catch(error => {
                switch (error.status) {
                    case 400:
                        this.showFieldError('password', 'login.error.invalidCredentials');
                        break;

                    case 403:
                        this.setState({
                            basicDialogTitle: 'dialog.oops.title',
                            basicDialogMessage: 'login.error.accountLocked'
                        });
                        this.showBasicDialog();
                        this.setState({ loading: false });
                        break;

                    case 422:
                        switch (error.response.error_code) {
                            case 'email_not_verified':
                                this.resendEmailDialogRef.current
                                    .open();
                                this.setState({ loading: false });
                                break;

                            case 'force_password_change':
                                this.showFieldError('password', 'login.error.passwordExpired');
                                break;

                            default:
                                this.setState({ loading: false });
                                this.showErrorDialog(error);
                        }

                        break;

                    default:
                        this.setState({ loading: false });
                        this.showErrorDialog(error);
                }
            });
    };

    createUser = e => {
        const {
            firstName,
            lastName,
            newEmail,
            newPassword,
        } = this.state;

        e.preventDefault();

        if (!this.validator.validate(FORM_CREATE_USER)) {
            return;
        }

        this.setState({ loading: true });
        const api = new FireflyApi();
        api.createUser(
            firstName,
            lastName,
            newEmail,
            newPassword
        )
            .then(() => {
                this.showCreateUserSuccess();
            })
            .catch(error => {
                this.setState({ loading: false });
                if (error.status === 422) {
                    const firstError =
                        error.response.errors &&
                        error.response.errors.length > 0
                            ? error.response.errors[0]
                            : null;
                    if (firstError && firstError.field === 'email') {
                        switch (firstError.error_code) {
                            case 'already_exists':
                                this.showFieldError('newEmail', 'createUser.error.alreadyRegistered');
                                break;

                            case 'invalid':
                                this.showFieldError('newEmail', 'validation.email');
                                break;

                            default:
                                this.showErrorDialog(error);
                        }
                    } else if (
                        firstError &&
                        firstError.field === 'password' &&
                        firstError.error_code === 'invalid'
                    ) {
                        this.showFieldError('newPassword', 'validation.password');
                    } else if (firstError && firstError.field === 'first_name'
                    ) {
                        this.showFieldError('firstName', 'validation.notEmpty');
                    } else if (firstError && firstError.field === 'last_name') {
                        this.showFieldError('lastName', 'validation.notEmpty');
                    } else {
                        this.showErrorDialog(error);
                    }
                } else {
                    this.showErrorDialog(error);
                }
            });
    };

    showCreateUserSuccess() {
        this.setState({
            loading: false,
            firstName: '',
            firstNameError: null,
            lastName: '',
            lastNameError: null,
            newEmail: '',
            newEmailError: null,
            newPassword: '',
            newPasswordError: null,
            newPasswordConfirm: '',
            newPasswordConfirmError: null,
            basicDialogIsOpen: true,
            basicDialogTitle: 'createUser.successModal.title',
            basicDialogMessage: 'createUser.successModal.message'
        });
    }

    onBasicDialogClosed() {
        setTimeout(() => {
            this.setState({ tabIndex: 0 });
        }, 100);
    }

    onResendEmail = () => {
        const { email } = this.state;

        return new Promise((resolve, reject) => {
            const api = new FireflyApi();
            api.resendEmailVerification(email)
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject();
                    switch (error.status) {
                        case 422:
                            switch (error.response.error) {
                                // sb: the only validation checks for this request
                                // are field validations for email of
                                // "missing_field" and "invalid", which should both
                                // be handled by our client-side validation.
                                default:
                                    this.showErrorDialog(error);
                            }

                            break;

                        default:
                            this.showErrorDialog(error);
                    }
                });
        });
    }
}

export default connect(mapStateToProps)(withTranslation()(LoginContainer));
