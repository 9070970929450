import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Displays an icon for an account type.
 */
function AccountTypeIcon(props) {
    const { accountType } = props;

    const { t } = useTranslation();

    let imageName;

    switch (accountType) {
        case 'electric':
            imageName = '/img/account_type_electric.svg';
            break;
        case 'water':
            imageName = '/img/account_type_water.svg';
            break;
        case 'sl_only':
            imageName = '/img/account_type_service_light.svg';
            break;
        default:
            imageName = '/img/account_type_other.svg';
            break;
    }

    return (
        <img src={imageName} width="41" height="41" alt={t('common.accounts.aria.accountTypeIndicator')} />
    );
}

AccountTypeIcon.propTypes = {
    accountType: PropTypes.string.isRequired
};

export default AccountTypeIcon;
