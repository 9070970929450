/**
 * Formats a number into a currency string.
 *
 * @param {number} n The number.
 * @returns {string} The formatted currency containing thousands separators.
 */
function formatCurrency(n) {
    return n.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export default formatCurrency;
