import appReducers from 'reducers';
import { DEFAULT_APP_STATE } from 'model/AppState';
import { createStore as reduxCreateStore } from 'redux';
import SessionDataAccess from 'reducers/SessionDataAccess';

export function createStore() {
    const savedSession = SessionDataAccess.get();
    let store;
    if (savedSession == null) {
        store = reduxCreateStore(appReducers);
    } else {
        if (savedSession.appState.version !== process.env.REACT_APP_VERSION) {
            const savedRefreshToken = savedSession.appState.refreshToken;
            const newState = { appState: DEFAULT_APP_STATE };
            newState.appState.refreshToken = savedRefreshToken;
            store = reduxCreateStore(appReducers, newState);
        } else {
            store = reduxCreateStore(appReducers, savedSession);
        }
    }
    store.subscribe(() => {
        SessionDataAccess.save(store.getState());
    });
    return store;
}
