import React, { Fragment } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormattedDate from 'common/components/FormattedDate';
import PropTypes from 'prop-types';
import styled from 'theme';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';

const MessageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 600px;
`;

function DataTable(props) {
    const {
        accountData = props.accountData || [],
        interval
    } = props;

    const { t } = useTranslation();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>{t('usage.intervals.headers.' + interval)}</TableCell>
                    <TableCell>{t('usage.dataTable.headers.usage')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {accountData.map((entry, i) => {
                    let format;

                    switch (interval) {
                        case 'daily':
                            format = {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric'
                            };
                            break;
                        case 'yearly':
                            format = {
                                year: 'numeric'
                            };
                            break;
                        default:
                            format = {
                                year: 'numeric',
                                month: 'long'
                            };
                            break;
                    }

                    const date = (
                        <FormattedDate dateString={entry.date_time} format={format} />
                    );

                    return (
                        <TableRow key={i}>
                            <TableCell component="th" scope="row">{date}</TableCell>
                            <TableCell>
                                {t('usage.units.' + entry.unit, { value: entry.value})}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}

class AccountUsageDataTable extends React.Component {
    render() {
        const {
            accountData,
            interval,
            loading,
            t
        } = this.props;

        return (
            <Fragment>
                {loading ? (
                    <MessageContainer>
                        <CircularProgress />
                    </MessageContainer>
                ) : (
                    <DataTable interval={interval} t={t} accountData={accountData} />
                )}
            </Fragment>
        );
    }
}

AccountUsageDataTable.propTypes = {
    interval: PropTypes.oneOf(['daily', 'monthly', 'yearly']).isRequired,
    loading: PropTypes.bool,
    accountData: PropTypes.array.isRequired
};

AccountUsageDataTable.defaultProps = {
    interval: 'monthly',
    loading: false
};

export default withTranslation()(AccountUsageDataTable);
