/**
 * @return {boolean}
 */
export function validateRoutingNumber(routing) {
    const checksumTotal =
        7 *
            (parseInt(routing.charAt(0), 10) +
                parseInt(routing.charAt(3), 10) +
                parseInt(routing.charAt(6), 10)) +
        3 *
            (parseInt(routing.charAt(1), 10) +
                parseInt(routing.charAt(4), 10) +
                parseInt(routing.charAt(7), 10)) +
        9 *
            (parseInt(routing.charAt(2), 10) +
                parseInt(routing.charAt(5), 10) +
                parseInt(routing.charAt(8), 10));

    return checksumTotal % 10 === 0;
}
