export default class OnboardingWizardContext {
    constructor(
        steps,
        memberNumber,
        accountNumbers,
        isAtLeastOneAccountTapEligible,
        areAllAccountsEnrolledInAutomaticPayments,
        isMembershipPaperless
    ) {
        this.steps = steps;
        this.currentStepIndex = 0;
        this.memberNumberAddedByWizard = memberNumber;
        this.accountNumbersAddedByWizard = accountNumbers;
        this.isAtLeastOneAccountTapEligible = isAtLeastOneAccountTapEligible;
        this.areAllAccountsEnrolledInAutomaticPayments = areAllAccountsEnrolledInAutomaticPayments;
        this.isMembershipPaperless = isMembershipPaperless;
        this.wasFromOnboarding = true;
    }

    getAccountsAddedByWizard(appState) {
        return appState.accounts.filter(account =>
            this.accountNumbersAddedByWizard.includes(account.accountNumber)
        );
    }

    /**
     * Starts the wizard by returning the first step for display.
     *
     * @return {*} The first step in the wizard process.
     */
    start() {
        return this.steps[this.currentStepIndex];
    }

    /**
     * Proceeds to the next step in the wizard process.
     *
     * @param {Location} location The current window.location.
     * @return {*} The next step in the wizard process.
     */
    next(location) {
        // Resetting current index based on location, in case user clicked the
        // back button.
        const currentStepIndex = this.steps.findIndex(step => {
            return step.path === location.pathname;
        });

        this.currentStepIndex = currentStepIndex < 0 ? 1 : currentStepIndex + 1;

        return this.steps[this.currentStepIndex];
    }
}
